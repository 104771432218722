import {
  BottomSheet,
  Icon,
  ListCell,
  ListView,
  Loader,
  Modal,
  NavigationBar,
  Toast,
  Typography,
} from '@modulor/react';
import { useOnClickOutside } from 'Modulor/hooks';
import { useGetDepositMethodInfoQuery } from 'store/services/depositAPI';
import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { PaymentProcessMethodInfo } from 'store/models/paymentProcessModels';
import { getLayoutType } from 'utils/common';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cleanCurrentMethod, methodNameSelector } from 'store/slices/deposit';
import { DESKTOP_MODAL_MIN_HEIGHT, DESKTOP_MODAL_WIDTH, LayoutType } from 'utils/constants';
import IntegrationCommunication from 'services/integration-communication';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';

import './BetShops.scss';

interface PreparedViewData {
  type?: string;
  style?: string;
  key?: string;
  value?: string;
  options?: {
    key?: string;
    label?: string;
  }[];
  params?: Record<string, unknown>;
}

interface BetShopsBottomsheetProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  currency?: string | null;
  payGroupId: number;
}
export const BetShopsBottomsheet = ({
  isOpen,
  setOpen,
  currency,
  payGroupId,
}: BetShopsBottomsheetProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(modalRef, () => setOpen(false));

  const { translate } = useSkeletonTranslation();
  const dispatch = useAppDispatch();
  const [viewDataForRender, setViewDataForRender] = useState<PreparedViewData[]>([]);
  const methodName = useAppSelector(methodNameSelector);

  const layoutType = getLayoutType();
  const isMobileLayout = layoutType === LayoutType.mobile;

  const { data: methodInfo, isFetching, isSuccess, isError, error } = useGetDepositMethodInfoQuery({
    payGroupId,
    currency: currency || '',
  }, {
    skip: !currency || !isOpen,
  });

  useEffect(() => {
    if (isSuccess && methodInfo) {
      const { viewData } = methodInfo as PaymentProcessMethodInfo || {};
      const updatedViewData: PreparedViewData[] = viewData?.map((data) => ({
        ...data,
        options: data.options?.map((option) => ({
          key: nanoid(),
          label: option.label || '',
        })) || [],
      })) || [];
      setViewDataForRender(updatedViewData);
    }
  }, [
    methodInfo,
    isSuccess,
  ]);

  useEffect(() => () => {
    if (isOpen) {
      dispatch(cleanCurrentMethod());
    }
  }, []);

  useLayoutEffect(() => {
    if (isOpen && !isMobileLayout) {
      const { height = 350 } = window.document.getElementById('root')?.getBoundingClientRect() || {};
      setTimeout(() => {
        IntegrationCommunication.sendMessage({
          id: 'frameChangedHeight',
          value: height <= DESKTOP_MODAL_MIN_HEIGHT ? DESKTOP_MODAL_MIN_HEIGHT + 50 : height,
        });
      }, 0);
    }
  }, [isOpen,
    isMobileLayout,
  ]);

  const onClose = () => setOpen(false);

  const renderBetShopsList = () => (
    <div
      className="content-page-wrapper"
      key="modal-content"
    >
      {isFetching && <Loader />}
      {isSuccess && !!viewDataForRender.length && (
      <ListView>
        {viewDataForRender.map((data) => (
          <ListCell
            key={data.key}
            leftContent={(
              <div>
                <div>
                  <Typography variant="body-regular">
                    {data.key}
                  </Typography>
                </div>
                {data.options?.map((option) => (
                  <div key={option.key}>
                    <Typography variant="caption-1-regular">
                      {option.label}
                    </Typography>
                  </div>
                ))}
              </div>
                  )}
            icon={(
              <Icon
                fill="icon-deposit"
                name="pin_outlined"
              />
                  )}
          />
        ))}
      </ListView>
      )}
    </div>
  );

  const renderHeader = () => (
    <div className="header">
      <Typography variant="title-2-semibold">{methodName}</Typography>
      <div className="header-navigation">
        <NavigationBar
          transparent
          right={(
            <Icon
              name="close"
              onClick={onClose}
            />
        )}
        />
      </div>
    </div>
  );

  return (
    <>
      {isOpen && isError && (
        <Toast
          variant="error"
          visible={Boolean(isError)}
          text={translate(error.toString())}
          multiline
          rightIcon={(
            <Icon
              name="close"
            />
          )}
          duration={2500}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onHide={() => {}}
        />
      )}
      {isMobileLayout ? (
        <BottomSheet
          open={isOpen}
          onShadowClick={onClose}
          onSwipeDown={onClose}
          header={renderHeader()}
        >
          {renderBetShopsList()}
        </BottomSheet>
      )
        : (
          <Modal
            openModal={isOpen}
            ref={modalRef}
            className="bet-shops-modal"
            minDesktopModalHeight={DESKTOP_MODAL_MIN_HEIGHT}
            desktopModalWidth={DESKTOP_MODAL_WIDTH}
            header={renderHeader()}
          >
            {[renderBetShopsList()]}
          </Modal>
        )}
    </>
  );
};
