import React, { Dispatch, SetStateAction } from 'react';
import { Icon, Toast } from '@modulor/react';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';

interface SubmitErrorProps {
    error: string;
    setError: Dispatch<SetStateAction<string>>;
}

const SubmitError = ({ error, setError }: SubmitErrorProps) => {
  const { translate } = useSkeletonTranslation();
  return error ? (
    <Toast
      variant="error"
      visible={Boolean(error)}
      text={translate(error)}
      multiline
      rightIcon={(
        <Icon
          name="close"
          onClick={() => setError('')}
        />
      )}
      onHide={() => setError('')}
      duration={2500}
    />
  ) : null;
};

export default SubmitError;
