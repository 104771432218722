/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-empty-function */
import { MouseEvent, useEffect, useState } from 'react';

import { useCarousel } from 'hooks/useCarousel';
import useSelectInfoMethod from 'hooks/useSelectInfoMethod';
import { setDefaultPaySessionState } from 'store/slices/paySessionState';
import {
  useGetWithdrawalMethodsQuery,
  useLazyGetWithdrawalMethodInfoQuery,
  withdrawalAPI,
  SELECT_TAG,
} from 'store/services/withdrawalAPI';
import { setWithdrawalTax, cleanCurrentMethod, setWidgetSettings } from 'store/slices/withdrawal';
import {
  appChannelSelector,
  brandSelector,
  clickstreamIdSelector,
  langSelector,
  needsInitRefetchSelector,
  setCountryCode,
  setHeadersNavIcon,
  setLogoSizes,
  setUserBalance,
  setUserPhone,
  setGrowthbookFeatures,
  userIdSelector,
  setReturnUrls,
  setNeedsInitRefetch,
  setIsCustomIframeMode,
  setIsStatusPageMode,
} from 'store/slices/global';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMethodForAutoRedirectWithdrawal, UIPayoutGroups } from 'store/formatters';
import { useFrameChangeHeight, useGetSessionId, useNavigateWithSearch } from 'Modulor/hooks';
import {
  softLocalStorageRemove,
  softLocalStorageSet,
  getMethodGroups,
  getGroupsCount,
} from 'utils';
import { ALT_METHODS, FlowTypes, SELECTED_METHOD_ID } from 'store/constants';
import {
  Notifications,
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType,
} from 'store/models';
import {
  isCarouselViewChangedSelector,
  isInitialCarouselLoadSelector,
  setInitialCarouselLoad,
} from 'store/slices/carousel';
import {
  setupClickstream,
  Clickstream,
  getClickStreamEvent,
  useGetClickStreamCashierContext,
  getUnavailableEventData,
  useGetClickStreamPayGroupCashierContext,
  getPayGroupCashierContext,
} from 'services/clickstream';
import { getGBFeatures } from 'services/growthbook';
import { CAROUSEL_EXP } from 'services/growthbook/constants';
import IntegrationCommunication from 'services/integration-communication';
import { selectWithdrawalMethodHandler, selectActionMethod } from './utils';
import { useSetNotificationsHook } from '../components/Notifications/notificationsHooks';
import ModulorLoader from '../components/ModulorLoader';
import { WithdrawalMethodGroupList } from './WithdrawalMethodGroupList';

import './WithdrawalMethods.scss';

const gbFeatures = getGBFeatures();

export const WithdrawalMethods = () => {
  const { height } = window.document.documentElement.getBoundingClientRect();
  const dispatch = useAppDispatch();
  const [wasLoaded, setWasLoaded] = useState<boolean>(false);
  const [isExpSetup, setIsExpSetup] = useState<boolean>(false);
  const navigate = useNavigateWithSearch();
  const { data, isFetching, isLoading, isSuccess, refetch } = useGetWithdrawalMethodsQuery();
  const sessionId = useGetSessionId();
  const {
    methods = [],
    initCarouselMethods,
    canAutoRedirect,
    widgetSettings,
    viewData,
    tax,
    merchantUrl,
    merchantSuccessUrl,
    merchantFailUrl,
    merchantProcessingUrl,
    merchantRedirectType,
    userBalance,
    currencyAlpha3,
    growthbookFeatures,
    authToken = '',
  } = data || {};
  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(FlowTypes.deposit);
  const payGroupContext = useGetClickStreamPayGroupCashierContext(FlowTypes.withdrawal);
  const brand = useAppSelector(brandSelector);
  const needsInitRefetch = useAppSelector(needsInitRefetchSelector);
  const language = useAppSelector(langSelector);
  const clickstreamId = useAppSelector(clickstreamIdSelector);
  const appChannel = useAppSelector(appChannelSelector);
  const userId = useAppSelector(userIdSelector);
  const isCarouselViewChanged = useAppSelector(isCarouselViewChangedSelector);
  const isInitialCarouselLoad = useAppSelector(isInitialCarouselLoadSelector);
  const methodGroups = getMethodGroups(methods);
  const methodToRedirect = getMethodForAutoRedirectWithdrawal({
    methods,
    initCarouselMethods,
    isInitialCarouselLoad,
  });

  useEffect(() => {
    if (needsInitRefetch) {
      dispatch(setNeedsInitRefetch(false));
      dispatch(setIsCustomIframeMode(false));
      dispatch(setIsStatusPageMode(false));
      refetch();
      dispatch(withdrawalAPI.util.invalidateTags([{
        type: SELECT_TAG,
      }]));
    }
  }, [needsInitRefetch]);

  const [triggerWithdrawalMethodInfo, resultWithdrawalMethodInfo] = useLazyGetWithdrawalMethodInfoQuery();
  useSelectInfoMethod(methods, resultWithdrawalMethodInfo, dispatch, widgetSettings?.platform);

  useCarousel({
    methods,
    initCarouselMethods,
    isInitialCarouselLoad,
    methodToRedirect,
    isCarouselViewChanged,
    flowType: FlowTypes.withdrawal,
  });

  useSetNotificationsHook(viewData as Notifications);

  useEffect(() => {
    dispatch(setWidgetSettings(widgetSettings || {}));
    dispatch(setUserPhone(widgetSettings?.user_phone || ''));
    dispatch(setCountryCode(widgetSettings?.country_code || ''));
    dispatch(setLogoSizes({
      height: widgetSettings?.logo_image_height || 40,
      width: widgetSettings?.logo_image_width || 40,
    }));
  }, [widgetSettings]);

  useEffect(() => {
    dispatch(setUserBalance(userBalance));
  }, [userBalance]);

  useEffect(() => {
    if (growthbookFeatures?.length) {
      dispatch(setGrowthbookFeatures(growthbookFeatures));
    }
  }, [growthbookFeatures]);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      dispatch(setReturnUrls({
        merchantUrl,
        merchantSuccessUrl,
        merchantFailUrl,
        merchantProcessingUrl,
        merchantRedirectType,
      }));
    }
  }, [data]);

  const setCarouselExpResult = () => {
    const result = gbFeatures.growthBook.evalFeature(CAROUSEL_EXP);
    if (result.value) {
      dispatch(setInitialCarouselLoad(false));
    }
    setIsExpSetup(true);
  };

  const initGBExperiment = (url: string, isCarouselExp: boolean) => {
    gbFeatures.initGBFeatures({
      currencyAlpha3,
      merchantUrl: url,
      userId,
      growthbookFeatures,
      brand,
      userBalance,
      clickstreamId,
      language,
      appChannel,
      platform: widgetSettings?.platform,
      userAmsSegments: widgetSettings?.user_ams_segments || [],
    }).then(() => {
      if (!isCarouselExp) {
        setIsExpSetup(true);
        dispatch(setInitialCarouselLoad(false));
      } else {
        setCarouselExpResult();
      }
    }).catch(() => {
      setIsExpSetup(true);
    });
  };

  const growthbookInitialization = (
    { shouldInitGrowthbook,
      url,
      isReadyForServices }: {
        shouldInitGrowthbook: boolean;
      url: string;
        isReadyForServices: boolean;
      },
  ) => {
    const isCarouselExp = !!growthbookFeatures && growthbookFeatures.includes(CAROUSEL_EXP);
    if (shouldInitGrowthbook) {
      initGBExperiment(url, isCarouselExp);
      return;
    }
    if (isReadyForServices && isCarouselExp) {
      setCarouselExpResult();
      return;
    }
    setIsExpSetup(true);
    dispatch(setInitialCarouselLoad(false));
  };

  const sendEntryClickstreamEvent = (availableMethods: UIPayoutGroups[]) => {
    const unavailableEventData = getUnavailableEventData(availableMethods);
    const event = getClickStreamEvent.cashier_homepage_open(unavailableEventData, '');
    clickstream.push(event, [cashierContext]);

    if (unavailableEventData && unavailableEventData.length === availableMethods.length) {
      // eslint-disable-next-line max-len
      const clEvent = getClickStreamEvent.cashier_homepage_all_methods_unavailable_view(unavailableEventData);
      clickstream.push(clEvent, [cashierContext]);
    }
  };

  const handleSelectWithdrawalMethod = (m: UIPayoutGroups) => (event: MouseEvent) => {
    const clEvent = getClickStreamEvent.cashier_homepage_method_tile_click(false);
    clickstream.push(clEvent, [cashierContext,
      getPayGroupCashierContext(m)]);

    if (m.payGroupActionType === PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType.Info) {
      triggerWithdrawalMethodInfo({
        payGroupId: m.id,
        currency: m.currencyAlpha3 || '',
      });
    }

    selectWithdrawalMethodHandler({
      method: m,
      platform: widgetSettings?.platform,
      dispatch,
      navigate,
      flowType: FlowTypes.withdrawal,
    }, event);
  };

  useEffect(() => {
    softLocalStorageSet(ALT_METHODS, JSON.stringify(methods));
  }, [methods]);

  const sendBackClickstreamEvent = () => {
    const clEvent = getClickStreamEvent.cashier_method_back_icon_click;
    clickstream.push(clEvent, [cashierContext,
      payGroupContext]);
  };

  useEffect(() => {
    document.addEventListener('СlickstreamBack', sendBackClickstreamEvent);
    dispatch(setDefaultPaySessionState());
    softLocalStorageRemove(SELECTED_METHOD_ID);
    dispatch(cleanCurrentMethod());
    dispatch(setHeadersNavIcon('close'));

    return () => {
      document.removeEventListener('СlickstreamBack', sendBackClickstreamEvent);
      dispatch(setHeadersNavIcon(''));
    };
  }, []);

  useEffect(() => {
    const canUseMerchantUrl = merchantUrl && merchantUrl.startsWith('http');
    const isReadyForServices = Boolean(!isLoading && merchantUrl);
    const isGbFeaturesExist = Boolean(growthbookFeatures?.length);
    const shouldInitGrowthbook = isReadyForServices
        && isGbFeaturesExist
        && !gbFeatures.isLoading()
        && !gbFeatures.isInitialized()
        && !gbFeatures.isFailed();

    if (!isLoading && canUseMerchantUrl) {
      growthbookInitialization({
        shouldInitGrowthbook,
        url: merchantUrl,
        isReadyForServices,
      });
      try {
        setupClickstream({
          appChannel,
          platform: widgetSettings?.platform,
          brand,
          language,
          merchantUrl,
          clickstreamId,
          sessionId,
          authToken,
        });
        if (clickstream.getIsSetup()) {
          sendEntryClickstreamEvent(methods);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setWithdrawalTax(tax));
  }, [tax]);

  useEffect(() => {
    if (isSuccess && methods.length) {
      IntegrationCommunication.sendMessage({
        id: 'frameChangedHeight',
        value: height,
      });
    }
  }, [
    isSuccess,
    height,
  ]);

  useFrameChangeHeight(!!methods.length);

  useEffect(() => {
    if (isLoading) {
      setWasLoaded(true);
    }
  }, [isLoading]);

  useEffect(() => {
    const canUseMerchantUrl = merchantUrl && merchantUrl.startsWith('http');
    if (methodToRedirect && canAutoRedirect && wasLoaded && (canUseMerchantUrl && isExpSetup)) {
      const clEvent = getClickStreamEvent.cashier_homepage_method_tile_click(false);
      clickstream.push(clEvent, [cashierContext,
        getPayGroupCashierContext(methodToRedirect)]);
      selectActionMethod({
        dispatch,
        method: methodToRedirect,
        navigate,
        flowType: FlowTypes.withdrawal,
      });
    }
  }, [
    methodToRedirect,
    canAutoRedirect,
    wasLoaded,
    isExpSetup,
  ]);

  if (isFetching) {
    return <ModulorLoader />;
  }

  return (
    <>
      {methodGroups.map(({ name, list }) => (
        <WithdrawalMethodGroupList
          key={name}
          name={name}
          list={list}
          groupCount={getGroupsCount(methodGroups)}
          handleSelectWithdrawalMethod={handleSelectWithdrawalMethod}
        />
      ))}
    </>
  );
};
