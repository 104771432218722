import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, FieldValues } from 'react-hook-form';
import { Button } from '@modulor/react';
import {
  getSubmitButtonVariant,
  getThemeByBrand, prepareFieldsWithCustomParameters,
} from 'utils';
import {
  Limits,
  MerchantUserFeeResponce,
  Parameter,
  PayHubCashierContractsViewDataResponse,
  PayHubWidgetContractsRecurringCheckResponse,
  PayHubWidgetContractsUserCardResponse,
  ProcessingCurrency,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
  PayHubEnumsCashierMethodParamType,
  Option,
} from 'store/models';
import { FlowTypes } from 'store/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { bonusDataSelector } from 'store/slices/bonus';
import {
  buildDefaultCheckoutValues,
  getSubmitSubText,
  prepareFieldsDataForSubmit,
} from 'Modulor/Deposit/utils';
import { TaxContainer } from 'Modulor/components/Tax/TaxContainer';
import { fieldGetter, getAmountField } from 'Modulor/fieldGetter';
import { cardFieldNameSelector } from 'store/slices/checkout';
import { hasValidationError } from 'utils/form';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { getFormattedAmountWithCurrency, getPrettierFormatNumber } from 'utils/dataTransform';
import { brandSelector, displaySelectListOptionsSelector, setDisplaySelectListOptions } from 'store/slices/global';
import { currencyDisplaySelector, setUploadedFile, uploadedFileFormDataSelector } from 'store/slices/deposit';
import { getGBFeatures } from 'services/growthbook';
import { NAMESPACES } from 'services/constants';
import { selectedCardSelector } from 'store/slices/userCard';
import { useUploadDocFileForDepositMutation } from 'store/services/depositAPI';
import { Fee } from '../components/Fee';
import { AmountBlocks } from '../components/AmountBlocks';
import {
  useDetectNewErrors,
  useFrameChangeHeight,
  useGetTaxInfo,
} from '../hooks';
import {
  filterFieldsForCheckout,
  isShowPCIDSSLogoByBrand,
  prepareRequiredFields,
  renderFields,
  updateFieldsDataStructure,
} from './utils';
import AdditionalInfo from '../components/AdditionalInfo';
import { RecurringCheckModal } from './RecurringModal/RecurringCheckModal';
import { ConversionBlock } from '../components/ConversionBlock';
import { getAmountBlocksByExperiment } from '../components/utils';
import RecurringModalBody from './RecurringModal/RecurringModalBody';
import CheckoutA2AError from './views/CheckoutA2AError';
import BonusInfoWrapper from './views/BonusInfoWrapper';
import PCIDSSWrapper from './views/PCIDSSWrapper';
import SavedCardWrapper from './views/SavedCardWrapper';
import { SelectListOptions } from '../components/SelectList/SelectList';

type Inputs = Record<string, string | number | boolean | unknown>;
interface CheckoutFormProps {
  amount: number;
  payGroupId: number;
  methodParameters: Parameter[];
  submitCallback: (fields: Inputs, isNew: boolean) => void;
  cardsList?: PayHubWidgetContractsUserCardResponse[];
  amountBlocks?: number[];
  currency: string;
  amountRegExpFe?: string;
  limits: Limits | null;
  isAutoSubmit?: boolean;
  isAutoSubmitSelectType?: boolean;
  withAmount: boolean;
  isFetchingSubmitRequest: boolean;
  presetCurrency?: string | null;
  additionalViewData?: PayHubCashierContractsViewDataResponse[] | null;
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string | null;
  presetAmount?: number | null;
  allowPresetAmount?: boolean | null;
  recurringCheckTrigger: LazyQueryTrigger<QueryDefinition<{
    cardId: number;
    amount: number;
}, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
never, PayHubWidgetContractsRecurringCheckResponse, 'checkoutAPI'>>;
  shouldVerifyCVV?: boolean;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  methodName: string;
  formConfig: Parameter[];
}

const gbFeatures = getGBFeatures();

export const CheckoutForm: FC<CheckoutFormProps> = (props) => {
  const {
    amount,
    submitCallback,
    methodParameters = [],
    cardsList = [],
    amountBlocks,
    currency,
    amountRegExpFe,
    limits,
    withAmount,
    isAutoSubmit,
    isAutoSubmitSelectType,
    isFetchingSubmitRequest,
    presetCurrency,
    additionalViewData,
    merchantUserFee,
    presetAmount,
    allowPresetAmount,
    fee,
    recurringCheckTrigger,
    shouldVerifyCVV,
    processingCurrency,
    processingCurrencyRate,
    methodName,
    payGroupId,
    formConfig,
  } = props;
  const dispatch = useAppDispatch();
  const { translate, skeletonT } = useSkeletonTranslation();
  const brand = useAppSelector(brandSelector);
  const cardInfo = useAppSelector(selectedCardSelector);
  const cardFieldName = useAppSelector(cardFieldNameSelector);
  const bonusData = useAppSelector(bonusDataSelector);
  const uploadedFileForA2aFlow = useAppSelector(uploadedFileFormDataSelector);
  const currencyDisplaySetting = useAppSelector(currencyDisplaySelector);
  const displaySelectListOptions = useAppSelector(displaySelectListOptionsSelector);
  const [defaultValues, setDefaultValues] = useState(buildDefaultCheckoutValues() as FieldValues);
  const [displayRecurringCheckModal, setShowRecurringCheckModal] = useState<boolean>(false);
  const { bonusInfo, bonusByPayGroup, isGeneralBonus } = bonusData || {};
  const currencyValue = presetCurrency || currency;
  const gbAmountBlocksResult = gbFeatures.growthBook.evalFeature('billing.recommended.amount');
  const amountBlocksData = useMemo(() => getAmountBlocksByExperiment({
    amountBlocks, limits, gbResult: gbAmountBlocksResult?.value,
  }), [amountBlocks,
    limits,
    gbAmountBlocksResult?.value]);
  const isBonusIncluded = isGeneralBonus || Boolean(bonusByPayGroup?.includes(payGroupId));
  const showPCIDSSLogo = isShowPCIDSSLogoByBrand(getThemeByBrand(brand));
  const requiredFieldsName = useMemo(
    () => formConfig
      .filter((field) => field.isRequired)
      .map((field) => field.name),
    [formConfig],
  );
  const [triggerUpload, { isLoading: isUploadLoading,
    isError: isUploadError, error: uploadError }] = useUploadDocFileForDepositMutation();
  const isA2aFlow = useMemo(() => formConfig.find((field) => field.type
      === PayHubEnumsCashierMethodParamType.FileUpload), [formConfig]);
  const isSendUploadedDocError = uploadedFileForA2aFlow && isUploadError;
  const isDisabledByA2aFlow = useMemo(
    () => requiredFieldsName.includes('FileUpload')
          && ((isA2aFlow && !uploadedFileForA2aFlow) || !!isSendUploadedDocError || isUploadLoading),
    [uploadedFileForA2aFlow,
      isSendUploadedDocError,
      isUploadLoading],
  );
  const prefillAmount = useMemo(
    () => (allowPresetAmount && presetAmount ? presetAmount?.toString() : undefined),
    [allowPresetAmount,
      presetAmount],
  );
  const showAmountBlocks = !!amountBlocksData?.length && !isAutoSubmit;
  const amountField = getAmountField({
    amountRegExpFe,
    limits,
    currency: presetCurrency || currency,
    flowType: FlowTypes.deposit,
    isDisabled: isAutoSubmitSelectType,
    isAutoSubmit,
  });
  const submitSubText = useMemo(() => getSubmitSubText({
    t: translate,
    currency: presetCurrency || currency,
    amount,
    bonusInfo,
    isBonusIncluded,
    processingCurrency,
    processingCurrencyRate,
    currencyDisplaySetting,
    merchantUserFee,
    fee,
  }), [
    currency,
    presetCurrency,
    bonusInfo,
    isBonusIncluded,
    amount,
    processingCurrency,
    processingCurrencyRate,
    merchantUserFee,
    fee,
  ]);

  const { handleSubmit, control, formState: {
    isValid,
    errors,
    dirtyFields,
  }, setError,
  clearErrors,
  trigger,
  getValues,
  unregister,
  reset,
  setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const hasNotErrors = !Object.keys(errors).length;
  const { hasNewErrors } = useDetectNewErrors(errors);

  useFrameChangeHeight(hasNewErrors);

  const newLocal = fieldGetter({
    t: translate,
    clearErrors,
    setError,
    setValue,
    formField: {
      control,
    },
    isLoading: isUploadLoading,
  })(amountField);

  const { tax, calculateNonTaxableAmount, amountWithTaxes } = useGetTaxInfo(
    amount,
    FlowTypes.deposit,
  );

  const preparedFields = prepareFieldsWithCustomParameters(
    filterFieldsForCheckout(methodParameters, cardInfo),
    cardInfo,
  );

  const renderedFields = renderFields({
    t: translate,
    clearErrors,
    setError,
    setValue,
    fields: updateFieldsDataStructure(preparedFields, cardInfo),
    control,
  });

  const amountButtonText = skeletonT(`${NAMESPACES.PW_KEYS}:PH.BUTTON.CONTINUE_AMOUNT`, {
    amount: getFormattedAmountWithCurrency(
      getPrettierFormatNumber(amount),
      currencyValue,
      currencyDisplaySetting,
    ),
  });

  const handleRecurringCheck = (cardId: number) => {
    if (shouldVerifyCVV) {
      setShowRecurringCheckModal(true);
      return;
    }
    recurringCheckTrigger({
      cardId,
      amount,
    }).then((res) => {
      if (res.data?.is_cvv_required) {
        setShowRecurringCheckModal(true);
      } else {
        const requiredFields = prepareRequiredFields(methodParameters.filter((field: Parameter) => field.isRequired));
        submitCallback(
          prepareFieldsDataForSubmit(getValues(), cardInfo.selectedCard || {}, requiredFields, dirtyFields),
          !!cardInfo?.selectedCard.isNew,
        );
      }
    });
  };

  const submitHandler: SubmitHandler<Inputs> = (fields: FieldValues) => {
    if (hasValidationError(fields, setError, {
      shouldValidateCard: !!cardInfo.selectedCard.isNew,
      cardFieldName,
      amount,
    })) {
      return;
    }

    const requiredFields = prepareRequiredFields(methodParameters.filter((field: Parameter) => field.isRequired));

    const cvvParam = methodParameters.find((param) => param.extendedType === ExtendedTypes.CardCvv);

    if ((cardInfo.selectedCard?.id && (
      cvvParam && !cvvParam.isRequired && cardInfo.selectedCard?.is_card_token_active))) {
      handleRecurringCheck(cardInfo.selectedCard?.id);
    } else {
      submitCallback(
        prepareFieldsDataForSubmit(fields, cardInfo.selectedCard || {}, requiredFields, dirtyFields),
        !!cardInfo?.selectedCard.isNew,
      );
    }
  };

  const setAmount = useCallback((amountFieldValue: number) => setValue('amount', amountFieldValue, {
    shouldValidate: true,
  }), [setValue]);

  const handleCloseRecurringModal = useCallback(() => {
    unregister('card_cvv');
    setShowRecurringCheckModal(false);
  }, [unregister]);

  const handleConfirmRecurringModal = useCallback(() => {
    const requiredFields = prepareRequiredFields(methodParameters.filter((field: Parameter) => field.isRequired));
    submitCallback(
      prepareFieldsDataForSubmit(getValues(), cardInfo.selectedCard || {}, requiredFields, dirtyFields),
      !!cardInfo?.selectedCard.isNew,
    );
    unregister('card_cvv');
    setShowRecurringCheckModal(false);
  }, [methodParameters,
    getValues,
    cardInfo.selectedCard,
    dirtyFields,
    unregister]);

  useEffect(() => {
    if (cardInfo.selectedCard) {
      const receivedDefaultValues: FieldValues = buildDefaultCheckoutValues(
        cardInfo.selectedCard,
        amount ? `${amount}` : prefillAmount,
      );
      setDefaultValues(receivedDefaultValues);
      reset(receivedDefaultValues);
    }
  }, [cardInfo.selectedCard,
    prefillAmount]);

  useEffect(() => {
    if (amount) {
      setValue('amount', amount);
      trigger('amount');
    }
  }, [amount]);

  useEffect(() => {
    if (uploadedFileForA2aFlow) {
      const formData = new FormData();
      formData.append('receipt', uploadedFileForA2aFlow);

      triggerUpload({
        payGroupId,
        requestBody: formData,
      });
    }
  }, [uploadedFileForA2aFlow,
    payGroupId]);

  useEffect(() => {
    dispatch(setUploadedFile(null));
    return () => {
      dispatch(setDisplaySelectListOptions(null));
    };
  }, []);

  if (!preparedFields?.length) {
    return null;
  }

  const getSelectListOptionsList = (fields: Parameter[]) => {
    const selectField = displaySelectListOptions && fields.find((field) => (
      displaySelectListOptions && displaySelectListOptions[field.name]
    ));
    if (displaySelectListOptions && selectField?.options?.length) {
      return (
        <SelectListOptions
          options={selectField.options}
          selectedOption={getValues(selectField.name)}
          setSelectValue={async (option: Option) => {
            setValue(selectField.name, option);
            await trigger(selectField.name);
          }}
        />
      );
    }

    return null;
  };

  const selectListOptionsList = getSelectListOptionsList(preparedFields);

  const renderFormContent = () => (
    <>
      <BonusInfoWrapper
        bonusInfo={bonusInfo}
        isBonusIncluded={isBonusIncluded}
      />
      <SavedCardWrapper
        isShow={!!cardsList?.length}
        cardInfo={cardInfo}
        cardsList={cardsList}
      />
      <div className="checkout-form__content">
        {withAmount && newLocal}
        {showAmountBlocks && (
          <AmountBlocks
            currentAmount={amount}
            amountBlocks={amountBlocksData}
            onClick={setAmount}
            currency={currency}
            methodName={methodName}
            min={limits?.min}
          />
        )}
        <Fee
          fee={fee}
          merchantUserFee={merchantUserFee}
        />
        <TaxContainer
          tax={tax}
          isTaxHintShown
          calculateNonTaxableAmount={calculateNonTaxableAmount}
          taxAmount={amountWithTaxes.taxAmount}
          amount={amount}
        />
        <ConversionBlock
          amount={amount}
          methodName={methodName}
          processingCurrency={processingCurrency}
          processingCurrencyRate={processingCurrencyRate}
        />
        {renderedFields}
        <CheckoutA2AError
          isA2aFlowError={isA2aFlow && isSendUploadedDocError}
          uploadError={uploadError}
        />
        <PCIDSSWrapper isShow={showPCIDSSLogo} />
      </div>
      <AdditionalInfo additionalData={additionalViewData} />
      <div className="checkout-form__footer">
        <Button
          type="submit"
          disabled={!isValid || !hasNotErrors || isDisabledByA2aFlow}
          loading={isFetchingSubmitRequest}
          variant={getSubmitButtonVariant(hasNotErrors)}
          doubleText={submitSubText}
        >
          {amountButtonText}
        </Button>
      </div>
      <RecurringCheckModal
        isOpened={displayRecurringCheckModal}
        bodyContent={(
          <RecurringModalBody
            cardInfo={cardInfo}
            control={control}
          />
            )}
        onClose={handleCloseRecurringModal}
        onConfirm={handleConfirmRecurringModal}
      />
    </>
  );

  return (
    <form
      className="checkout-form"
      onSubmit={handleSubmit(submitHandler)}
    >
      {!displaySelectListOptions && renderFormContent()}
      {selectListOptionsList}
    </form>
  );
};
