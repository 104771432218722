/* eslint-disable camelcase */
import IntegrationCommunication from 'services/integration-communication';
import { isNil } from 'ramda';
import urlParse from 'url-parse';
import { Routes } from 'Modulor/router';
import { PayHubDomainSessionsEnumsPlatformType } from 'store/models';
import { Location } from 'react-router-dom';
import {
  appConstants,
  CASHIER_CONTENT_PAGE_URL,
  CASHIER_CONTENT_PAGE_URL_INITIAL,
  CONTENT_PAGE_URL_PATTERN,
  LINK_TARGET,
  LOGO_URL_INITIAL,
  LOGO_URL_PATTERN,
  PAGE_STATUS,
  pmAction,
} from './constants';
import { softLocalStorageGet } from './storage';

export type LocationState = {
  isMainPage: boolean;
  isErrorPage: boolean;
  isSuccessPage: boolean;
  isProcessingPage: boolean;
  isDeposit: boolean;
  isDepositForm: boolean;
  isWithdrawal: boolean;
  isWithdrawalForm: boolean;
  isAccManagementStatusPage: boolean;
  isAccManagementSupportPage: boolean;
  isAccManagementStatusSuccessPage: boolean;
  isAccManagementStatusErrorPage: boolean;
  isLLAPage: boolean;
  isStatusPage: boolean;
  isWithdrawalCardList: boolean;
  isCheckoutPage: boolean;
  isCards: boolean;
  isWithdrawalVerifyAccount: boolean;
  isContent: boolean;
};

export const widgetLocations = {
  deposit: '/deposit',
  depositError: '/deposit/deposit-error',
  depositProcessing: '/deposit/deposit-processing',
  depositSuccess: '/deposit/deposit-success',

  withdrawal: '/withdrawal',
  withdrawalError: '/withdrawal/withdrawal-error',
  withdrawalProcessing: '/withdrawal/withdrawal-processing',
  withdrawalSuccess: '/withdrawal/withdrawal-success',

  accStatus: `/${pmAction.accManagement}/status`,
  accStatusSuccess: `/${pmAction.accManagement}/status/success`,
  accStatusError: `/${pmAction.accManagement}/status/error`,
  accSupport: `/${pmAction.accManagement}/support`,
};

export const getWidgetLocation = (pathname: string): LocationState => {
  const isMainPage = pathname === widgetLocations.deposit || pathname === widgetLocations.withdrawal;
  const isErrorPage = pathname.includes(widgetLocations.depositError)
    || pathname.includes(widgetLocations.withdrawalError);
  const isSuccessPage = pathname.includes(widgetLocations.depositSuccess)
    || pathname.includes(widgetLocations.withdrawalSuccess);
  const isProcessingPage = pathname.includes(widgetLocations.depositProcessing)
    || pathname.includes(widgetLocations.withdrawalProcessing);
  const isStatusPage = isSuccessPage || isProcessingPage || isErrorPage;
  const isDeposit = pathname.includes(widgetLocations.deposit);
  const isDepositForm = pathname.includes(`${widgetLocations.deposit}/`)
    && !pathname.includes(`${widgetLocations.deposit}/deposit`)
    && !isStatusPage;
  const isWithdrawal = pathname.includes(widgetLocations.withdrawal);
  const isWithdrawalForm = pathname.includes(`${widgetLocations.withdrawal}/`)
    && !pathname.includes(`${widgetLocations.withdrawal}/withdrawal`)
    && !isStatusPage;
  const isAccManagementStatusPage = pathname.includes(widgetLocations.accStatus);
  const isAccManagementStatusSuccessPage = pathname.includes(widgetLocations.accStatusSuccess);
  const isAccManagementStatusErrorPage = pathname.includes(widgetLocations.accStatusError);
  const isAccManagementSupportPage = pathname.includes(widgetLocations.accSupport);
  const isLLAPage = pathname.includes('payments/alt');
  const isWithdrawalCardList = pathname.includes(Routes.withdrawalCardList);
  const isCheckoutPage = pathname.includes(Routes.checkout);
  const isCards = pathname.includes(Routes.cards);
  const isWithdrawalVerifyAccount = pathname.includes(Routes.withdrawalVerifyAccount);
  const isContent = pathname.includes(Routes.content);

  return {
    isMainPage,
    isErrorPage,
    isSuccessPage,
    isProcessingPage,
    isDeposit,
    isDepositForm,
    isWithdrawal,
    isWithdrawalForm,
    isAccManagementStatusPage,
    isAccManagementStatusSuccessPage,
    isAccManagementStatusErrorPage,
    isAccManagementSupportPage,
    isLLAPage,
    isStatusPage,
    isWithdrawalCardList,
    isCheckoutPage,
    isCards,
    isWithdrawalVerifyAccount,
    isContent,
  };
};

export const parseQuery = (querystring?: string): Record<string, string | number | unknown> => {
  if (typeof querystring !== 'string') {
    return {};
  }

  const result = querystring.substring(querystring.indexOf('?') + 1).split('&');
  const params: Record<string, string | number | unknown> = {};
  const decodedUri = decodeURIComponent;
  let pair;

  // eslint-disable-next-line no-plusplus
  for (let i = result.length - 1; i >= 0; i--) {
    pair = result[i].split('=');
    params[decodedUri(pair[0])] = decodedUri(pair[1] || '');
  }

  return params;
};

interface GetMerchantRedirectUrl {
  merchantSuccessUrl?: string;
  merchantFailUrl?: string;
  merchantProcessingUrl?: string;
  merchantRedirectUrlFromState?: string;
}
export const getMerchantRedirectUrl = (params: GetMerchantRedirectUrl): string | undefined | null => {
  const {
    merchantSuccessUrl,
    merchantFailUrl,
    merchantProcessingUrl,
    merchantRedirectUrlFromState,
  } = params;
  const search: Record<string, string | number | unknown> = parseQuery(window.location.search);
  const {
    isSuccessPage,
    isErrorPage,
    isProcessingPage,
    isAccManagementSupportPage,
    isAccManagementStatusSuccessPage,
    isAccManagementStatusErrorPage,
  } = getWidgetLocation(window.location.pathname);
  const { merchant_redirect_url } = search;

  if (merchantSuccessUrl && (isSuccessPage || isAccManagementSupportPage || isAccManagementStatusSuccessPage)) {
    return merchantSuccessUrl;
  }

  if (merchantFailUrl && (isErrorPage || isAccManagementStatusErrorPage)) {
    return merchantFailUrl;
  }

  if (merchantProcessingUrl && isProcessingPage) {
    return merchantProcessingUrl;
  }

  return merchant_redirect_url as string || merchantRedirectUrlFromState;
};

export const getMerchantRedirectUrlForTransactionProcessing = (isSuccess: boolean): string => {
  const params: Record<string, string> = parseQuery(window.location.search) as Record<string, string>;
  const { merchant_redirect_url } = params;
  const { merchant_domain } = params;
  const merchant_url = softLocalStorageGet('merchant_url');
  const merchant_success_url = softLocalStorageGet('merchant_success_url');
  const merchant_processing_url = softLocalStorageGet('merchant_processing_url');

  if (isSuccess) {
    return merchant_success_url
      || merchant_domain
      || merchant_url
      || merchant_redirect_url
      || merchant_processing_url
      || window.location.origin;
  }

  return merchant_processing_url
    || merchant_success_url
    || merchant_redirect_url
    || merchant_domain
    || merchant_url
    || window.location.origin;
};

export const getDomainWithLang = (url: unknown): string => {
  if (typeof url !== 'string') {
    return '';
  }

  const urlPartsArr = url.split('/');
  const output = urlPartsArr.reduce((acc, curr, i) => {
    if (i === 0) {
      return curr;
    }

    if (i <= 3) {
      return `${acc}/${curr}`;
    }

    return acc;
  }, '');

  if (output.includes('http') || output.includes('://')) {
    return output;
  }

  return `https://${output}`;
};

export const getBlankLanguage = (): string => {
  try {
    const params = parseQuery(window.location.search) as { lang?: string };
    const l = softLocalStorageGet(appConstants.language_key) ?? params.lang;
    const lang = l ?? 'en';

    if (lang.length === 2) {
      return lang;
    }

    const [language] = lang.split('-');

    return language;
  } catch {
    return 'en';
  }
};

export const urlFormer = (url: string | unknown, status: PAGE_STATUS, isBackToGameButtonClicked?: boolean): string => {
  if (typeof url !== 'string') {
    return '';
  }

  const domain = getDomainWithLang(url);
  const language = getBlankLanguage();
  const urlHasLanguage = url.split('/').some((e) => e.length === 2);
  const urlHasFullPath = url.includes('deposit') || url.includes('withdraw');

  if (urlHasFullPath && status === 'error') { // for example: https://en.test.com/ru/withdraw
    return url;
  }

  if (urlHasLanguage) { // https://en.test.com/uk OR https://en.test.com/uk/
    if (status === 'error' && !isBackToGameButtonClicked) {
      const isLastSlash = url[url.length - 1] === '/';
      return isLastSlash ? `${domain}deposit` : `${domain}/deposit`;
    }

    return domain;
  }

  if (status === 'error' && !isBackToGameButtonClicked) {
    return `${domain}/${language}/deposit`;
  }

  return domain;
};

export const redirectTopToAccount = () => {
  IntegrationCommunication.sendMessage({
    id: 'redirectTo',
    value: '/my-account',
  });
};

interface GetLinkTargetTypeProps {
  platform?: PayHubDomainSessionsEnumsPlatformType;
  isSelfTarget: boolean;
}
export const getLinkTargetType = (props: GetLinkTargetTypeProps): LINK_TARGET => {
  const {
    platform = '',
    isSelfTarget,
  } = props;

  if (isSelfTarget) {
    return LINK_TARGET.SELF;
  }

  const mapObj = {
    [PayHubDomainSessionsEnumsPlatformType.NativeIos as string]: LINK_TARGET.SELF,
  };
  return mapObj[platform] || LINK_TARGET.BLANK;
};

export const addQueryParamTo = (url: string, key?: string, value?: unknown): string => {
  if (isNil(key) || isNil(value)) {
    return url;
  }

  const parsedUrl = urlParse(url);

  parsedUrl.set('query', `${parsedUrl.query}${parsedUrl.query ? '&' : '?'}${key}=${value}`);

  return parsedUrl.toString();
};

export const isContentPageUrl = (url: string): boolean => Boolean(url && url.includes(CASHIER_CONTENT_PAGE_URL));

export const isContentPageUrlInitial = (url: string): boolean => Boolean(url && url.includes(
  CASHIER_CONTENT_PAGE_URL_INITIAL,
));

export const transformContentUrl = (url: string): string => (isContentPageUrlInitial(url)
  ? url.replace(CONTENT_PAGE_URL_PATTERN, '/content')
  : url
);

const scrollToTop = (): void => {
  try {
    window.scroll({
      behavior: 'smooth',
      top: 0,
    });
  } catch (err) {
    if (err instanceof TypeError) {
      window.scroll(0, 0);
    } else {
      throw err;
    }
  }
};

export const locationListener = () => {
  scrollToTop();

  IntegrationCommunication.sendMessage({
    id: 'resetScroll',
  });
};

export const isLogoUrlInitial = (url: string) => url && url.includes(
  LOGO_URL_INITIAL,
);

export const updateLogoUrlViaTheme = (url: string, theme: string) => {
  if (!url) {
    return undefined;
  }

  return isLogoUrlInitial(url) ? url.replace(LOGO_URL_PATTERN, '') : url;
};

export const hasParentWidgetAndIsOnStatusPage = (curLocation: Location) => {
  let hasParentCashier = false;
  try {
    const currentLocation = getWidgetLocation(curLocation.pathname);
    const { href = '' } = window?.parent?.location || {};
    hasParentCashier = currentLocation.isStatusPage
      && (href.includes('deposit')
        || href.includes('withdrawal')
        || href.includes('demeter')
        || href.includes('cashier')
      ) && window?.parent !== window?.self;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('No parent here.');
  }

  return hasParentCashier;
};

export const getMerchantUrl = (merchantUrl?: string): string => merchantUrl
  || (parseQuery(window.location.search)?.merchant_domain || '') as string
  || '';
