import dayjs from 'dayjs';
import { setAmount } from 'store/slices/global';
import { Dispatch } from '@reduxjs/toolkit';
import { Routes } from 'Modulor/router';
import {
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
  PayHubCashierContractsTrustedContactResponse as TrustedContacts,
} from 'store/models';
import { FieldValues, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';
import { countryDialCode } from 'assets/countryDialCode';
import {
  prepareFieldsWithCustomParameters,
  setNullToNotRequiredFields,
} from 'utils';
import { UIWithdrawalCard } from 'store/formatters/commonPayments';
import { setAccVarificationData } from 'store/slices/withdrawal';
import { DynamicTypeObject, FlatStringObject, TFunction } from '../../commonTypes';
import { NAMESPACES } from '../../services/constants';
import { SendCodeProps } from './otpTypes';

export const isCardPanSelectTypeField = (param: Parameter) => [
  `${ExtendedTypes.CardPan}`,
  `${ExtendedTypes.CreditCard}`,
].includes(`${param.extendedType}`) && param.type === 'select';

export type FormConditions = {
  payoutAccountVerifBlocked?: boolean;
};

export const isBetshopCheckboxField = (param: Parameter) => param.type === 'check'
    && param.extendedType === ExtendedTypes.BetShop;

const filterFieldsForRender = (fields: FieldValues = []) => prepareFieldsWithCustomParameters(
  fields.filter((param: Parameter) => !isBetshopCheckboxField(param) && !isCardPanSelectTypeField(param)),
);

export const prepareFieldsForRender = (
  fields: FieldValues,
  { payoutAccountVerifBlocked }: FormConditions,
  cardParam: Parameter | undefined,
) => {
  if (payoutAccountVerifBlocked) {
    const preparedFields = fields.map((field: Parameter) => ({
      ...field,
      isDisabled: field.type === 'select',
    }));
    return filterFieldsForRender(preparedFields);
  }

  return !!cardParam && !cardParam?.options?.length
    ? prepareFieldsWithCustomParameters(fields as Parameter[])
    : filterFieldsForRender(fields);
};

export const prepareFieldsDataForSubmit = (
  fields: FieldValues,
  requiredFields: Parameter[],
  selectedCard: UIWithdrawalCard,
  cardParam: Parameter | undefined,
) => {
  const preparedFields: DynamicTypeObject = setNullToNotRequiredFields(requiredFields, fields);
  const dateFields = requiredFields.filter((field: Parameter) => field.type === 'date');

  if (dateFields.length) {
    dateFields.forEach((field: Parameter) => {
      preparedFields[field.name] = dayjs(preparedFields[field.name] as unknown as Date).toISOString();
    });
  }

  if (selectedCard.value && cardParam?.name) {
    return ({
      ...preparedFields,
      [cardParam?.name]: selectedCard.value.replace(/\s/gi, ''),
    });
  }

  return preparedFields;
};

export const isIncorrectCode = (status?: boolean): boolean => status === false;

export const getBlockedDurationValue = (timeString: string): string => {
  const [hours, minutes, seconds] = timeString.split(':');
  const totalMinutes = +hours * 60 + (+minutes + 1) + +seconds / 60;
  const currentDate = new Date();
  return new Date(currentDate.getTime() + totalMinutes * 60000).toISOString();
};

const CREDIT_CARD_TYPES = [
  ExtendedTypes.CardPan,
  ExtendedTypes.CreditCard,
];

interface RedirectForVerificationHookProps {
  requiredFields: Parameter[];
  trustedContacts?: TrustedContacts[];
  fields: FieldValues;
  supportUrl?: string;
  payoutAccountVerifBlocked: boolean;
  allowNewPayoutAccount?: boolean;
  hasNeededVerifCardsOptionsCount: boolean;
  dispatch: Dispatch;
  navigate: NavigateFunction;
}

export const handleRedirectForVerification = (props: RedirectForVerificationHookProps): void => {
  const {
    requiredFields,
    trustedContacts,
    fields,
    supportUrl,
    payoutAccountVerifBlocked,
    allowNewPayoutAccount,
    hasNeededVerifCardsOptionsCount,
    dispatch,
    navigate,
  } = props;

  const hasVerifiedParams = requiredFields.some((field) => field.verifyNewPayoutAccount);
  const fieldTypes = requiredFields.find((field) => field.extendedType
      && CREDIT_CARD_TYPES.includes(field.extendedType));
  const shouldRedirectToSupportPage = (!allowNewPayoutAccount && hasVerifiedParams) || !hasNeededVerifCardsOptionsCount;

  dispatch(setAccVarificationData({
    trustedContacts,
    card: fields.card,
    field: {
      name: fieldTypes?.name,
      dataType: fieldTypes?.dataType,
    },
    supportUrl,
  }));

  if (shouldRedirectToSupportPage || payoutAccountVerifBlocked) {
    navigate(Routes.accountManagementBlockedPage);
    return;
  }
  navigate(Routes.withdrawalVerifyAccount);
  dispatch(setAmount(fields.amount));
};

interface UpdateFieldsByOTP {
  isOTPEnabled: boolean;
  requiredFields: Parameter[];
  fields: FlatStringObject;
  dispatch: Dispatch;
  OTPCode?: string;
  OTPCodeFieldName?: string;
}

export const updateFieldsByOTP = (props: UpdateFieldsByOTP): void => {
  const {
    isOTPEnabled,
    OTPCode,
    OTPCodeFieldName,
    fields,
  } = props;

  if (!isOTPEnabled) {
    return;
  }

  if (OTPCodeFieldName) {
    fields[OTPCodeFieldName] = OTPCode || '';
  }
};

// eslint-disable-next-line max-len
export const findCountryDetailsByPhoneNumber = (phoneNumber: string) => countryDialCode.find((countryItem) => phoneNumber.startsWith(`+${countryItem.phonecode}`)
      || phoneNumber.startsWith(`00${countryItem.phonecode}`));

export const getFormattedPhone = (value: string): string => {
  if (!value) return '';
  return value.startsWith('+') ? value : `+${value}`;
};

export const formContentValue = (value: string): string => {
  try {
    const formattedValue = getFormattedPhone(value);
    const countryDetails = findCountryDetailsByPhoneNumber(formattedValue);
    const phoneCode = countryDetails?.phonecode;
    const phone = phoneCode ? formattedValue.replace(`+${phoneCode}`, '') : formattedValue;
    const res = [`+${phoneCode}`];

    for (let i = 0; i < phone.length; i += 3) {
      res.push(phone.substring(i, i + 3));
    }

    return res.join(' ');
  } catch (e) {
    return value;
  }
};

interface CheckPhoneValidationProps {
  phone: string;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  t: TFunction;
}

export const checkPhonePresentValidation = ({ phone, t, setError, clearErrors }: CheckPhoneValidationProps) => {
  if (!phone) {
    setError('phone', {
      type: 'manual',
      message: t(`${NAMESPACES.PW_KEYS}:PH.ERROR.EMPTY_PHONE_PROFILE`),
    });
  } else {
    clearErrors('phone');
  }
};

interface CheckAmountValidationProps {
  amount: string;
  setAmountValueError: (args: string) => void;
}

export const checkAmountValidation = ({ amount, setAmountValueError }: CheckAmountValidationProps) => {
  if (!parseFloat(amount)) {
    setAmountValueError(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.ERROR.INCORRECT_AMOUNT`);
  }
  return false;
};

interface IsPayloadsAndFormDataEqualProps {
  previousPayload: SendCodeProps;
  formData: {[key: string]: string | number | null | undefined};
  otpFiledName: string;
}

export const isPayloadsAndFormDataEqual = (
  { previousPayload, formData, otpFiledName }: IsPayloadsAndFormDataEqualProps,
) => {
  const { amount, parameters: payload } = previousPayload;
  const { amount: amountFromForm, [otpFiledName]: _, ...form } = formData;

  return amount === amountFromForm && Object.keys(payload).every(
    (key) => payload[key] === form[key],
  );
};

interface MapOtpFieldsToFormDataProps {
  otpFields: Parameter[];
  formData: {[key: string]: string | number | null | undefined};
}

// eslint-disable-next-line max-len
export const mapOtpFieldsToFormData = ({ otpFields, formData }: MapOtpFieldsToFormDataProps): { [key: string]: string } => otpFields.reduce<{ [key: string]: string }>((acc, field) => {
  const { name } = field;
  acc[name] = formData[name] !== undefined && formData[name] !== null ? String(formData[name]) : '';
  return acc;
}, {});
