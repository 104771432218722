import { Route, Routes as ReactRoutes } from 'react-router-dom';
import { FlowTypes } from 'store/constants';
import { PAGE_STATUS } from 'utils/constants';
import { DepositMethods } from './Deposit/DepositMethods';
import { SavedCards } from './components/Cards/List';
import { VerifyAccountPage } from './Withdrawal/VerifyAccount/VerifyPage';
import { WithdrawalCardList } from './Withdrawal/Cards/List';
import { WithdrawalMethod } from './Withdrawal/WithdrawalMethod';
import { DepositMethod } from './Deposit/DepositMethod';
import Status from './Status/StatusController';
import { ContentController } from './Content/ContentController';
import { WithdrawalMethods } from './Withdrawal/WithdrawalMethods';
import { VerificationBlockedPage } from './Withdrawal/VerifyAccount/VerificationBlockedPage';
import Page3DSResult from './components/Page3DSResult';
import PageLLA from './LLA/PageLLA';
import PageLLAStatus from './LLA/PageLLAStatus';

export enum Routes {
  'accountManagementBlockedPage' = '/account-management-blocked',
  'cards' = '/cards',
  'checkout' = 'checkout',
  // TODO: useless route, remove itw
  'content' = '/content',
  // END
  'deposit' = 'deposit',
  'depositError' = '/deposit/deposit-error',
  'depositMethod' = 'deposit/:provider/',
  'depositMethodForm' = 'deposit/:provider/:form',
  'depositProcessing' = '/deposit/deposit-processing',
  'depositSuccess' = '/deposit/deposit-success',
  'depositAwait' = '/deposit/deposit-await',
  'withdrawal' = 'withdrawal',
  'withdrawalMethod' = 'withdrawal/:provider/',
  'withdrawalCardList' = '/withdrawal-cards',
  'withdrawalVerifyAccount' = '/withdrawal-verify-account',
  'withdrawalProcessing' = '/withdrawal/withdrawal-processing',
  'withdrawalSuccess' = '/withdrawal/withdrawal-success',
  'withdrawalError' = '/withdrawal/withdrawal-error',
  'page3dsResult' = '/3ds_result',
  'pageLLA' = '/payments/interaction/:provider',
  'pageLLAStatus' = '/payments/status/:provider',
  'page3dsStatus' = '/payments/3ds_result/:provider',
}

export const router = (
  <ReactRoutes>
    <Route
      path={Routes.deposit}
      element={<DepositMethods />}
      errorElement={<h1>deposit failure</h1>}
    />
    <Route
      path={Routes.depositMethod}
      element={<DepositMethod />}
      errorElement={<h1>deposit failure</h1>}
    />
    <Route
      path={Routes.depositMethodForm}
      element={<DepositMethod />}
      errorElement={<h1>deposit failure</h1>}
    />
    <Route
      path={Routes.withdrawal}
      element={<WithdrawalMethods />}
      errorElement={<h1>withdrawal failure</h1>}
    />
    <Route
      path={Routes.withdrawalMethod}
      element={<WithdrawalMethod />}
      errorElement={<h1>withdrawal failure</h1>}
    />
    <Route
      path={Routes.withdrawalCardList}
      element={<WithdrawalCardList />}
      errorElement={<h1>withdrawal failure</h1>}
    />
    <Route
      path={Routes.withdrawalVerifyAccount}
      element={<VerifyAccountPage />}
      errorElement={<h1>verify account failure</h1>}
    />
    <Route
      path={Routes.withdrawalSuccess}
      element={(
        <Status
          status={PAGE_STATUS.success}
          type={FlowTypes.withdrawal}
        />
      )}
      errorElement={<h1>Success page failure</h1>}
    />
    <Route
      path={Routes.withdrawalProcessing}
      element={(
        <Status
          status={PAGE_STATUS.processing}
          type={FlowTypes.withdrawal}
        />
      )}
      errorElement={<h1>Processing page failure</h1>}
    />
    <Route
      path={Routes.withdrawalError}
      element={(
        <Status
          status={PAGE_STATUS.error}
          type={FlowTypes.withdrawal}
        />
      )}
      errorElement={<h1>Error page failure</h1>}
    />
    <Route
      path={Routes.accountManagementBlockedPage}
      element={<VerificationBlockedPage />}
      errorElement={<h1>verify account failure</h1>}
    />
    <Route
      path={Routes.depositSuccess}
      element={(
        <Status
          status={PAGE_STATUS.success}
          type={FlowTypes.deposit}
        />
    )}
      errorElement={<h1>Success page failure</h1>}
    />
    <Route
      path={Routes.depositError}
      element={(
        <Status
          status={PAGE_STATUS.error}
          type={FlowTypes.deposit}
        />
      )}
      errorElement={<h1>Error page failure</h1>}
    />
    <Route
      path={Routes.depositProcessing}
      element={(
        <Status
          status={PAGE_STATUS.processing}
          type={FlowTypes.deposit}
        />
      )}
      errorElement={<h1>Processing page failure</h1>}
    />
    <Route
      path={Routes.content}
      element={<ContentController />}
      errorElement={<h1>Content page failure</h1>}
    />
    <Route
      path={Routes.cards}
      element={<SavedCards />}
      errorElement={<h1>Cards list page failure</h1>}
    />
    <Route
      path={Routes.page3dsResult}
      element={<Page3DSResult />}
      errorElement={<h1>3DS page failure</h1>}
    />
    <Route
      path={Routes.page3dsStatus}
      element={<PageLLAStatus is3DS />}
      errorElement={<h1>3DS page failure</h1>}
    />
    <Route
      path={Routes.depositAwait}
      element={(
        <Status
          status={PAGE_STATUS.await}
          type={FlowTypes.deposit}
        />
      )}
      errorElement={<h1>Await page failure</h1>}
    />
    <Route
      path={Routes.pageLLA}
      element={<PageLLA />}
      errorElement={<h1>LLA page failure</h1>}
    />
    <Route
      path={Routes.pageLLAStatus}
      element={<PageLLAStatus />}
      errorElement={<h1>LLA status failure</h1>}
    />
    <Route
      path="*"
      element={<DepositMethods />}
      errorElement={<h1>deposit failure</h1>}
    />
  </ReactRoutes>
);
