/* eslint-disable react/no-array-index-key */
import { MouseEvent } from 'react';
import clx from 'classnames';
import { Content, Icon, Image, ListView, Typography } from '@modulor/react';
import { DisabledMethodsGroups, UIPaymentGroups, UIPayoutGroups } from 'store/formatters';
import { useAppSelector } from 'store/hooks';
import { initialThemeSelector, logoSizesSelector } from 'store/slices/global';
import { ConditionalContentListCell } from 'Modulor/components/ConditionalContentListCell';
import {
  getDisabledMethods,
  getLayoutType, getTheme,
  getTranslationMessage,
  getTwoMethodColumns,
  GROUPS,
  LayoutType,
  updateLogoUrlViaTheme,
} from 'utils';
import { NAMESPACES } from 'services/constants';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { formMethodSubTitle } from './utils';
import { DepositMethodsListDisabled } from './DepositMethodsListDisabled';
import { formMethodSubTitleTranslationKeys } from '../payUtils';

interface DepositMethodGroupListProps {
  name: GROUPS;
  list: UIPaymentGroups[];
  handleSelectDepositMethod: (m: UIPaymentGroups) => (event: MouseEvent) => void;
  groupCount: number;
}

export const DepositMethodGroupList = (props: DepositMethodGroupListProps): null | JSX.Element => {
  const {
    name,
    list,
    handleSelectDepositMethod,
    groupCount,
  } = props;
  const isDesktopLayout = getLayoutType() === LayoutType.desktop;
  const initialTheme = useAppSelector(initialThemeSelector) || getTheme();
  const logoSizes = useAppSelector(logoSizesSelector);
  const { translate, skeletonT, isSkeleton } = useSkeletonTranslation();

  if (!list.length) {
    return null;
  }

  const captions = {
    [GROUPS.Popular]: skeletonT(`${NAMESPACES.PW_KEYS}:PH.POPULAR_METHOD_LIST_CAPTION`),
    [GROUPS.LostProfit]: skeletonT(`${NAMESPACES.PW_KEYS}:PH.LOST_PROFIT_METHOD_LIST_CAPTION`),
    [GROUPS.Regular]: skeletonT(`${NAMESPACES.PW_KEYS}:PH.REGULAR_METHOD_LIST_CAPTION`),
    [GROUPS.Disabled]: skeletonT(`${NAMESPACES.PW_KEYS}:PH.DISABLED_METHOD_LIST_CAPTION`),
  };

  const methodColumns: UIPaymentGroups[][] = isDesktopLayout
    ? getTwoMethodColumns<UIPaymentGroups>(list)
    : [list];

  if (name === GROUPS.Disabled) {
    const disabledMethods: DisabledMethodsGroups = getDisabledMethods<UIPaymentGroups>(
      list,
      isDesktopLayout,
    );
    const { disabledMethodListUntil, disabledMethodList } = disabledMethods;
    const isDisabledMethodsExist = isDesktopLayout
      ? ((disabledMethodListUntil?.length && disabledMethodListUntil
        .some((item) => Array.isArray(item) && item.length > 0))
          || (disabledMethodList?.length && disabledMethodList
            .some((item) => Array.isArray(item) && item.length > 0))
      )
      : ((disabledMethodListUntil && disabledMethodListUntil[0].length > 0)
          || (disabledMethodList && disabledMethodList[0].length > 0)
      );

    return !isDisabledMethodsExist ? null : (
      <>
        <div
          data-testid="method-group-caption"
          data-translation-key={`${NAMESPACES.PW_KEYS}:PH.DISABLED_METHOD_LIST_CAPTION`}
          data-icon-name="clock_outlined"
          className="method-group-caption"
        >
          <Icon
            className="method-group-caption__icon"
            data-testid="method-group-caption-icon"
            name="clock_outlined"
            size={24}
          />
          <Typography
            variant="caption-1-regular-caps"
            dataTestId="disable-method-list-caption"
          >
            {' '}
            {skeletonT(`${NAMESPACES.PW_KEYS}:PH.DISABLED_METHOD_LIST_CAPTION`)}
          </Typography>
        </div>
        <div
          className={clx('deposit-methods', 'deposit-methods__disabled', {
            desktop: isDesktopLayout,
          })}
          data-testid="method-list-disabled"
        >
          <div className="row">
            {disabledMethods?.disabledMethodListUntil?.map((column: UIPayoutGroups[], index) => (
              <DepositMethodsListDisabled
                key={`disabledMethodListUntil__deposit__${index}`}
                list={column as UIPaymentGroups[]}
                initialTheme={initialTheme}
              />
            ))}
          </div>

          <div className="row">
            {disabledMethods?.disabledMethodList?.map((column: UIPayoutGroups[], index) => (
              <DepositMethodsListDisabled
                key={`disabledMethodList__deposit__${index}`}
                list={column as UIPaymentGroups[]}
                initialTheme={initialTheme}
              />
            ))}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {groupCount !== 1 && (
      <div
        data-testid={`method-group-caption__${name}`}
        data-translation-key={name}
        data-icon-name="clock_outlined"
        className="method-group-caption"
      >
        <Typography
          variant="caption-1-regular-caps"
          dataTestId="group-method-list-caption"
        >
          {' '}
          {captions[name]}
        </Typography>
      </div>
      )}
      <div
        className={clx('deposit-methods', {
          desktop: isDesktopLayout,
        })}
        data-testid={`deposit-methods-list__${name}`}
      >
        {methodColumns.map((column: UIPaymentGroups[]) => (
          <ListView
            key={JSON.stringify(column)}
            divider
          >
            {column.map((m) => (
              <ConditionalContentListCell
                className={clx('deposit-methods__item', {
                  'lost-profit': m.isLostProfit,
                  'text-skeleton': isSkeleton,
                })}
                data-translation-key={formMethodSubTitleTranslationKeys({
                  processingTime: m.processingTimeTranslationKey,
                  fee: m.merchantUserFee || {},
                  lostProfitInfo: 'lostProfitInfo' in m ? m.lostProfitInfo : null,
                  isIncludeBonus: 'isIncludeBonus' in m ? m.isIncludeBonus : false,
                  limits: m.limits,
                })}
                key={m.id}
                text={getTranslationMessage({
                  t: translate,
                  value: m.displayName,
                }) || ''}
                subtitle={formMethodSubTitle({
                  t: translate,
                  processingTime: m.processingTimeTranslationKey,
                  fee: m.merchantUserFee || m.fee || {},
                  lostProfitInfo: m.lostProfitInfo,
                  isIncludeBonus: m.isIncludeBonus,
                  limits: m.limits,
                  currency: m.currencyAlpha3,
                })}
                onClick={handleSelectDepositMethod(m)}
                multiline
                leftImageSize={logoSizes.width}
                image={(
                  <Image
                    width={`${logoSizes.width}`}
                    dataTestId="method-icon"
                    data-id="method-logo"
                    alt={m.displayName}
                    src={updateLogoUrlViaTheme(m.logoUri, initialTheme)}
                    style={{
                      height: logoSizes.height,
                      width: logoSizes.width,
                    }}
                  />
                    )}
                content={<Content icon="chevron-right" />}
              />
            ))}
          </ListView>
        ))}
      </div>
    </>
  );
};
