/* eslint-disable @typescript-eslint/no-empty-function */
import { FC } from 'react';
import { Image, ListView, Typography } from '@modulor/react';
import { ConditionalContentListCell } from 'Modulor/components/ConditionalContentListCell';
import { UIPaymentGroups } from 'store/formatters';
import { useAppSelector } from 'store/hooks';
import { logoSizesSelector } from 'store/slices/global';
import {
  updateLogoUrlViaTheme,
  getRestrictedUntilText,
} from 'utils';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { formMethodSubTitle } from './utils';
import { NAMESPACES } from '../../services/constants';
import { formMethodSubTitleTranslationKeys } from '../payUtils';

interface MethodsListProps {
    list?: UIPaymentGroups[];
    initialTheme: string;
}

export const DepositMethodsListDisabled: FC<MethodsListProps> = (props: MethodsListProps) => {
  const {
    list,
    initialTheme,
  } = props;
  const { translate } = useSkeletonTranslation();
  const logoSizes = useAppSelector(logoSizesSelector);
  const disableMethodsStyles: { [param: string]: string } = {
    '--list-cell-left-title': 'var(--text-disable)',
    '--list-cell-left-subtitle': 'var(--text-disable)',
  };

  if (!list || !list.length) {
    return null;
  }
  return (
    <ListView
      key={JSON.stringify(list)}
      divider
    >
      {list.map((m) => (
        <ConditionalContentListCell
          className="deposit-methods__item deposit-methods__item__disabled"
          data-translation-key={formMethodSubTitleTranslationKeys({
            processingTime: m.processingTimeTranslationKey,
            fee: m.merchantUserFee || {},
            limits: m.limits,
          })}
          key={m.id}
          text={translate(m.displayName, {
            ns: NAMESPACES.PAYGOUPS_AND_PROCESSING_TIME,
          }) || ''}
          style={disableMethodsStyles}
          subtitle={formMethodSubTitle({
            t: translate,
            processingTime: m.processingTimeTranslationKey,
            fee: m.merchantUserFee || {},
            limits: m.limits,
            currency: m.currencyAlpha3,
          })}
          onClick={() => {}}
          leftImageSize={logoSizes.width}
          multiline
          image={(
            <Image
              className="disabled"
              dataTestId="method-icon-disabled"
              width={`${logoSizes.width}`}
              alt={m.displayName}
              src={updateLogoUrlViaTheme(m.logoUri, initialTheme)}
              style={{
                height: logoSizes.height,
                width: logoSizes.width,
              }}
            />
            )}
          content={(
            <Typography
              variant="subhead-regular"
              dataTestId="restricted-time"
            >
              <span
                data-translation-key={getRestrictedUntilText(translate, m.restrictedUntil).key}
              >
                {getRestrictedUntilText(translate, m.restrictedUntil).message}
              </span>
            </Typography>
            )}
        />
      ))}
    </ListView>
  );
};
