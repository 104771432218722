import { FC, FormEvent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { TextField } from '@modulor/react';
import { FlowTypes } from 'store/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAmount, userBalanceSelector } from 'store/slices/global';
import { getPattern, getPrettierFormatNumber, getFormattedAmountWithCurrency } from 'utils';
import { NAMESPACES } from 'services/constants';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { currencyDisplaySelector as depositCurrencyDisplaySelector } from 'store/slices/deposit';
import { currencyDisplaySelector as withdrawalCurrencyDisplaySelector } from 'store/slices/withdrawal';
import { getAmountErrorKey } from '../fieldErrors';
import { amountPatternValidator, isAmountAboveUserBalanceValidator } from '../../utils/validator';

interface AmountFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  min?: number;
  max?: number;
  regexp?: string;
  placeHolder?: string;
  currency: string;
  flowType: FlowTypes;
  isDisabled?: boolean;
  isAutoSubmit?: boolean;
}

export const AmountField:FC<AmountFieldProps> = (props) => {
  const {
    name,
    control,
    min,
    max,
    regexp,
    currency,
    flowType,
    placeHolder = '',
    isDisabled,
    isAutoSubmit,
  } = props;
  const { translate } = useSkeletonTranslation();
  const dispatch = useAppDispatch();
  const userBalance = useAppSelector(userBalanceSelector);
  const pattern = getPattern(regexp);

  const currencyDisplaySetting = useAppSelector(flowType === FlowTypes.deposit ? (
    depositCurrencyDisplaySelector
  ) : withdrawalCurrencyDisplaySelector);

  const labelText = translate(`${NAMESPACES.PW_KEYS}:PH.LABEL.LIMIT_AMOUNT_HINT`, {
    min: getFormattedAmountWithCurrency(getPrettierFormatNumber(min), currency, currencyDisplaySetting),
    max: getFormattedAmountWithCurrency(getPrettierFormatNumber(max) || '∞', currency, currencyDisplaySetting),
  }, true);

  if (!isDisabled && isAutoSubmit) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
        min,
        max,
        validate: {
          isAmountAboveUserBalance: (value) => isAmountAboveUserBalanceValidator({
            amount: value,
            userBalance,
            flowType,
            min,
          }),
          pattern: (value) => amountPatternValidator({
            amount: value,
            pattern,
            flowType,
          }),
        },
      }}
      render={({ field, fieldState }) => {
        const amountForErrorText = fieldState.error?.type === 'max' ? max : min;
        const errorMessageKey = getAmountErrorKey(flowType, fieldState.error?.type);
        const errorMessage = translate(errorMessageKey, {
          amount: `${getFormattedAmountWithCurrency(
            getPrettierFormatNumber(amountForErrorText),
            currency,
            currencyDisplaySetting,
          )} `,
        });
        const onChangeHandler = (value: FormEvent<HTMLDivElement> | string) => {
          let result = value;

          if (typeof value === 'string') {
            // NOTE: . (dot) as separator
            result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          }
          dispatch(setAmount(`${result}`));
          field.onChange(result);
        };
        const value = field.value ? `${field.value}` : '';

        return (
          <TextField
            {...field}
            data-testid="amount-field"
            value={getPrettierFormatNumber(value)}
            onChange={onChangeHandler}
            inputMode="numeric"
            name={name}
            error={errorMessage}
            label={labelText}
            placeholder={placeHolder}
            type="text"
            maxLength={11}
            disabled={isDisabled}
          />
        );
      }}
    />
  );
};
