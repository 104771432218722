import { FC, useEffect } from 'react';
import { parseQuery } from 'utils';
import { Timeout } from 'react-number-format/types/types';
import { useLazyRequest3dsQuery } from 'store/services/page3dsAPI';
import { FlowTypes } from 'store/constants';
import { StatusRedirect } from './StatusRedirect';
import { useNavigateWithSearch } from '../hooks';

export const Page3DSResult:FC<unknown> = () => {
  const navigate = useNavigateWithSearch();
  const [
    triggerRequest, response3DSResult,
  ] = useLazyRequest3dsQuery();
  const { data, error } = response3DSResult;

  const askParentSession = () => {
    window.parent.postMessage({
      id: 'getSessionIdFromParentFrame',
    }, '*');
  };

  useEffect(() => {
    let delayGet3DSResultRequest: string | number | Timeout | undefined;
    clearTimeout(delayGet3DSResultRequest);
    askParentSession();
    delayGet3DSResultRequest = setTimeout(() => {
      const requestData = parseQuery(window.location.search);
      const convertedData = Object.entries(requestData).reduce((acc, [key, value]) => ({
        ...acc,
        [key.toLowerCase()]: value,
      }), {});
      try {
        triggerRequest(convertedData);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        navigate('/deposit/deposit-error/');
      }
    }, 1000);

    return () => clearTimeout(delayGet3DSResultRequest);
  }, [
    triggerRequest,
    navigate,
  ]);

  if (error) {
    navigate('/deposit/deposit-error/');
  }

  if (error) {
    return null;
  }

  return (
    <StatusRedirect
      paymentType={FlowTypes.deposit}
      redirectForm={data || {}}
    />
  );
};

export default Page3DSResult;
