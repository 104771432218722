import { softLocalStorageGet, softLocalStorageRemove, softLocalStorageSet, appConstants } from 'utils';
import IntegrationCommunication from '../services/integration-communication';

interface GetIsSessionExpiredProps {
  sessionIdSaved: string;
  sessionExpirationTime: number;
}

interface SetPrevSessionDataToLocalStorageProps {
  sessionId: string;
  sessionExpirationTime: number;
}

type SessionWithTimestamp = [string, number];

const getPrevSessionData = (): [] | SessionWithTimestamp[] => {
  let prevSessionIdData = [];

  try {
    prevSessionIdData = JSON.parse(
      softLocalStorageGet(appConstants.phPrevSessionIdData) || '',
    ) || [];
    // eslint-disable-next-line no-empty
  } catch {}

  if (Array.isArray(prevSessionIdData)) {
    return prevSessionIdData;
  }
  softLocalStorageRemove(appConstants.phPrevSessionIdData);
  return [];
};

export const getIsSessionExpired = (props: GetIsSessionExpiredProps): boolean => {
  const { sessionIdSaved, sessionExpirationTime } = props;
  const now = new Date().getTime();
  const prevSessionIdDataArray = getPrevSessionData();
  const prevSessionIdDataMap:Map<string, number> = new Map(prevSessionIdDataArray);
  const PrevSessionExpired = prevSessionIdDataMap.get(sessionIdSaved);
  const isPrevSessionExpired = PrevSessionExpired && PrevSessionExpired <= now;
  const isCurrentSessionExpired = sessionExpirationTime <= now;

  return Boolean(isCurrentSessionExpired || !sessionIdSaved || isPrevSessionExpired);
};

export const setPrevSessionDataToLocalStorage = (
  props: SetPrevSessionDataToLocalStorageProps,
): void => {
  const { sessionId, sessionExpirationTime } = props;
  const arrayLength = 10;

  const prevSessionIdData = getPrevSessionData();
  while (prevSessionIdData.length > arrayLength) {
    prevSessionIdData.shift();
  }
  const prevSessionIdDataMap = new Map(prevSessionIdData);

  prevSessionIdDataMap.set(sessionId, sessionExpirationTime);
  const entries = [...Array.from(prevSessionIdDataMap)];

  softLocalStorageSet(appConstants.phPrevSessionIdData, JSON.stringify(entries));
};

interface AutoReinitArguments {
  isSubmitted: boolean;
  isNativeDevice: boolean;
  merchantUrl: string;
}
export const autoReinit = (componentState: AutoReinitArguments): void => {
  const { isSubmitted, isNativeDevice, merchantUrl } = componentState;

  if (!isSubmitted) {
    return;
  }

  if (!isNativeDevice) {
    IntegrationCommunication.sendMessage({
      id: 'reInitCashier',
    });
    return;
  }
  window.location.assign(merchantUrl);
};
