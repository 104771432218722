import React, { FC, useEffect, useMemo } from 'react';
import { ListView, ListCell, Image } from '@modulor/react';
import { FlowTypes } from 'store/constants';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  useGetClickStreamPayGroupCashierContext,
  getClickStreamEvent,
} from 'services/clickstream';
import { getStringWithoutSpaces } from 'utils';
import { getGBFeatures } from 'services/growthbook';
import { NAMESPACES } from 'services/constants';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import './BonusInfo.scss';

interface BonusInfoTopBlock {
  bonusName?: string;
  bonusDescription?: string;
}

const gbFeatures = getGBFeatures();

export const BonusInfo: FC<BonusInfoTopBlock> = (props) => {
  const {
    bonusName,
    bonusDescription,
  } = props;
  const { translate } = useSkeletonTranslation();
  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(FlowTypes.deposit);
  const payGroupContext = useGetClickStreamPayGroupCashierContext(FlowTypes.deposit);
  // temporary we can get  bonusName & bonusDescription with the same text and should avoid duplicating
  // https://gr8-tech.atlassian.net/browse/BILL-16749
  const gbResult = gbFeatures.growthBook.evalFeature('billing.bonus.view');
  const isParamsEquals = getStringWithoutSpaces(bonusName || '') === getStringWithoutSpaces(bonusDescription || '');
  const transformedBonusName = (bonusName && !isParamsEquals) ? bonusName : translate(`${NAMESPACES.PW_KEYS}:PH.BONUS`);
  const contextToPush = payGroupContext.data && Object.keys(payGroupContext.data).length > 0
    ? [cashierContext,
      payGroupContext]
    : [cashierContext];

  const experimentStyles = useMemo(() => {
    const experimentBonusClassnames = gbResult?.value ? 'experiment-bonus-wrapper' : undefined;
    const experimentBonusStyles: { [param: string]: string } | undefined = gbResult?.value ? {
      '--list-cell-left-title': '#000',
      '--list-cell-left-subtitle': '#000',
      '--list-cell-background': 'url(/assets/exp-bg-bonus.svg)',
      'background-size': 'cover',
      'background-position': 'center center',
    } : undefined;

    return {
      className: experimentBonusClassnames,
      style: experimentBonusStyles,
    };
  }, [gbResult?.value]);

  const experimentBonusIcon = useMemo(() => (
    gbResult?.value ? '/assets/experiment-bonus-icon.png' : '/assets/bonus-icon.png'
  ), [gbResult?.value]);

  useEffect(() => {
    const event = getClickStreamEvent.cashier_method_bonus_banner_view;
    clickstream.push(event, contextToPush);
  }, []);

  const bonusInfoCLickHandler = () => {
    const event = getClickStreamEvent.cashier_method_bonus_banner_click;
    clickstream.push(event, contextToPush);
  };

  return (
    <ListView
      className="bonus-info-component"
      dataTestId="bonus-info-component"
      data-translation-key={bonusDescription}
    >
      <ListCell
        image={(
          <Image
            width="40"
            height="40"
            src={experimentBonusIcon}
            alt="bonus"
            className="bonus-icon"
          />
        )}
        text={bonusDescription}
        subtitle={transformedBonusName}
        multiline
        inverted
        onClick={bonusInfoCLickHandler}
        {...experimentStyles}
      />
    </ListView>
  );
};
