import { FC } from 'react';
import { Typography } from '@modulor/react';
import { NAMESPACES } from 'services/constants';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import './ProcessingTime.scss';
import { getTranslationMessage } from '../../utils';

interface ProcessingTimeProps {
  message?: string;
}

export const ProcessingTime: FC<ProcessingTimeProps> = (props) => {
  const { message } = props;
  const { skeletonT, translate } = useSkeletonTranslation();

  if (!message) {
    return null;
  }

  const messageText = getTranslationMessage({
    t: translate,
    value: message,
  });

  return (
    <div className="processing-time-container">
      <Typography variant="subhead-regular">
        {
          skeletonT(`${NAMESPACES.PAYGOUPS_AND_PROCESSING_TIME}:PH.PROCESSING_TIME`)
        }
      </Typography>
      <Typography variant="subhead-regular">{messageText}</Typography>
    </div>
  );
};
