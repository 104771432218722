/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { useLazyContentPageQuery } from 'store/services/contentPageAPI';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { brandSelector, langSelector, userIdSelector } from 'store/slices/global';
import { Button, Icon, Loader, NavigationBar, Toast } from '@modulor/react';
import { closeContentPageUrl, contentPageUrlSelector, isContentPageOpenSelector } from 'store/slices/contentPage';
import { getContent, formContent } from 'utils';
import { useFrameChangeHeight } from 'Modulor/hooks';
import { NAMESPACES } from 'services/constants';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import CustomModal from '../CustomModal';

export const ContentController = () => {
  const { translate } = useSkeletonTranslation();
  const dispatch = useAppDispatch();
  const brand = useAppSelector(brandSelector);
  const lang = useAppSelector(langSelector).toLowerCase();
  const url = useAppSelector(contentPageUrlSelector);
  const isOpen = useAppSelector(isContentPageOpenSelector);
  const userId = useAppSelector(userIdSelector);
  const [trigger, result] = useLazyContentPageQuery();
  const { data = [], isFetching, isError, isSuccess, error } = result;
  const closeButtonText = translate(`${NAMESPACES.PW_KEYS}:PH.BUTTON.ACCEPT`) || '';
  const [content, setContent] = useState<string>('');
  useFrameChangeHeight(isSuccess || isError);
  const onClose = () => dispatch(closeContentPageUrl());

  useEffect(() => {
    if (isOpen) {
      trigger({
        url,
        userId,
      });
    }
  }, [
    isOpen,
    url,
    userId,
  ]);

  useEffect(() => {
    if (!isFetching && isOpen && data.length) {
      const currentContent = getContent(data, lang, brand);
      setContent(formContent(currentContent, userId));
    }
  }, [
    isFetching,
    isOpen,
    data,
  ]);

  return (
    <>
      {isOpen && isError && (
        <Toast
          variant="error"
          visible={Boolean(isError)}
          text={translate(error.toString())}
          multiline
          rightIcon={(
            <Icon
              name="close"
            />
          )}
          duration={2500}
          onHide={() => {}}
        />
      )}
      <CustomModal
        isPopupView
        open={isOpen}
        openModal={isOpen}
        onShadowClick={onClose}
        onSwipeDown={onClose}
        header={(
          <NavigationBar
            transparent
            title={translate(`${NAMESPACES.PW_KEYS}:PH.TITLE.INFORMATION`)}
            right={(
              <Icon
                name="close"
                onClick={onClose}
              />
                  )}
          />
          )}
        buttonNumber={1}
        buttons={(
          <Button
            variant="primary"
            onClick={onClose}
          >
            {closeButtonText}
          </Button>
          )}
      >
        <div className="content-page-wrapper">
          {isFetching && <Loader />}
          <div
            id="content-anchor"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};
