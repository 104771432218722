import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CarouselState } from '../models';
import { UIPaymentGroups, UIPayoutGroups } from '../formatters';

export const initialState:CarouselState = {
  isCarouselView: false,
  isInitialLoad: true,
  isCarouselViewChanged: false,
  methodList: [],
  selectedMethodId: null,
};

const carouselSlice = createSlice({
  name: 'carousel',
  initialState,
  reducers: {
    setCarouselSelectedMethodId: (state, action: PayloadAction<number>) => ({
      ...state,
      selectedMethodId: action.payload,
    }),
    setCarouselMethods: (state, action: PayloadAction<(UIPaymentGroups | UIPayoutGroups)[]>) => ({
      ...state,
      methodList: action.payload,
      isCarouselView: true,
    }),
    setInitialCarouselLoad: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isInitialLoad: action.payload,
    }),
    setCarouselViewOn: (state) => ({
      ...state,
      isCarouselView: true,
      isCarouselViewChanged: true,
    }),
    setCarouselViewOff: (state) => ({
      ...state,
      isCarouselView: false,
      isCarouselViewChanged: true,
    }),
    resetCarouselViewState: (state) => ({
      ...state,
      isCarouselView: true,
      isCarouselViewChanged: false,
    }),
  },
});

export const carouselMethodListSelector = ({
  carousel,
}: { carousel: CarouselState }): (UIPayoutGroups | UIPaymentGroups)[] => carousel.methodList || [];

export const isCarouselViewSelector = ({
  carousel,
}: { carousel: CarouselState }): boolean => carousel.isCarouselView;

export const isCarouselViewChangedSelector = ({
  carousel,
}: { carousel: CarouselState }): boolean => carousel.isCarouselViewChanged;

export const isInitialCarouselLoadSelector = ({
  carousel,
}: { carousel: CarouselState }): boolean => carousel.isInitialLoad;

export const selectedMethodIdSelector = ({
  carousel,
}: { carousel: CarouselState }): number | null => carousel.selectedMethodId;

export const {
  setCarouselMethods,
  setCarouselViewOn,
  setCarouselViewOff,
  setCarouselSelectedMethodId,
  resetCarouselViewState,
  setInitialCarouselLoad,
} = carouselSlice.actions;

export default carouselSlice.reducer;
