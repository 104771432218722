import React from 'react';
import { ListCell, ListView } from '@modulor/react';
import { Control } from 'react-hook-form';
import { CardState } from 'store/slices/userCard';
import { getCardsIcon, getCardsPlaceholder, getCardsTypeName } from '../../components/Cards/utils';
import { CvvField } from '../../components/CvvField';
import { getCVVField } from '../../fieldGetter';

interface RecurringModalBodyProps {
    cardInfo: CardState;
    control: Control;
}

const RecurringModalBody = ({ cardInfo, control }: RecurringModalBodyProps) => (
  <div className="cvv-modal-content">
    <ListView className="card-box">
      <ListCell
        text={getCardsTypeName(cardInfo.selectedCard?.bin)}
        subtitle={getCardsPlaceholder(cardInfo.selectedCard?.last4, cardInfo.selectedCard?.bin)}
        image={getCardsIcon(cardInfo.selectedCard?.bin)}
      />
    </ListView>
    <CvvField
      {...getCVVField()}
      control={control}
      tooltipPlacement="left"
    />
  </div>
);

export default RecurringModalBody;
