import { Centrifuge } from 'centrifuge';
import { Analytics, AnalyticsGlobalContext } from 'big-brother';
import { getMappingChannel } from 'utils';
import { AnalyticsEventPayload, ClickstreamProps, SelfDescribingJson } from './types';

class EventAnalytics {
  private instance: Analytics;

  public constructor(props: ClickstreamProps) {
    const transformedUrl = props.merchantUrl.replace(/^https?:\/\//i, 'wss://');
    const myCentrifugoInstance = new Centrifuge(`${transformedUrl}/crtc`, {
      protocol: 'json',
      data: {
        Authorization: props.authToken,
      },
    });

    myCentrifugoInstance.connect();

    this.instance = new Analytics({
      appId: 'cashier',
      centrifugo: myCentrifugoInstance,
      crossdomainId: props.clickstreamId,
      globalContext: {
        channel: getMappingChannel(props.appChannel, props.platform),
        brand: props.brand,
        language: props.language,
      },
    });
  }

  // you can send a simple event using a schema
  public push(event: AnalyticsEventPayload, context: Array<SelfDescribingJson>) {
    this.instance.push({
      schema: event.schema,
      data: event.data,
    }, context);
  }

  // you can send a pageview on history change
  public pageView() {
    this.instance.pageview();
  }

  // you can update a globalContext supplied on the init stage in case of changes
  public updateContext(data: AnalyticsGlobalContext) {
    this.instance.updateDefaultGlobalContext(data);
  }
}

export { EventAnalytics };
