import React from 'react';
import SslSecure from '../../../assets/SslSecure';
import PciDss from '../../../assets/PciDss';

interface PCIDSSWrapperProps {
  isShow: boolean;
}

const PCIDSSWrapper = ({ isShow }: PCIDSSWrapperProps) => (isShow
  ? (
    <div className="checkout-form__bottom-icons-box">
      <SslSecure />
      <PciDss />
    </div>
  ) : null);

export default PCIDSSWrapper;
