import { HTMLAttributes, MouseEventHandler, ReactNode, RefAttributes } from 'react';
import { ListCell } from '@modulor/react';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';

type ListCellProps = RefAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & {
  text?: string;
  subtitle?: string;
  multiline?: boolean;
  icon?: string | ReactNode;
  content?: ReactNode;
  leftControl?: ReactNode;
  leftContent?: ReactNode;
  rightImage?: ReactNode;
  image?: ReactNode | string;
  leftImageSize?: number;
  withoutLeftImagePadding?: boolean;
  withoutRightImagePadding?: boolean;
  withContentDivider?: boolean;
  dividerHidden?: boolean;
  inverted?: boolean;
  buttons?: ReactNode;
  bottomContainerContent?: string;
  onClick?: MouseEventHandler;
  dataTestId?: string;
  rightContentAlignment?: 'start' | 'center';
  leftContentAlignment?: 'start' | 'center';
};

export const ConditionalContentListCell = ({ text, subtitle, leftContent, content, ...props }: ListCellProps) => {
  const { skeletonT, isSkeleton } = useSkeletonTranslation();

  const listSellText = isSkeleton ? undefined : text;
  const listSellSubtitle = isSkeleton ? undefined : subtitle;
  const conditionalLeftContent = isSkeleton ? skeletonT('SKELETON') : leftContent;
  const conditionalContent = isSkeleton ? undefined : content;

  return (
    <ListCell
      {...props}
      text={listSellText}
      subtitle={listSellSubtitle}
      content={conditionalContent}
      leftContent={conditionalLeftContent}
    />
  );
};
