/* eslint-disable */
// @ts-nocheck
import { useEffect, lazy } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setParamsSnapshot,
  setWidgetFlow,
  setIsLLA,
  setAppChannel,
  checkAndSetIsSessionExpired,
  setInitialTheme,
  themeUISelector,
} from 'store/slices/global';
import { getAppChannelFromUrl, getInitialTheme, getIsLLA } from 'utils';
import { FlowTypes, UIThemeTypes } from 'store/constants';
import ErrorBoundary from 'Modulor/components/ErrorPage/ErrorBoundary';
import { useMetricsRequest } from 'Modulor/hooks';
import { initSentry } from './services/sentry';

import './index.scss';

const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
if (sentryDSN) {
  initSentry(sentryDSN);
}

const ModulorApp = lazy(() => import('Modulor/ModulorApp'));

const App = () => {
  const UITheme = useAppSelector(themeUISelector);
  const dispatch = useAppDispatch();
  useMetricsRequest(dispatch);

  useEffect(() => {
    const { search, href } = window.location;
    const flow = href.includes('deposit') ? FlowTypes.deposit : FlowTypes.withdrawal;
    const initialTheme = getInitialTheme();
    const isLLAPage = getIsLLA(href);
    const appChannelFromUrl = getAppChannelFromUrl();

    dispatch(setWidgetFlow(flow));
    dispatch(setIsLLA(isLLAPage));
    dispatch(setAppChannel(appChannelFromUrl));
    dispatch(setParamsSnapshot(search));
    dispatch(checkAndSetIsSessionExpired({
      isLLAPage,
    }));
    dispatch(setInitialTheme(initialTheme));
  }, []);

  if (UITheme === UIThemeTypes.modulor) {
    return (
      <ErrorBoundary>
        <ModulorApp />
      </ErrorBoundary>
    );
  }

  return null;
};

export default App;
