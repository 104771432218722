import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
} from '@microsoft/signalr';
import { Dispatch } from '@reduxjs/toolkit';
import { API_CASHIER_SERVICE, NOTIFICATIONS_URL } from '../../utils';
import { CustomServerError } from '../../store/services/checkoutAPI';
import { setSessionExpired } from '../../store/slices/global';

interface Subscriptions {
  name: string;
  callback: (data: string, stopFunction: () => void) => void;
  onSuccess?: () => void;
}

class NotificationService {
  private readonly sessionId: string;

  private connection: HubConnection | null;

  private subscriptionNameList: string[];

  private readonly wsConnectionString: string;

  private dispatch: Dispatch;

  constructor(sessionId: string, dispatch: Dispatch) {
    this.sessionId = sessionId;
    this.subscriptionNameList = [];
    this.connection = null;
    this.dispatch = dispatch;
    this.wsConnectionString = process.env.NODE_ENV === 'production'
      ? `${API_CASHIER_SERVICE}${NOTIFICATIONS_URL}`
      : `${process.env.REACT_APP_NOTIFICATIONS_CONNECTION_STRING}`;
  }

  public async start(): Promise<void> {
    const connectionString = `${this.wsConnectionString}?x-session-id=${this.sessionId}`;

    if (this.sessionId
      && (!this.connection
      || [
        'Disconnected',
        'Disconnecting',
      ].includes(this.connection?.state)
      )
    ) {
      await this.connection?.stop();
      this.connection = new HubConnectionBuilder()
        .withUrl(connectionString, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .configureLogging(process.env.NODE_ENV === 'production' ? LogLevel.None : LogLevel.Trace)
        .withAutomaticReconnect()
        .build();

      this.connection.serverTimeoutInMilliseconds = 60000 * 3;

      this.connection.on('connectionId', (connectionId) => {
        // eslint-disable-next-line no-console
        console.log('Connected with connectionId:', connectionId);
      });

      this.connection.onclose((error) => {
        // eslint-disable-next-line no-console
        console.error('Connection closed:', error);
      });

      try {
        await this.connection.start();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);

        if (e && (e as CustomServerError)?.errorCode === 17) {
          this.dispatch(setSessionExpired());
        }

        await this.stop();
        throw e;
      }
    }
  }

  public subscribe({ name, callback, onSuccess }: Subscriptions): void {
    if (this.subscriptionNameList.includes(name)) {
      return;
    }

    this.subscriptionNameList.push(name);

    try {
      this.connection?.on(
        name,
        (data: string) => callback(
          data,
          this.unSubscribe.bind(this, name),
        ),
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw e;
    }
  }

  public async stop(subScribeName?: string): Promise<void> {
    if (!this.connection) {
      return;
    }

    try {
      if (subScribeName && this.subscriptionNameList.includes(subScribeName)) {
        this.unSubscribe(subScribeName);
        this.subscriptionNameList = this.subscriptionNameList.filter((sub) => sub !== subScribeName);
      } else if (this.subscriptionNameList.length) {
        const unsub = this.unSubscribe.bind(this);
        this.subscriptionNameList.forEach(unsub);
        this.subscriptionNameList = [];
      }
    } catch (e) { /* empty */ } finally {
      if (this.connection.state !== HubConnectionState.Disconnected) {
        try {
          await this.connection.stop();
        } catch (e) { /* empty */ }
      }
    }
  }

  private unSubscribe(name: string) {
    if (!name || !this.connection) {
      return;
    }

    this.connection.off(name);
  }

  public getState() {
    return this.connection?.state;
  }
}

export { NotificationService };
