/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-empty-function */
import { MouseEvent, useEffect, useState } from 'react';
import {
  getMethodForAutoRedirect,
  UIPaymentGroups,
} from 'store/formatters';
import {
  depositAPI,
  SELECT_TAG,
  useGetDepositMethodsQuery,
  useLazyGetDepositMethodInfoQuery,
} from 'store/services/depositAPI';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ALT_METHODS, FlowTypes, SELECTED_METHOD_ID } from 'store/constants';
import {
  softLocalStorageRemove,
  softLocalStorageSet,
  parseQuery,
  getMethodGroups,
  getGroupsCount,
} from 'utils';
import {
  Notifications, PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType,
  PayHubDomainSessionsEnumsPlatformType,
} from 'store/models';
import {
  appChannelSelector,
  brandSelector,
  clickstreamIdSelector,
  langSelector,
  needsInitRefetchSelector,
  setCountryCode,
  setHeadersNavIcon,
  setPlatform,
  setUserBalance,
  setUserPhone,
  setLogoSizes,
  setGrowthbookFeatures,
  setAmount,
  userIdSelector,
  setSuccessPageRedirectTimeout,
  setSelectedPayGroupId,
  setReturnUrls,
  setNeedsInitRefetch,
  setIsCustomIframeMode,
  setIsStatusPageMode,
} from 'store/slices/global';
import {
  useBonusesHook,
  useFrameChangeHeight,
  useGetPayGroupId,
  useGetSessionId,
  useNavigateWithSearch,
} from 'Modulor/hooks';
import {
  cleanCurrentMethod,
  currentDepositMethodSelector,
  resetSkippingAfterDeclinePage,
  setCurrentMethod,
  setWidgetSettings,
  setDepositTax,
  skipAutoSelectMethodAfterDeclinePageSelector,
} from 'store/slices/deposit';
import { isOpenedSelector as isWithdrawalRejectOpenedSelector, setWithdrawalRejectInfo } from 'store/slices/withdrawalReject';
import {
  setupClickstream,
  Clickstream,
  getClickStreamEvent,
  useGetClickStreamCashierContext,
  getUnavailableEventData,
  useGetClickStreamPayGroupCashierContext,
  getPayGroupCashierContext,
} from 'services/clickstream';
import { getGBFeatures } from 'services/growthbook';
import { CAROUSEL_EXP } from 'services/growthbook/constants';
import { toggleOTPMode } from 'store/slices/otp';
import { cleanCardState } from 'store/slices/userCard';
import { setDefaultPaySessionState } from 'store/slices/paySessionState';
import {
  isCarouselViewChangedSelector,
  isInitialCarouselLoadSelector,
  setInitialCarouselLoad,
} from 'store/slices/carousel';
import { selectActionMethod, selectDepositMethodHandler } from './utils';
import ModulorLoader from '../components/ModulorLoader';
import { useSetNotificationsHook } from '../components/Notifications/notificationsHooks';
import { DepositMethodGroupList } from './DepositMethodGroupList';

import './DepositMethods.scss';
import { useCarousel } from '../../hooks/useCarousel';
import useSelectInfoMethod from '../../hooks/useSelectInfoMethod';
import { BetShopsBottomsheet } from './BetShops';
import { BonusInfo } from '../components/BonusInfo';
import { bonusDataSelector } from '../../store/slices/bonus';

const gbFeatures = getGBFeatures();

export const DepositMethods = () => {
  const dispatch = useAppDispatch();
  const sessionId = useGetSessionId();
  const [wasLoaded, setWasLoaded] = useState(false);
  const [isExpSetup, setIsExpSetup] = useState(false);
  const [showBottomsheet, setBottomsheetVisibility] = useState(false);
  const { data, isFetching, isLoading, refetch } = useGetDepositMethodsQuery();
  const {
    methods = [],
    initCarouselMethods = [],
    canAutoRedirect,
    widgetSettings,
    viewData,
    withdrawalReverse,
    tax,
    merchantUrl,
    merchantSuccessUrl,
    merchantFailUrl,
    merchantProcessingUrl,
    merchantRedirectType,
    userBonuses,
    userBalance,
    currencyAlpha3,
    growthbookFeatures,
    authToken = '',
  } = data || {};
  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(FlowTypes.deposit);
  const payGroupContext = useGetClickStreamPayGroupCashierContext(FlowTypes.deposit);
  const brand = useAppSelector(brandSelector);
  const needsInitRefetch = useAppSelector(needsInitRefetchSelector);
  const language = useAppSelector(langSelector);
  const clickstreamId = useAppSelector(clickstreamIdSelector);
  const appChannel = useAppSelector(appChannelSelector);
  const userId = useAppSelector(userIdSelector);
  const skipRedirectAfterDeclinePage = useAppSelector(skipAutoSelectMethodAfterDeclinePageSelector);
  const isCarouselViewChanged = useAppSelector(isCarouselViewChangedSelector);
  const isInitialCarouselLoad = useAppSelector(isInitialCarouselLoadSelector);
  const payGroupId = useGetPayGroupId();
  const currentMethod = useAppSelector(currentDepositMethodSelector);
  const bonusData = useAppSelector(bonusDataSelector);
  const isWithdrawalRejectOpened = useAppSelector(isWithdrawalRejectOpenedSelector);

  useEffect(() => {
    if (needsInitRefetch) {
      dispatch(setNeedsInitRefetch(false));
      dispatch(setIsCustomIframeMode(false));
      dispatch(setIsStatusPageMode(false));
      refetch();
      dispatch(depositAPI.util.invalidateTags([{
        type: SELECT_TAG,
      }]));
    }
  }, [needsInitRefetch]);

  const { bonusInfo, isGeneralBonus } = bonusData || {};

  const methodToRedirect = getMethodForAutoRedirect({
    methods,
    initCarouselMethods,
    isInitialCarouselLoad,
    payGroupId,
    skip: skipRedirectAfterDeclinePage || showBottomsheet,
  });

  const {
    platform = PayHubDomainSessionsEnumsPlatformType.Desktop,
    user_phone,
    country_code,
    logo_image_height,
    logo_image_width,
    success_page_redirect_timeout,
    user_ams_segments,
  } = widgetSettings || {};
  const navigate = useNavigateWithSearch();
  const methodIdToRedirect = methodToRedirect?.id || null;
  const methodGroups = getMethodGroups(methods);
  const isNestedListMethod = currentMethod?.payGroupActionType === PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType.NestedList;
  const [triggerDepositMethodInfo, resultDepositMethodInfo] = useLazyGetDepositMethodInfoQuery();
  useSelectInfoMethod(methods, resultDepositMethodInfo, dispatch, platform);

  useCarousel({
    methods,
    initCarouselMethods,
    methodToRedirect,
    methodIdToRedirect,
    isCarouselViewChanged,
    isInitialCarouselLoad,
    flowType: FlowTypes.deposit,
    skip: skipRedirectAfterDeclinePage,
  });

  useEffect(() => {
    dispatch(setUserPhone(user_phone || ''));
    dispatch(setCountryCode(country_code || ''));
    dispatch(setLogoSizes({
      height: logo_image_height || 40,
      width: logo_image_width || 40,
    }));
    dispatch(setWidgetSettings(widgetSettings || {}));
    if (success_page_redirect_timeout) {
      dispatch(setSuccessPageRedirectTimeout(success_page_redirect_timeout));
    }
  }, [
    user_phone,
    country_code,
    logo_image_height,
    logo_image_width,
    success_page_redirect_timeout,
  ]);

  useEffect(() => {
    dispatch(setPlatform(platform));
    dispatch(setWithdrawalRejectInfo(withdrawalReverse));
  }, [
    platform,
    withdrawalReverse,
  ]);

  useEffect(() => {
    if (growthbookFeatures?.length) {
      dispatch(setGrowthbookFeatures(growthbookFeatures));
    }
  }, [growthbookFeatures]);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      dispatch(setReturnUrls({
        merchantUrl,
        merchantSuccessUrl,
        merchantFailUrl,
        merchantProcessingUrl,
        merchantRedirectType,
      }));
    }
  }, [data]);

  useFrameChangeHeight(!!methods.length, {
    skip: showBottomsheet,
    applyPlatformHeights: isWithdrawalRejectOpened,
  });

  useSetNotificationsHook(viewData as Notifications);
  useBonusesHook(userBonuses);

  const sendEntryClickstreamEvent = (availableMethods: UIPaymentGroups[]) => {
    const unavailableEventData = getUnavailableEventData(availableMethods);
    const preselectedPayGroup = availableMethods.find(
      (x) => x.id === methodIdToRedirect,
    )?.displayName || '';
    const clEvent = getClickStreamEvent.cashier_homepage_open(unavailableEventData, preselectedPayGroup);
    clickstream.push(clEvent, [cashierContext]);

    if (unavailableEventData && unavailableEventData.length === availableMethods.length) {
      const event = getClickStreamEvent.cashier_homepage_all_methods_unavailable_view(
        unavailableEventData,
      );
      clickstream.push(event, [cashierContext]);
    }
  };

  const handleSelectDepositMethod = (m: UIPaymentGroups) => (event: MouseEvent) => {
    dispatch(resetSkippingAfterDeclinePage());
    const clEvent = getClickStreamEvent.cashier_homepage_method_tile_click(false);
    clickstream.push(clEvent, [cashierContext,
      getPayGroupCashierContext(m)]);

    if (m.payGroupActionType === PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType.Info) {
      triggerDepositMethodInfo({
        payGroupId: m.id,
        currency: m.currencyAlpha3 || '',
      });
    }
    if (m.payGroupActionType === PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType.NestedList) {
      dispatch(setCurrentMethod(m));
      dispatch(setSelectedPayGroupId(m.id));
      setBottomsheetVisibility(true);
    }

    selectDepositMethodHandler({
      method: m,
      platform: widgetSettings?.platform,
      dispatch,
      navigate,
      flowType: FlowTypes.deposit,
    }, event);
  };

  const sendBackClickstreamEvent = () => {
    const clEvent = getClickStreamEvent.cashier_method_back_icon_click;
    clickstream.push(clEvent, [cashierContext,
      payGroupContext]);
  };

  const setCarouselExpResult = () => {
    const result = gbFeatures.growthBook.evalFeature(CAROUSEL_EXP);
    if (result.value) {
      dispatch(setInitialCarouselLoad(false));
    }
    setIsExpSetup(true);
  };

  const initGBExperiment = (url: string, isCarouselExp: boolean) => {
    gbFeatures.initGBFeatures({
      currencyAlpha3,
      merchantUrl: url,
      userId,
      growthbookFeatures,
      brand,
      userBalance,
      clickstreamId,
      language,
      appChannel,
      platform,
      userAmsSegments: user_ams_segments,
    }).then(() => {
      if (!isCarouselExp) {
        setIsExpSetup(true);
        dispatch(setInitialCarouselLoad(false));
      } else {
        setCarouselExpResult();
      }
    }).catch(() => {
      setIsExpSetup(true);
    });
  };

  const growthbookInitialization = (
    { shouldInitGrowthbook,
      url,
      isReadyForServices }: {
        shouldInitGrowthbook: boolean;
      url: string;
        isReadyForServices: boolean;
      },
  ) => {
    const isCarouselExp = !!growthbookFeatures && growthbookFeatures.includes(CAROUSEL_EXP);
    if (shouldInitGrowthbook) {
      initGBExperiment(url, isCarouselExp);
      return;
    }
    if (isReadyForServices && isCarouselExp) {
      setCarouselExpResult();
      return;
    }
    setIsExpSetup(true);
    dispatch(setInitialCarouselLoad(false));
  };

  useEffect(() => {
    dispatch(setDefaultPaySessionState());
    softLocalStorageRemove(SELECTED_METHOD_ID);
    const params = parseQuery(window.location.search);
    dispatch(setAmount(params.amount ? `${params.amount}` : ''));
    dispatch(cleanCardState());
    softLocalStorageRemove('currentMethod');
    dispatch(cleanCurrentMethod());
    dispatch(toggleOTPMode(false));
    document.addEventListener('СlickstreamBack', sendBackClickstreamEvent);
    dispatch(setHeadersNavIcon('close'));

    return () => {
      document.removeEventListener('СlickstreamBack', sendBackClickstreamEvent);
      dispatch(setHeadersNavIcon(''));
    };
  }, []);

  useEffect(() => {
    softLocalStorageSet(ALT_METHODS, JSON.stringify(methods));
  }, [methods]);

  useEffect(() => {
    dispatch(setUserBalance(userBalance));
  }, [userBalance]);

  useEffect(() => {
    if (isLoading) {
      setWasLoaded(true);
    }
    const canUseMerchantUrl = merchantUrl && merchantUrl.startsWith('http');
    const isReadyForServices = Boolean(!isLoading && merchantUrl);
    const isGbFeaturesExist = Boolean(growthbookFeatures?.length);
    const shouldInitGrowthbook = isReadyForServices
        && isGbFeaturesExist
        && !gbFeatures.isLoading()
        && !gbFeatures.isInitialized()
        && !gbFeatures.isFailed();

    if (!isLoading && canUseMerchantUrl) {
      growthbookInitialization({
        shouldInitGrowthbook,
        url: merchantUrl,
        isReadyForServices,
      });
      try {
        setupClickstream({
          appChannel,
          platform,
          brand,
          language,
          merchantUrl,
          clickstreamId,
          sessionId,
          authToken,
        });
        if (clickstream.getIsSetup()) {
          sendEntryClickstreamEvent(methods);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setDepositTax(tax));
  }, [tax]);

  useEffect(() => {
    if (methodToRedirect && canAutoRedirect && wasLoaded && isExpSetup && !isNestedListMethod) {
      const event = getClickStreamEvent.cashier_homepage_method_tile_click(true);
      clickstream.push(event, [cashierContext,
        getPayGroupCashierContext(methodToRedirect)]);
      selectActionMethod({
        dispatch,
        method: methodToRedirect,
        navigate,
        flowType: FlowTypes.deposit,
      });
    }
  }, [
    methodToRedirect,
    canAutoRedirect,
    wasLoaded,
    isExpSetup,
  ]);

  if (isFetching) {
    return <ModulorLoader />;
  }

  return (
    <>
      {bonusInfo && isGeneralBonus && (
      <BonusInfo {...bonusInfo} />
      )}
      {methodGroups.map(({ name, list }) => (
        <DepositMethodGroupList
          key={name}
          name={name}
          list={list}
          groupCount={getGroupsCount(methodGroups)}
          handleSelectDepositMethod={handleSelectDepositMethod}
        />
      ))}
      <BetShopsBottomsheet
        isOpen={showBottomsheet}
        setOpen={setBottomsheetVisibility}
        currency={currencyAlpha3}
        payGroupId={payGroupId}
      />
    </>
  );
};
