/* eslint-disable camelcase */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BonusesDataState, UserBonusInfo } from '../models';

export const initialState:BonusesDataState = {
  userBonuses: [],
  isBonusPresent: false,
  isGeneralBonus: false,
  userBonusesByPayGroups: null,
};

const bonusSlice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    setBonusesInfo(state, action: PayloadAction<UserBonusInfo[]>) {
      const { payload } = action;
      const { bonusDescription, bonusPayGroupsIds } = (payload?.length && payload[0]) || {};
      state.userBonuses = payload || [];
      // TODO: Should return when bonusName will be delivered
      // const shouldDisplayBonuses = bonusName && bonusDescription;
      const shouldDisplayBonuses = bonusDescription;
      state.isBonusPresent = !!shouldDisplayBonuses;
      state.userBonusesByPayGroups = (bonusPayGroupsIds?.length && bonusPayGroupsIds) || null;
      state.isGeneralBonus = !bonusPayGroupsIds || bonusPayGroupsIds.length === 0;
    },
  },
});

export const bonusDataSelector = createSelector(
  (state: { bonus: BonusesDataState }) => state.bonus,
  (bonus) => (bonus?.isBonusPresent
    ? {
      bonusInfo: bonus.userBonuses[0],
      bonusByPayGroup: bonus.userBonusesByPayGroups,
      isGeneralBonus: bonus.isGeneralBonus,
    }
    : null),
);

export const {
  setBonusesInfo,
} = bonusSlice.actions;

export default bonusSlice.reducer;
