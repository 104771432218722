import React, { useEffect, useState } from 'react';
import { Icon, Typography } from '@modulor/react';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { NAMESPACES } from 'services/constants';
import { RefreshCodeTimerProps } from '../otpTypes';
import './OtpForm.scss';

const ONE_MIN_TIMER = 60;

const RefreshCodeTimer = ({ isShow, isTimerStop, onSetTimerOn }: RefreshCodeTimerProps) => {
  const { skeletonT } = useSkeletonTranslation();
  const [seconds, setSeconds] = useState<number>(ONE_MIN_TIMER);
  const preparedSeconds = seconds.toString().padStart(2, '0');
  const countdown = seconds === ONE_MIN_TIMER ? '1:00' : `0:${preparedSeconds}`;

  useEffect(() => {
    if (seconds > 0 && isShow) {
      const intervalId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
    if (seconds === 0 || isTimerStop) {
      setSeconds(ONE_MIN_TIMER);
      onSetTimerOn(false);
    }

    return undefined;
  }, [seconds,
    isShow]);

  return isShow
    ? (
      <div className="refresh-code-block">
        <Icon
          name="clock_outlined"
          size={16}
          className="refresh-code-block_icon"
        />
        <Typography
          className="get-new-code-text"
          variant="subhead-regular"
          data-translation-key={`${NAMESPACES.PW_KEYS}:PH.BUTTON.RECEIVE_TIME_LIMIT`}
        >
          {skeletonT(`${NAMESPACES.PW_KEYS}:PH.BUTTON.RECEIVE_TIME_LIMIT`, {
            time: countdown,
          })}
        </Typography>
      </div>
    ) : null;
};

export default RefreshCodeTimer;
