import React from 'react';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { Button, Modal, Typography } from '@modulor/react';
import { NAMESPACES } from 'services/constants';
import useFileUpload from './useFileUpload';
import LoadedFileCell from './LoadedFileCell';
import LoadButton from './LoadButton';
import './FileUpload.scss';

interface FileUploadProps {
  isDisabled?: boolean;
  isLoading?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({ isDisabled, isLoading }) => {
  const { translate, skeletonT } = useSkeletonTranslation();
  const { Uploader, uploadedFile, isLoadingUpload, showError,
    setShowError, handleUploadFile, handleResetFile } = useFileUpload();

  const component = uploadedFile
    ? (
      <LoadedFileCell
        uploadedFile={uploadedFile}
        handleResetFile={handleResetFile}
        isDisabled={isDisabled}
      />
    )
    : (
      <LoadButton
        Uploader={Uploader}
        isLoading={isLoadingUpload || !!isLoading}
        handleUploadFile={handleUploadFile}
      />
    );

  return (
    <>
      {showError && (
        <Modal
          openModal={showError}
          title={translate(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.UPLOAD_DOC.UPLOAD_ERROR`)}
          hero
          buttonNumber={1}
          toggleModal={() => setShowError(false)}
          shadowClick
          vertical
          description={translate(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.UPLOAD_DOC.UPLOAD_MAX_SIZE_ERROR`)}
          buttons={(
            <Button
              key="upload-error-modal1"
              variant="primary"
              onClick={() => setShowError(false)}
            >
              {skeletonT(`${NAMESPACES.PW_KEYS}:PH.BUTTON.ACCEPT`)}
            </Button>
        )}
        />
      )}
      <div className="file-upload-container">
        <div className="file-upload-title">
          <Typography variant="body-semibold">
            {skeletonT(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.UPLOAD_DOC.UPLOAD_RECEIPT`)}
          </Typography>
        </div>
        <div className="file-upload-content">
          <Typography variant="caption-1-regular">
            {skeletonT(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.UPLOAD_DOC.UPLOAD_FORMATS`)}
          </Typography>
        </div>
        {component}
      </div>
    </>
  );
};

export default FileUpload;
