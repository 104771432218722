import { currentDepositMethodSelector } from 'store/slices/deposit';
import { useAppSelector } from 'store/hooks';
import { currentWithdrawalMethodSelector } from 'store/slices/withdrawal';
import { FlowTypes } from 'store/constants';
import { getPayGroupCashierContext } from './eventsHelpers';

export const useGetClickStreamPayGroupCashierContext = (type = 'deposit') => {
  const selectorName = type === FlowTypes.withdrawal ? currentWithdrawalMethodSelector : currentDepositMethodSelector;
  const method = useAppSelector(selectorName);
  return getPayGroupCashierContext(method);
};
