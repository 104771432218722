/* eslint-disable @typescript-eslint/no-empty-function */
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Icon, ListCell, ListView, Radio, RadioGroup } from '@modulor/react';
import { ConditionalContentListCell } from 'Modulor/components/ConditionalContentListCell';
import { UIWithdrawalCard } from 'store/formatters/commonPayments';
import {
  extendedTypeForCardParamSelector,
  setSelectedWithdrawalCard,
  verifyNewPayoutAccountSelector,
  withdrawalCardsSelector,
  withdrawalSelectedCardSelector,
} from 'store/slices/withdrawal';
import { FlowTypes } from 'store/constants';
import { PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedType } from 'store/models';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamEvent,
  useGetClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { NAMESPACES } from 'services/constants';
import { useNavigateWithSearch } from 'Modulor/hooks';
import { getCardsIcon, getCardsTypeName } from './utils';

import './Cards.scss';

export const WithdrawalCardList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithSearch();
  const { translate } = useSkeletonTranslation();
  const cards = useAppSelector(withdrawalCardsSelector);
  const { isNew, value } = useAppSelector(withdrawalSelectedCardSelector);
  const extendedType = useAppSelector(extendedTypeForCardParamSelector);
  const isPAMAvaliable = useAppSelector(verifyNewPayoutAccountSelector) && extendedType !== ExtendedType.CreditCard;
  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(FlowTypes.withdrawal);
  const payGroupContext = useGetClickStreamPayGroupCashierContext(FlowTypes.withdrawal);

  const onSelectCard = (card: UIWithdrawalCard): void => {
    const event = card.isNew ? getClickStreamEvent.cashier_payout_new_account_add_button_click
      : getClickStreamEvent.cashier_payout_account_select_field_click;
    clickstream.push(event, [cashierContext,
      payGroupContext]);
    dispatch(setSelectedWithdrawalCard(card));
    return navigate(-1);
  };

  return (
    <ListView className="cards-container-wrapper">
      {cards?.map((card) => (
        <ListCell
          key={card.value}
          className="card-box"
          text={getCardsTypeName(card.label)}
          subtitle={card.label}
          image={getCardsIcon(card.label)}
          onClick={() => onSelectCard(card)}
          content={(
            <RadioGroup onChange={() => {}}>
              <Radio
                className="action__in-list-cell radio-btn"
                value={card.value || ''}
                checked={card.value === value}
              />
            </RadioGroup>
            )}
        />
      ))}
      {isPAMAvaliable && (
        <ConditionalContentListCell
          className="card-box"
          text={translate(`${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.PAY_NEW_CARD`) || ''}
          rightContentAlignment="start"
          image={<Icon name="credit_card_outlined" />}
          onClick={() => onSelectCard({
            isNew: true,
          })}
          content={(
            <RadioGroup onChange={() => {}}>
              <Radio
                className="action__in-list-cell radio-btn"
                value=""
                checked={isNew}
              />
            </RadioGroup>
            )}
        />
      )}
    </ListView>
  );
};
