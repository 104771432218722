import { Control, FieldValues, UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import {
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedType,
  SelectedCardInfo,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ApiParameter,
} from 'store/models';
import { Clickstream, getClickStreamEvent } from 'services/clickstream';
import { CardState } from 'store/slices/userCard';
import { fieldGetter } from '../fieldGetter';
import { TFunction } from '../../commonTypes';

interface RenderFields {
  setError: UseFormSetError<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  fields: Parameter[];
  control: Control;
  t: TFunction;
}

export const renderFields = (props: RenderFields) => {
  const {
    setError,
    setValue,
    clearErrors,
    fields,
    control,
    t,
  } = props;

  return fields.map((field: Parameter) => {
    if (field.name === 'pair' && field.items?.length) {
      const isFullPair = field.items.length > 1;
      return (
        <div
          key={field.name}
          className={isFullPair ? 'pair-row' : ''}
        >
          {renderFields({
            t,
            setError,
            setValue,
            clearErrors,
            fields: field.items,
            control,
          })}
        </div>
      );
    }
    return fieldGetter({
      t,
      setError,
      setValue,
      clearErrors,
      formField: {
        control,
      },
      isCheckout: true,
    })(field);
  });
};

export const getIsCVVFieldRequired = ({
  methodParams,
  isCardTokenIsActive,
  amount,
  maxRecurrentAmount,
}: {
  methodParams: Parameter[];
  isCardTokenIsActive: boolean;
  amount: number;
  maxRecurrentAmount?: number;
}): boolean => {
  const cvvField = methodParams?.find(
    (param) => param?.type === 'input' && param?.extendedType === ExtendedType.CardCvv,
  );

  // BILL-4220
  if (!cvvField) {
    return false;
  }

  if (!isCardTokenIsActive) {
    const selectedCvvField = cvvField || {
      isRequired: true,
    };
    const isAmountMoreThanMax = maxRecurrentAmount && amount > maxRecurrentAmount;

    return Boolean(isAmountMoreThanMax || selectedCvvField?.isRequired);
  }

  return true;
};

export const prepareRequiredFields = (fields: Parameter[]) => {
  const expirationDateField = fields.find((field: Parameter) => field.name === 'pair')?.items?.find((obj) => (
    obj.name === 'expirationDate')) || null;
  return expirationDateField === null ? fields : [
    ...fields,
    expirationDateField];
};

export const isShowPCIDSSLogoByBrand = (brand: string) => !['brand32'].includes(brand);

type CashierContext = { schema: string; data: { session_id: string; session_type: string } };
type PayGroupContext = { schema: string; data: { pay_group: string; max_amount?: number |
        undefined; min_amount?: number | undefined; }; } | { schema: string; data: NonNullable<unknown> };

export const setClickStreamEvents = (
  isCarouselView: boolean,
  clickstream: Clickstream,
  cashierContext: CashierContext,
  payGroupContext: PayGroupContext,
) => {
  if (isCarouselView) {
    const event = getClickStreamEvent.demetr_carousel_submit_click;
    clickstream.push(event, [cashierContext,
      payGroupContext]);
    return;
  }

  const clEvent = getClickStreamEvent.cashier_method_submit_button_click;
  clickstream.push(clEvent, [cashierContext,
    payGroupContext]);
};

export const updateFieldsDataStructure = (fields: FieldValues, cardInfo: SelectedCardInfo) => (
  fields.filter((field: Parameter) => !(
    field.extendedType === ApiParameter.CardPan && cardInfo.selectedCard?.last4)));

export const filterFieldsForCheckout = (fields: Parameter[], cardInfo: CardState): Parameter[] => {
  if (!fields?.length) {
    return [];
  }

  let filterFields: Parameter[];

  const isCVVRequired = fields.find((field: Parameter) => field.extendedType === ExtendedTypes.CardCvv)?.isRequired;

  // This logic described in BILL-15183
  if (cardInfo.selectedCard?.is_card_token_active && isCVVRequired) {
    filterFields = fields.filter((param) => (
      param.extendedType === ExtendedTypes.CardPan || param.extendedType === ExtendedTypes.CardCvv
    ));
  } else if (cardInfo.selectedCard?.is_card_token_active && !isCVVRequired) {
    filterFields = fields.filter((param) => (
      param.extendedType === ExtendedTypes.CardPan
    ));
  } else {
    filterFields = fields.filter((field: Parameter) => field.extendedType !== ExtendedType.CreditCard);
  }

  return filterFields;
};
