import { FlowTypes } from '../store/constants';
import { getAmountErrorKey } from '../Modulor/fieldErrors';

export const isValidRegExp = (regExpString: string): boolean => {
  try {
    RegExp(regExpString);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidJSON = (JSONString: string): boolean => {
  try {
    JSON.parse(JSONString);
    return true;
  } catch (e) {
    return false;
  }
};

interface IsAmountAboveUserBalanceValidator {
  amount: number;
  userBalance?: number;
  flowType: FlowTypes;
  min?: number;
}

export const isAmountAboveUserBalanceValidator = (props: IsAmountAboveUserBalanceValidator) => {
  const {
    amount,
    userBalance,
    flowType,
    min,
  } = props;

  const userBalanceHasError = typeof userBalance !== 'number'
    || userBalance <= 0
    || amount > userBalance
    || (!!min && userBalance < min);

  if (flowType === FlowTypes.withdrawal && userBalanceHasError) {
    return getAmountErrorKey(flowType, 'isAmountAboveUserBalance');
  }
  return true;
};

interface AmountPatternValidatorProps {
  amount: number;
  pattern?: RegExp;
  flowType: FlowTypes;
}

export const amountPatternValidator = (props: AmountPatternValidatorProps) => {
  const {
    amount,
    pattern,
    flowType,
  } = props;

  if (!pattern) {
    return true;
  }

  if (!pattern.test(`${amount}`)) {
    return getAmountErrorKey(flowType, 'pattern');
  }

  return true;
};
