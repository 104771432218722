import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { NAMESPACES } from 'services/constants';
import { FieldError, FieldErrorsImpl, FieldValues, Merge, UseFormSetError } from 'react-hook-form';
import { Typography } from '@modulor/react';

interface PhoneErrorProps {
    error: string | FieldError | Merge<FieldError, FieldErrorsImpl> | undefined;
    name: string;
    setError: UseFormSetError<FieldValues>;
}

const PhoneError = ({ error, name, setError }: PhoneErrorProps) => {
  const { skeletonT } = useSkeletonTranslation();

  return error && typeof error === 'string'
    ? (
      <Typography
        variant="caption-1-regular"
        style={{
          color: 'var(--text-error)',
        }}
      >
        {skeletonT(`${NAMESPACES.PW_KEYS}:PH.ERROR.EMPTY_PHONE_PROFILE`)}
      </Typography>
    ) : null;
};

export default PhoneError;
