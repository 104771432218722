import React from 'react';
import { Typography } from '@modulor/react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SerializedError } from '@reduxjs/toolkit';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { CustomServerError } from 'store/services/depositAPI';
import { getUploadErrorMessage } from '../../Deposit/utils';

interface CheckoutA2AErrorWrapperProps {
    isA2aFlowError: boolean | null | undefined;
    uploadError: FetchBaseQueryError | SerializedError | undefined;
}

const CheckoutA2AError = ({ isA2aFlowError, uploadError }: CheckoutA2AErrorWrapperProps) => {
  const { translate } = useSkeletonTranslation();
  return isA2aFlowError ? (
    <Typography
      variant="caption-1-regular"
      style={{
        color: 'var(--text-error)',
      }}
    >
      {getUploadErrorMessage({
        errorMessage: uploadError && 'errorMessage' in uploadError
          ? (uploadError as CustomServerError).errorMessage
          : undefined,
        t: translate,
      })}
    </Typography>
  ) : null;
};

export default CheckoutA2AError;
