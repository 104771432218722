import React, { FC, useState } from 'react';
import { Button, Content, ListCell, ListView, Toast } from '@modulor/react';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { NAMESPACES } from '../../services/constants';

export interface CopyListCellProps {
    name: string;
    placeHolder: string;
}

const CopyListCell: FC<CopyListCellProps> = ({ name, placeHolder }) => {
  const { translate } = useSkeletonTranslation();
  const [showSuccessCopied, setSuccessShowCopied] = useState(false);
  const [showErrorCopied, setErrorShowCopied] = useState(false);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setSuccessShowCopied(true);
      },
      () => {
        setErrorShowCopied(true);
      },
    );
  };

  return (
    <>
      <Toast
        className="copied-success-message"
        text={translate(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.DOC_LIST_ITEM.ACCOUNT_NAME.COPIED_SUCCESS_MESSAGE`)}
        icon="checkmark"
        visible={showSuccessCopied}
        duration={2000}
        variant="success"
        onHide={() => setSuccessShowCopied(false)}
      />
      <Toast
        className="copied-success-message"
        text={translate(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.DOC_LIST_ITEM.ACCOUNT_NAME.COPY_FAILED_MESSAGE`)}
        icon="close"
        visible={showErrorCopied}
        duration={2000}
        variant="error"
        onHide={() => setErrorShowCopied(false)}
      />
      <ListView>
        <ListCell
          key={name}
          inverted
          subtitle={name}
          text={placeHolder}
          content={(
            <Content>
              <Button
                variant="secondary"
                icon="copy_outlined"
                onClick={() => handleCopy(placeHolder || '')}
              />
            </Content>
            )}
        />
      </ListView>
    </>
  );
};

export default CopyListCell;
