/* eslint-disable camelcase */

export const getClickStreamEvent = {
  cashier_homepage_all_methods_unavailable_view: (unavailablePayGroup: string[]) => ({
    schema: 'iglu:com.psp/cashier_homepage_all_methods_unavailable_view/jsonschema/1-0-0',
    data: {
      unavailable_pay_group: unavailablePayGroup,
    },
  }),
  cashier_homepage_cancel_withdrawal_click: {
    schema: 'iglu:com.psp/cashier_homepage_cancel_withdrawal_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_homepage_cancel_withdrawal_close_click: {
    schema: 'iglu:com.psp/cashier_homepage_cancel_withdrawal_close_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_homepage_close_click: {
    schema: 'iglu:com.psp/cashier_homepage_close_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_homepage_method_tile_click: (isPreselected: boolean) => ({
    schema: 'iglu:com.psp/cashier_homepage_method_tile_click/jsonschema/1-0-0',
    data: {
      is_preselected: isPreselected,
    },
  }),
  cashier_homepage_method_filter_button_click: {
    schema: 'iglu:com.psp/cashier_homepage_method_filter_button_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_homepage_open: (unavailablePayGroup: string[] | null, preselectedPayGroup: string) => ({
    schema: 'iglu:com.psp/cashier_homepage_open/jsonschema/1-0-0',
    data: {
      unavailable_pay_group: unavailablePayGroup,
      preselected_pay_group: preselectedPayGroup,
    },
  }),

  context_cashier: (method: string, sessionId: string) => ({
    schema: 'iglu:com.psp/context_cashier/jsonschema/2-0-0',
    data: {
      session_id: sessionId,
      session_type: method,
    },
  }),
  cashier_homepage_popup_cancel_withdrawal_confirm_button_click: {
    schema: 'iglu:com.psp/cashier_homepage_popup_cancel_withdrawal_confirm_button_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_homepage_popup_cancel_withdrawal_not_confirm_button_click: {
    schema: 'iglu:com.psp/cashier_homepage_popup_cancel_withdrawal_not_confirm_button_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_infoblock_click: (action: string) => ({
    schema: 'iglu:com.psp/cashier_method_infoblock_click/jsonschema/1-0-0',
    data: {
      infoblock_action: action,
    },
  }),
  cashier_method_back_icon_click: {
    schema: 'iglu:com.psp/cashier_method_back_icon_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_change_button_click: {
    schema: 'iglu:com.psp/cashier_method_change_button_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_recommended_click: {
    schema: 'iglu:com.psp/cashier_method_recommended_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_open: {
    schema: 'iglu:com.psp/cashier_method_open/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_predefined_amount_button_click: (index: number, currency: string, amount: number) => ({
    schema: 'iglu:com.psp/cashier_method_predefined_amount_button_click/jsonschema/2-0-0',
    data: {
      amount_button_index: index,
      currency,
      amount,
    },
  }),
  cashier_method_hint_click: {
    schema: 'iglu:com.psp/cashier_method_hint_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_continue_button_click: {
    schema: 'iglu:com.psp/cashier_method_continue_button_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_save_card_checkbox_click: (value: boolean) => ({
    schema: 'iglu:com.psp/cashier_method_save_card_checkbox_click/jsonschema/1-0-0',
    data: {
      card_saved: value,
    },
  }),
  cashier_method_submit_button_click: {
    schema: 'iglu:com.psp/cashier_method_submit_button_click/jsonschema/1-0-0',
    data: {},
  },
  // eslint-disable-next-line max-len
  cashier_result_page_view: (status: string, declineReason: string | null = null, declineCode: string | null = null) => ({
    schema: 'iglu:com.psp/cashier_result_page_view/jsonschema/1-0-0',
    data: {
      order_result_status: status,
      decline_code: declineCode,
      decline_reason: declineReason,
    },
  }),
  cashier_result_page_intogame_button_click: (destination: string) => ({
    schema: 'iglu:com.psp/cashier_result_page_intogame_button_click/jsonschema/1-0-0',
    data: {
      order_result_status: 'success',
      destination_page: destination,
    },
  }),
  // eslint-disable-next-line max-len
  cashier_result_page_retry_button_click: (declineReason: string, declineCode: string | null = null) => ({
    schema: 'iglu:com.psp/cashier_result_page_retry_button_click/jsonschema/1-0-0',
    data: {
      order_result_status: 'decline',
      decline_reason: declineReason,
      decline_code: declineCode,
    },
  }),
  cashier_result_page_change_method_tile_click: {
    schema: 'iglu:com.psp/cashier_result_page_change_method_tile_click/jsonschema/1-0-0',
    data: {
      order_result_status: 'decline',
    },
  },
  cashier_result_page_all_methods_button_click: {
    schema: 'iglu:com.psp/cashier_result_page_all_methods_button_click/jsonschema/1-0-0',
    data: {
      order_result_status: 'decline',
    },
  },
  // eslint-disable-next-line max-len
  cashier_result_page_close_icon_click: (status: string, declineReason: string | null = null, declineCode: string | null = null) => ({
    schema: 'iglu:com.psp/cashier_result_page_close_icon_click/jsonschema/1-0-0',
    data: {
      order_result_status: status,
      decline_reason: declineReason,
      decline_code: declineCode,
    },
  }),
  cashier_method_bonus_banner_view: {
    schema: 'iglu:com.psp/cashier_method_bonus_banner_view/jsonschema/1-0-0',
    data: {},
  },
  cashier_homepage_no_methods_view: {
    schema: 'iglu:com.psp/cashier_homepage_no_methods_view/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_bonus_banner_click: {
    schema: 'iglu:com.psp/cashier_method_bonus_banner_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_payout_entry_page_view: {
    schema: 'iglu:com.psp/cashier_payout_entry_page_view/jsonschema/1-0-0',
    data: {},
  },
  cashier_payout_account_select_field_click: {
    schema: 'iglu:com.psp/cashier_payout_account_select_field_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_payout_new_account_add_button_click: {
    schema: 'iglu:com.psp/cashier_payout_new_account_add_button_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_payout_account_delete_icon_click: {
    schema: 'iglu:com.psp/cashier_payout_account_delete_icon_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_payout_account_delete_confirm_popup_button_click: (isDelete: boolean) => ({
    schema: 'iglu:com.psp/cashier_payout_account_delete_confirm_popup_button_click/jsonschema/1-0-0',
    data: {
      delete_confirmed: isDelete,
    },
  }),
  cashier_payout_continue_button_click: {
    schema: 'iglu:com.psp/cashier_payout_continue_button_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_payout_verification_page_open: {
    schema: 'iglu:com.psp/cashier_payout_verification_page_open/jsonschema/1-0-0',
    data: {},
  },
  cashier_payout_change_confirmation_contact_option_click: (contact: string) => ({
    schema: 'iglu:com.psp/cashier_payout_change_confirmation_contact_option_click/jsonschema/1-0-0',
    data: {
      contact,
    },
  }),
  cashier_payout_contact_support_option_click: {
    schema: 'iglu:com.psp/cashier_payout_contact_support_option_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_payout_otp_request_button_click: (attempt: number) => ({
    schema: 'iglu:com.psp/cashier_payout_otp_request_button_click/jsonschema/1-0-0',
    data: {
      attempt,
    },
  }),
  cashier_payout_otp_confirm_button_click: (attempt: number) => ({
    schema: 'iglu:com.psp/cashier_payout_otp_confirm_button_click/jsonschema/1-0-0',
    data: {
      attempt,
    },
  }),
  cashier_payout_account_verification_result: {
    schema: 'iglu:com.psp/cashier_payout_account_verification_result/jsonschema/1-0-0',
    data: {
      verification_result_status_success: true,
    },
  },
  cashier_payout_verification_via_support_message_view: {
    schema: 'iglu:com.psp/cashier_payout_verification_via_support_message_view/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_redirect_link_click: {
    schema: 'iglu:com.psp/cashier_method_redirect_link_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_method_redirect_page_open: {
    schema: 'iglu:com.psp/cashier_method_redirect_page_open/jsonschema/1-0-0',
    data: {},
  },
  cashier_payout_back_button_click: {
    schema: 'iglu:com.psp/cashier_payout_back_button_click/jsonschema/1-0-0',
    data: {},
  },
  cashier_result_page_intogame_redirect: (status: string, destination: string) => ({
    schema: 'iglu:com.psp/cashier_result_page_intogame_redirect/jsonschema/1-0-0',
    data: {
      order_result_status: status,
      destination_page: destination,
    },
  }),
  demetr_carousel_view: {
    schema: 'iglu:com.psp/demetr_carousel_view/jsonschema/1-0-0',
    data: {},
  },
  demetr_carousel_method_click: (method: string) => ({
    schema: 'iglu:com.psp/demetr_carousel_method_click/jsonschema/1-0-0',
    data: {
      method,
    },
  }),
  demetr_carousel_all_methods_click: {
    schema: 'iglu:com.psp/demetr_carousel_all_methods_click/jsonschema/1-0-0',
    data: {},
  },
  demetr_carousel_submit_click: {
    schema: 'iglu:com.psp/demetr_carousel_submit_click/jsonschema/1-0-0',
    data: {},
  },
};
