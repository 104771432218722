/* eslint-disable camelcase */
import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { getCurrentQueryParams } from 'utils';
import { FlowTypes } from 'store/constants';
import {
  PayHubCashierContractsCashier3DSResultResponse,
  PayHubWidgetContractsSubmitPaymentResponse,
  PayHubWidgetContractsSubmitPayoutResponse,
} from 'store/models';
import ModulorLoader from 'Modulor/components/ModulorLoader';

interface StatusNavigateProps {
  paymentType: FlowTypes;
  redirectForm: PayHubWidgetContractsSubmitPaymentResponse |
    PayHubWidgetContractsSubmitPayoutResponse |
    PayHubCashierContractsCashier3DSResultResponse;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const StatusRedirect: FC<StatusNavigateProps> = (props) => {
  const {
    paymentType,
    redirectForm: {
      action = '',
      status = '',
      result_status,
    },
  } = props;

  const queryParams = getCurrentQueryParams() || '/';
  const { provider } = useParams();

  if (!action || action === 'redirect') {
    return null;
  }

  const urlSuccess = `/${paymentType}/${paymentType}-success${queryParams}`;
  const urlSuccessCharity = `/${paymentType}/${paymentType}-success-charity${queryParams}`;
  const urlFail = `/${paymentType}/${paymentType}-error${queryParams}`;
  const urlProcessing = `/${paymentType}/${paymentType}-processing${queryParams}`;
  const urlAwait = `/${paymentType}/${paymentType}-await${queryParams}`;

  const isCharityMethod = provider === 'mobimoney_charity';

  if ((action as string) === 'success' || status === 'Pending') {
    return <Navigate to={urlSuccess} />;
  }

  if (!action || status === 'Failed') {
    return <Navigate to={urlFail} />;
  }

  if (action === 'result' && result_status === 'success' && isCharityMethod) {
    return <Navigate to={urlSuccessCharity} />;
  }

  if (action === 'result' && result_status === 'success') {
    return <Navigate to={urlSuccess} />;
  }

  if (action === 'result' && result_status === 'fail') {
    return <Navigate to={urlFail} />;
  }

  if (action === 'result' && result_status === 'processing') {
    return <Navigate to={urlProcessing} />;
  }

  if (action === 'await') {
    return <Navigate to={urlAwait} />;
  }

  // eslint-disable-next-line no-console
  console.warn(props);

  return <ModulorLoader />;
};
