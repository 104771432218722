import { FC, useState, useEffect, useRef } from 'react';
import clx from 'classnames';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType } from 'store/models/APIModels';
import { PasswordField, Tooltip, Icon, Button } from '@modulor/react';
import { getPattern, displayTooltip, FieldType, getTranslationMessage } from 'utils';
import { useOnClickOutside } from 'Modulor/hooks';
import { NAMESPACES } from 'services/constants';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { getErrorKey } from '../fieldErrors';
import { allowOnlyNumericValue, getAdditionalRules } from './utils';

import './CvvField.scss';

type Placement = 'top' | 'right' | 'bottom' | 'left';
interface CvvFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  regexp?: string;
  isRequired?: boolean;
  extendedType?: PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType;
  placeHolder?: string;
  validationMsgKey?: string;
  tooltipPlacement?: Placement;
}

export const CvvField:FC<CvvFieldProps> = (props) => {
  const {
    name,
    label,
    placeHolder = '',
    control,
    isRequired,
    regexp,
    extendedType,
    validationMsgKey,
    tooltipPlacement,
  } = props;

  const hintRef = useRef<HTMLDivElement | null>(null);
  const [isOpenedHint, showHint] = useState(false);
  const { translate, skeletonT } = useSkeletonTranslation();
  const pattern = getPattern(regexp, extendedType);

  const labelText = getTranslationMessage({
    t: translate,
    value: label || name,
  });
  const placeholderText = getTranslationMessage({
    t: translate,
    value: placeHolder,
  });

  useEffect(() => {
    displayTooltip(false);
  }, []);

  const additionalParams = getAdditionalRules({
    extendedType,
  });

  const toggleHint = () => {
    if (isOpenedHint) {
      showHint(false);
      displayTooltip(false);
      return;
    }
    displayTooltip(true);
    showHint(true);
  };

  useOnClickOutside(hintRef, () => showHint(false));

  return (
    <div className="cvv-field">
      <Controller
        name={name}
        control={control}
        rules={{
          required: isRequired,
          pattern,
          ...additionalParams,
        }}
        render={({ field, fieldState }: FieldValues) => {
          const errorMessageKey = getErrorKey(FieldType.cardCvv, fieldState, validationMsgKey);
          const errorMessage = getTranslationMessage({
            t: translate,
            value: errorMessageKey,
          });

          return (
            <PasswordField
              {...field}
              onChange={(value: string) => field.onChange(allowOnlyNumericValue(value))}
              name={name}
              placeholder={placeholderText}
              error={errorMessage}
              label={labelText}
              inputMode="numeric"
              maxLength={3}
            />
          );
        }}
      />
      <div
        className="icon-box"
        ref={hintRef}
      >
        <div className={clx('tooltip', {
          'is-opened': isOpenedHint,
        })}
        >
          <Tooltip
            show={isOpenedHint}
            placement={tooltipPlacement}
            heading={translate(`${NAMESPACES.PW_KEYS}:PH.TOOLTIP.CVV.TITLE`, {}, true)}
            text={translate(`${NAMESPACES.PW_KEYS}:PH.TOOLTIP.CVV.BODY`, {}, true) || ''}
            button={(
              <Button
                variant="primary"
                className="info-tooltip-button"
                onClick={toggleHint}
              >
                {skeletonT(`${NAMESPACES.PW_KEYS}:PH.BUTTON.ACCEPT`)}
              </Button>
          )}
          >
            {[<Icon
              key="info-icon"
              className="info-icon"
              name="info_circle_outlined"
              onClick={toggleHint}
            />]}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
