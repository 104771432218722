import { UIPaymentGroups, UIPayoutGroups } from 'store/formatters';
import { Limits } from '../../store/models';

export const getUnavailableEventData = (availableMethods: UIPayoutGroups[] | UIPaymentGroups[]) => {
  const unavailableMethods: string[] = [];

  availableMethods.forEach((x) => {
    if (!x.enabled) unavailableMethods.push(x.displayName);
  });

  if (unavailableMethods.length) return unavailableMethods;
  return null;
};

export const getPayGroupCashierContext = (
  method: UIPaymentGroups | UIPayoutGroups | undefined,
  limits?: Limits | null,
) => {
  if (method) {
    const { min, max } = limits || method.limits || {};
    const data = {
      ...(min && {
        min_amount: min,
      }),
      ...(max && {
        max_amount: max,
      }),
      pay_group: method.displayName,
    };
    return {
      schema: 'iglu:com.psp/context_pay_group_cashier/jsonschema/2-0-0',
      data,
    };
  }

  return {
    schema: 'iglu:com.psp/context_pay_group_cashier/jsonschema/2-0-0',
    data: {},
  };
};
