/* eslint-disable camelcase */
import qs from 'query-string';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import { setReqHeaders } from 'utils/common';
import { WIDGET_API } from 'utils/constants';

export interface GenerateOTPResponse {
  status: 'success' | 'fail';
  error?: string;
  error_code?: string;
  errorCode?: string;
  error_message?: string;
  errorMessage?: string;
  can_resend?: boolean;
  canResend?: boolean;
}

export interface GenerateOTPRequest {
  paygroup_id?: number | null;
  amount: number;
  currency: string;
  parameters: {
    [p: string]: string;
  };
}

export const otpAPI = createApi({
  reducerPath: 'otpAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: WIDGET_API,
    prepareHeaders(headers, { getState }) {
      const { session_id = '' } = qs.parse(window?.location?.search || '');
      const { sessionId } = (getState() as RootState).common;
      setReqHeaders(headers, sessionId || `${session_id}`);
    },
  }),
  endpoints(builder) {
    return {
      generateOTP: builder.mutation<GenerateOTPResponse, GenerateOTPRequest>({
        query: (body) => ({
          url: `payments/groups/${body.paygroup_id}/otp`,
          method: 'POST',
          body: {
            ...body,
            paygroup_id: undefined,
          },
        }),
        transformResponse: (
          response: GenerateOTPResponse,
        ): GenerateOTPResponse => ({
          status: response.status,
          error: response.error,
          canResend: response.can_resend,
          errorCode: response?.error_code,
          errorMessage: response?.error_message,
        }),
      }),
    };
  },
});

export const {
  useGenerateOTPMutation,
} = otpAPI;
export default otpAPI.reducer;
