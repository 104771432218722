import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  PayHubCashierContractsWithdrawalReverseResponse,
  WithdrawalRejectRequestResponse,
  WithdrawalRejectState,
} from '../models';

export const initialState: WithdrawalRejectState = {
  isFetching: false,
  isOpened: false,
};

const withdrawalRejectSlice = createSlice({
  name: 'withdrawalReject',
  initialState,
  reducers: {
    setWithdrawalRejectInfo(state, action: PayloadAction<PayHubCashierContractsWithdrawalReverseResponse | undefined>) {
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        ...action.payload,
      };
    },
    setWithdrawalRejectRequestResponse(state, action: PayloadAction<WithdrawalRejectRequestResponse | undefined>) {
      return {
        ...state,
        response: action.payload,
      };
    },
    startWithdrawalRejectRequestFetching(state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    stopWithdrawalRejectRequestFetching(state) {
      return {
        ...state,
        isFetching: false,
      };
    },
    setIsOpened(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isOpened: action.payload,
      };
    },
  },
});

export const withdrawalAmountSelector = (state: { withdrawalReject: WithdrawalRejectState }) => state
  .withdrawalReject.amount;

export const withdrawalCurrencySelector = (state: { withdrawalReject: WithdrawalRejectState }) => state
  .withdrawalReject.currency;

export const withdrawalIsFetchingSelector = (state: { withdrawalReject: WithdrawalRejectState }) => state
  .withdrawalReject.isFetching;

export const withdrawalRejectInfoSelector = createSelector(
  withdrawalAmountSelector,
  withdrawalCurrencySelector,
  withdrawalIsFetchingSelector,
  (amount, currency, isFetching) => ({
    amount,
    currency,
    isFetching,
  }),
);

export const isOpenedSelector = (
  { withdrawalReject }: { withdrawalReject: WithdrawalRejectState },
) => withdrawalReject.isOpened;

export const isWithdrawalRejectRequestFetchingSelector = (
  { withdrawalReject }: { withdrawalReject: WithdrawalRejectState },
) => withdrawalReject.isFetching;

export const withdrawalRejectRequestResponseSelector = (
  { withdrawalReject }: { withdrawalReject: WithdrawalRejectState },
) => withdrawalReject.response;

export const {
  setWithdrawalRejectInfo,
  setWithdrawalRejectRequestResponse,
  startWithdrawalRejectRequestFetching,
  stopWithdrawalRejectRequestFetching,
  setIsOpened,
} = withdrawalRejectSlice.actions;

export default withdrawalRejectSlice.reducer;
