import React from 'react';
import { UserBonusInfo } from 'store/models';
import { BonusInfo } from '../../components/BonusInfo';

interface BonusInfoWrapperProps {
  bonusInfo: UserBonusInfo | undefined;
  isBonusIncluded: boolean;
}

const BonusInfoWrapper = ({ bonusInfo, isBonusIncluded }: BonusInfoWrapperProps) => (bonusInfo && isBonusIncluded ? (
  <BonusInfo {...bonusInfo} />
) : null);

export default BonusInfoWrapper;
