/* eslint-disable @typescript-eslint/no-unsafe-assignment, camelcase */
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import clx from 'classnames';

import { FlatStringObject } from 'commonTypes';
import { getLayoutType, getMerchantUrl, inIframe, LayoutType } from 'utils';
import IntegrationCommunication from 'services/integration-communication';
import { FlowTypes, REDIRECT_FLOW_TYPES, SUBMIT_FORM_ACTIONS, SUBMIT_REDIRECT_ERROR_TIME } from 'store/constants';
import { PayHubDomainSessionsEnumsPlatformType } from 'store/models';
import { APP_NAME, pmAction } from 'utils/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  isInPopUpSelector,
  paymentFlowSelector,
  platformSelector,
  setIsCustomIframeMode,
} from 'store/slices/global';
import { setCarouselViewOff } from 'store/slices/carousel';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamEvent,
  useGetClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { useFrameChangeHeight, useGetSessionId, useNavigateWithSearch } from 'Modulor/hooks';
import { NotificationService } from 'services/notificationService/NotificatioinService';
import { paySessionStateTargetName, submitRedirectFailureTargetName } from 'services/notificationService/constants';
import { paySessionStateSelector } from 'store/slices/paySessionState';
// eslint-disable-next-line max-len
import { paySessionStateSubmitFormHandlerCreator } from 'services/notificationService/handlers/paySessionStateSubmitFormHandler';
import { resetSubmitRedirectError, submitRedirectErrorSelector } from 'store/slices/submitForm';
import { methodShortNameSelector } from 'store/slices/deposit';
import { Timeout } from 'react-number-format/types/types';
// eslint-disable-next-line max-len
import { submitErrorRedirectFormHandlerCreator } from 'services/notificationService/handlers/submitRedirectFailerHandler';
import {
  closeCashierAfterOpenInNewTab,
  formatParamsForForm,
  getFormTarget,
  isSafariAndChromeMobile,
} from './utils';
import './SubmitForm.scss';
import { FormContent } from './FormContent';
import { SubmitFormProps } from './types';

const TRANSACTION_STATUS_MAP: FlatStringObject = {
  processing: 'processing',
  success: 'success',
  fail: 'error',
  processing_error: 'error',
  order_cancelled: 'error',
  awaiting: 'await',
  await: 'await',
};

let errorTimer: Timeout;

export const SubmitForm: FC<SubmitFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const clickstream = Clickstream.use();
  const flowType = useAppSelector(paymentFlowSelector);
  const isRedirectFailed = useAppSelector(submitRedirectErrorSelector);
  const shortMethodeName = useAppSelector(methodShortNameSelector);
  const navigate = useNavigateWithSearch();
  const cashierContext = useGetClickStreamCashierContext(flowType);
  const payGroupContext = useGetClickStreamPayGroupCashierContext(flowType);
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormHandled, setIsFormHandled] = useState(false);
  const [isRedirectErrorCheck, setIsRedirectErrorCheck] = useState(false);
  const [notificationService, setNotificationService] = useState<NotificationService | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSocketConnectionFailed, setIsSocketConnectionFailed] = useState(false);
  const {
    redirectForm,
    paymentType,
    isAwait,
    isLLA = false,
  } = props;
  const {
    action,
    request_method: requestMethod,
    redirect_type,
    iframe_height,
    is_frame_height_equals_platform_height,
  } = redirectForm;

  const isRedirectTypeParent = redirect_type && redirect_type === 'parent';
  const isRedirectTypeBlank = redirect_type && redirect_type === 'blank';
  const layoutType = getLayoutType();
  const isMobileLayout = layoutType === LayoutType.mobile;
  const platform = useAppSelector(platformSelector);
  const isOpenedInPopup = useAppSelector(isInPopUpSelector);
  const isNativeDevice = [
    PayHubDomainSessionsEnumsPlatformType.NativeIos,
    PayHubDomainSessionsEnumsPlatformType.NativeAndroid,
  ].includes(platform);

  const paySessionState = useAppSelector(paySessionStateSelector);
  const {
    transaction_status = null,
    result_status = null,
    merchant_url: merchantUrl,
  } = paySessionState || {};

  const { requestUrl, requestFields } = formatParamsForForm(redirectForm);
  const isActionExpected = [
    SUBMIT_FORM_ACTIONS.redirect as string,
    SUBMIT_FORM_ACTIONS.blank as string,
  ].includes(action as string);
  const requestSessionTime = 30000;
  const isDeposit = window.location.pathname.includes(FlowTypes.deposit);
  const formTarget = getFormTarget({
    action,
    isActionExpected,
    redirectType: redirect_type,
    isLLA,
    isRedirectFailed: isRedirectErrorCheck && isRedirectTypeBlank ? isRedirectFailed : false,
  });
  const merchant_url = getMerchantUrl(merchantUrl) || '/';

  const isInWeb = inIframe();
  const isInsideCustomIframe = isInWeb && formTarget === '_self' && !isRedirectTypeParent;
  const sessionId: string = useGetSessionId();
  const notificationServiceCallback = paySessionStateSubmitFormHandlerCreator({
    dispatch,
  });
  const submitErrorRedirectCallback = submitErrorRedirectFormHandlerCreator({
    dispatch,
  });
  const submitFormContent = document.querySelector('#submit-content');

  const onStartConnection = useCallback(async () => {
    const service = new NotificationService(sessionId, dispatch);
    service.start().then(() => {
      setNotificationService(service);
      service.subscribe({
        name: paySessionStateTargetName,
        callback: notificationServiceCallback,
      });
      service.subscribe({
        name: submitRedirectFailureTargetName,
        callback: submitErrorRedirectCallback,
        onSuccess: () => {
          setIsSubscribed(true);
        },
      });
    }).catch(() => {
      setIsSocketConnectionFailed(true);
    });
  }, []);
  const onStopConnection = useCallback(async (name: string) => {
    if (!notificationService) return;
    await notificationService.stop(name);
  }, []);

  useFrameChangeHeight(isFormHandled, {
    skip: isInsideCustomIframe,
  });

  useEffect(() => {
    if (isInsideCustomIframe) {
      dispatch(setIsCustomIframeMode(true));
    }
  }, [isInsideCustomIframe]);

  useEffect(() => {
    if (isRedirectErrorCheck && isRedirectFailed && formRef.current && isRedirectTypeBlank) {
      formRef.current.target = REDIRECT_FLOW_TYPES.top;

      setTimeout(() => {
        try {
          formRef?.current?.submit();
        } catch {
          formRef?.current?.dispatchEvent(new Event('submit'));
        }
      }, 0);
    }
  }, [
    isRedirectErrorCheck,
    isRedirectFailed,
  ]);

  useEffect(() => {
    dispatch(setCarouselViewOff());
    if (!isInsideCustomIframe && !isLLA) {
      onStartConnection().then();
    }

    const timer = setTimeout(async () => {
      await onStopConnection(paySessionStateTargetName);
    }, requestSessionTime);

    return () => {
      dispatch(resetSubmitRedirectError());
      clearTimeout(timer);
      clearTimeout(errorTimer);
      onStopConnection(paySessionStateTargetName).then();
    };
  }, []);

  const handleSafariFlow = () => {
    // TODO: check closeCashierAfterOpenInNewTab(redirect_type);
    if (isOpenedInPopup) {
      IntegrationCommunication.sendMessage({
        id: 'closeCashier',
      });
    } else {
      IntegrationCommunication.sendMessage({
        id: 'redirectTo',
        value: '/',
      });
    }
  };

  useEffect(() => {
    if (isFormHandled && isSubscribed) {
      errorTimer = setTimeout(() => {
        if (notificationService?.getState() === HubConnectionState.Connected && !isSocketConnectionFailed) {
          setIsRedirectErrorCheck(true);
        }
      }, SUBMIT_REDIRECT_ERROR_TIME);
    }

    return () => {
      clearTimeout(errorTimer);
      onStopConnection(submitRedirectFailureTargetName).then();
    };
  }, [isFormHandled,
    isSubscribed]);

  useEffect(() => {
    if (isRedirectErrorCheck) {
      closeCashierAfterOpenInNewTab(isOpenedInPopup, redirect_type);
    }
  }, [isRedirectErrorCheck]);

  useEffect(() => {
    if (isSafariAndChromeMobile() && isFormHandled && submitFormContent && redirect_type !== 'blank') {
      handleSafariFlow();
    }
  }, [isFormHandled,
    submitFormContent,
    isSafariAndChromeMobile,
    redirect_type]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isFormHandled) {
        return;
      }

      if (!formRef.current) {
        return;
      }

      const clickstreamEvent = getClickStreamEvent.cashier_method_redirect_page_open;
      clickstream.push(clickstreamEvent, [cashierContext,
        payGroupContext]);

      try {
        formRef.current.submit();
      } catch {
        formRef.current.dispatchEvent(new Event('submit'));
      }
      setIsFormHandled(true);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    isFormHandled,
    paymentType,
    formTarget,
  ]);

  useEffect(() => {
    const workStatus = transaction_status || result_status;
    const providerIframe = document.querySelector('#iframe-host');

    if (workStatus && isDeposit && !providerIframe) {
      const mappedStatus = TRANSACTION_STATUS_MAP[`${workStatus}`];

      if (mappedStatus) {
        navigate(`/${paymentType}/${paymentType}-${mappedStatus}`);
      }
    }
  }, [
    transaction_status,
    result_status,
    paymentType,
  ]);

  if (!isActionExpected) {
    return null;
  }

  const handleSubmitForm = () => {
    const CHANGE_BUTTON_TIMEOUT = 3000;

    if (!isFormHandled) {
      if (isAwait) {
        setIsFormHandled(true);
      } else {
        const timer = setTimeout(() => {
          setIsFormHandled(true);
          clearTimeout(timer);
        }, CHANGE_BUTTON_TIMEOUT);
      }
    }
  };

  const handleGoBackClick = () => {
    if (isOpenedInPopup) {
      IntegrationCommunication.sendMessage({
        id: 'closeCashier',
      });
      return;
    }

    if (isInWeb) {
      IntegrationCommunication.sendMessage({
        id: 'redirectTo',
        value: '/',
      });
      IntegrationCommunication.sendMessage({
        id: 'successClick',
        target: APP_NAME,
        value: pmAction.deposit,
      });
    } else {
      if (isNativeDevice) {
        window.location.assign(merchant_url);
        return;
      }

      window.location.href = window.location.origin;
    }
  };

  const handleRedirectClick = () => {
    const clickstreamEvent = getClickStreamEvent.cashier_method_redirect_link_click;
    clickstream.push(clickstreamEvent, [cashierContext,
      payGroupContext]);
  };

  return (
    <div
      id="iframe-host"
      className={clx({
        mobile: isMobileLayout,
      })}
    >
      <FormContent
        isInsideCustomIframe={isInsideCustomIframe}
        isDeposit={isDeposit}
        isFormHandled={isFormHandled}
        isLLA={isLLA}
        shortMethodeName={shortMethodeName}
        handleSubmitForm={handleSubmitForm}
        handleRedirectClick={handleRedirectClick}
        handleGoBackClick={handleGoBackClick}
        requestMethod={requestMethod}
        requestUrl={requestUrl}
        formTarget={formTarget}
        requestFields={requestFields}
        flowType={flowType}
        ref={formRef}
        iFrameHeight={iframe_height}
        isFrameHeightEqualsPlatformHeight={is_frame_height_equals_platform_height}
        isNativeDevice={isNativeDevice}
      />
    </div>

  );
};
