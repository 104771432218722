import { FlatStringObject } from 'commonTypes';

/* eslint-disable max-len */
export enum appConstants {
  phPrevSessionIdData = 'phPrevSessionIdData',
  phSkipLasUsedMethod = 'phSkipLasUsedMethod',
  transaction_status = 'transaction_status',
  language_key = 'airPmCashierLang',
}

export enum submitFormActions {
  blank = 'blank',
  redirect = 'redirect',
  result = 'result',
}

export enum pmAction {
  // WARNING! These paths are used in `index.html`
  // (gtm) so if you are about to update it, please update the html as well
  deposit = 'deposit',
  withdrawal = 'withdrawal',
  // /end of warning
  error = 'error',
  content = 'content',
  checkout = 'checkout',
  page3dsResult = '3ds_result',
  tokenizationPage = 'tokenization_page',
  accManagement = 'acc_management',
  LLA = '/payments/alt/interaction',
}

// NOTICE: maybe will be changed in the future to Widget
export const APP_NAME = 'cashier';

export const NATIVE_PAYTYPE_MAP = {
  GooglePay: 'Gpay',
  ApplePay: 'ApplePay',
};

export enum PAGE_STATUS {
  success = 'success',
  error = 'error',
  processing = 'processing',
  await ='await',
  'charity-success' = 'charity-success',
}

export const CLICKSTREAM_STATUSES_MAP = {
  error: 'decline',
  success: 'success',
  processing: 'processing',
  await: 'processing',
  'charity-success': 'success',
};

export const API_CASHIER_SERVICE = '/api/cashier-service';

export const NOTIFICATIONS_URL = '/notifications';

export const NATIVE_PAY_TYPES = {
  GOOGLE: 'GooglePay',
  APPLE: 'ApplePay',
};

export const LOGO_URL_INITIAL = '/cashier-logo.url/';
export const LOGO_URL_PATTERN = 'http://cashier-logo.url';
export const LOGO_URL_BASE = 'cashier-logo.url';

export const PH_AMOUNT_PLACEHOLDER_KEY = 'PH_AMOUNT_PLACEHOLDER';
export const CASHIER_CONTENT_PAGE_URL = '/content/';
export const CASHIER_CONTENT_PAGE_URL_INITIAL = '/cashier-content-page.info/';
export const CONTENT_PAGE_URL_PATTERN = 'http://cashier-content-page.info';

export enum LINK_TARGET {
  SELF = '_self',
  BLANK = '_blank',
}

export const DESKTOP_WIDTH = 1024;
export const PLATFORM_MIN_HEIGHT = 650;

export enum LayoutType {
  mobile = 'mobile',
  desktop = 'desktop',
}

export const CLOSE_ICON = 'alwaysCloseIcon';

export const LOCAL_THEME = 'local_theme';

export enum FieldType {
  'amount',
  'checkbox',
  'select',
  'input',
  'expirationDate',
  'cardPan',
  'cardCvv',
  'card',
  'userPhoneVisible',
  'betShop',
  'phone',
  'date',
  'file_upload',
  'disabledInputCopy',
}

export const DEFAULT_THEME_NAME = 'brand0';

const WIDGET_SERVICE_ENV = process.env.NODE_ENV === 'production' ? '/api/cashier-service/' : '/cash-ph20-service/';
export const WIDGET_API = `${WIDGET_SERVICE_ENV}v1.0`;

export const X_CHANNEL = 'xChannel';

export const LOCIZE_NAMESPACES = [
  'demeter_common',
  'common',
];

export const HEADER_ICON: FlatStringObject = {
  close: 'close',
  back: 'back_ios',
};

export const DESKTOP_MODAL_MIN_HEIGHT = 675;
export const DESKTOP_MODAL_WIDTH = 656;

export const WIDGET_HEIGHT_GAP = 50;
export const DEFAULT_WIDGET_APP_HEIGHT = 350;

export const LOCAL_LOCIZE_NAMESPACES = [
  'widget_common',
  'common',
];
