import React from 'react';
import { CardState } from 'store/slices/userCard';
import { PayHubWidgetContractsUserCardResponse } from 'store/models';
import { SavedCard } from '../../components/Cards/SavedCard';

interface SavedCardWrapperProps {
    isShow: boolean;
    cardInfo: CardState;
    cardsList: PayHubWidgetContractsUserCardResponse[];
}

const SavedCardWrapper = ({ isShow, cardInfo, cardsList }: SavedCardWrapperProps) => (isShow ? (
  <SavedCard
    cardsState={cardInfo}
    cardsList={cardsList}
  />
) : null);

export default SavedCardWrapper;
