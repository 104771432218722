import React from 'react';
import { Icon, Toast } from '@modulor/react';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { NAMESPACES } from 'services/constants';
import { SendCodeFormErrorProps } from '../otpTypes';

const GeneralErrorToast = ({ error, onShow }: SendCodeFormErrorProps) => {
  const { translate } = useSkeletonTranslation();

  return error
    ? (
      <Toast
        variant="error"
        visible={error}
        text={translate(`${NAMESPACES.PW_KEYS}:PH2.ERROR.UNEXPECTED`)}
        multiline
        rightIcon={(
          <Icon
            name="close"
            onClick={() => onShow(false)}
          />
            )}
        onHide={() => onShow(false)}
        duration={5000}
      />
    ) : null;
};

export default GeneralErrorToast;
