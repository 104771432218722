/* eslint-disable camelcase */
import { Dispatch } from '@reduxjs/toolkit';
import { resetSubmitRedirectError } from 'store/slices/submitForm';
import { isValidJSON } from '../../../utils/validator';

interface CreatorProps {
  dispatch: Dispatch;
}

export const submitErrorRedirectFormHandlerCreator = (
  creatorProps: CreatorProps,
) => async (
  json: string,
  unsubscribe: () => void,
) => {
  const {
    dispatch,
  } = creatorProps;
  const data = isValidJSON(json) ? JSON.parse(json) : {};

  const {
    session_id,
    is_succeed,
  } = data;

  if (session_id && is_succeed) {
    dispatch(resetSubmitRedirectError());
    unsubscribe();
  }
};
