/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import IntegrationCommunication from 'services/integration-communication';
import { useLazySubmitDepositQuery } from 'store/services/depositAPI';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  PaymentProcessMethodInfo,
  Limits,
  PayHubCashierContractsViewDataResponse,
  MerchantUserFeeResponce, ProcessingCurrency,
  PayHubCashierContractsPaymentsSelectPaymentGroupResponse, PayHubWidgetContractsUserCardResponse, Parameter,
} from 'store/models';
import {
  getErrorMessage, getTranslationMessage,
  prepareParametersForSubmit,
} from 'utils';
import { FieldValues } from 'react-hook-form';
import { setSessionExpired } from 'store/slices/global';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  useGetClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { CustomServerError, useLazyGetUserCardsQuery, useLazyRecurringCheckQuery } from 'store/services/checkoutAPI';
import { selectedCardSelector, setCards, setSelectedCard } from 'store/slices/userCard';
import { SubmitFormWrapper } from 'Modulor/components/SubmitForm/SubmitFormWrapper';
import { FlowTypes } from 'store/constants';
import ModulorLoader from 'Modulor/components/ModulorLoader';
import { methodNameSelector } from 'store/slices/deposit';
import { isCarouselViewSelector } from 'store/slices/carousel';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { CheckoutForm } from './CheckoutForm';
import { useAbortSubmitControllerRef, useFrameChangeHeight } from '../hooks';
import { getIsCVVFieldRequired, setClickStreamEvents } from './utils';
import { SubmitHandler } from '../SubmitStrategy/SubmitHandler';
import { PaymentProcessStrategy } from '../SubmitStrategy/PaymentProcessStrategy';
import SubmitError from './views/SubmitError';

import './Checkout.scss';

interface CheckoutControllerProps {
  amount: number;
  setIsCheckoutFormSubmitted?: (flag: boolean) => void;
  withAmount?: boolean;
  amountRegExpFe?: string;
  limits?: Limits | null;
  currency: string;
  isAutoSubmit?: boolean;
  isAutoSubmitSelectType?: boolean;
  presetCurrency?: string | null;
  payGroupId: number;
  methodInfo: PayHubCashierContractsPaymentsSelectPaymentGroupResponse;
  isMethodLoadSuccess: boolean;
  additionalViewData?: PayHubCashierContractsViewDataResponse[] | null;
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string | null;
  presetAmount?: number | null;
  allowPresetAmount?: boolean | null;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  formConfig: Parameter[];
}

export const CheckoutController: FC<CheckoutControllerProps> = ({
  amount,
  setIsCheckoutFormSubmitted = () => {},
  withAmount = false,
  amountRegExpFe,
  limits = null,
  currency,
  presetCurrency,
  isAutoSubmit,
  isAutoSubmitSelectType,
  payGroupId,
  methodInfo = {},
  isMethodLoadSuccess,
  additionalViewData,
  merchantUserFee,
  presetAmount,
  allowPresetAmount,
  fee,
  processingCurrency,
  processingCurrencyRate,
  formConfig,
}) => {
  const dispatch = useAppDispatch();
  const { translate } = useSkeletonTranslation();
  const methodName = useAppSelector(methodNameSelector);
  const { selectedCard, cardsList } = useAppSelector(selectedCardSelector);
  const isCarouselView = useAppSelector(isCarouselViewSelector);
  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(FlowTypes.deposit);
  const payGroupContext = useGetClickStreamPayGroupCashierContext(FlowTypes.deposit);
  const [submitGeneralError, setSubmitGeneralError] = useState<string>('');
  const submitHandler = new SubmitHandler(new PaymentProcessStrategy());
  const {
    requiredFields: methodParameters,
    amountBlocks,
  } = methodInfo as PaymentProcessMethodInfo || {};
  const { maxRecurrentAmount = 0 } = limits || {};
  const [getCardListTrigger, cardListResult] = useLazyGetUserCardsQuery();
  const [recurringCheckTrigger, resultRecurringCheck] = useLazyRecurringCheckQuery();
  const [trigger, result] = useLazySubmitDepositQuery();
  const abortControllerRef = useAbortSubmitControllerRef();
  const lastUsedCard = useMemo(() => cardListResult.data?.cards?.find((
    item: PayHubWidgetContractsUserCardResponse,
  ) => item.is_last_used), [cardListResult.data?.cards]);
  const localCurrentIsCVVPresent = useMemo(() => getIsCVVFieldRequired({
    methodParams: methodParameters,
    isCardTokenIsActive: !!selectedCard?.is_card_token_active,
    amount,
    maxRecurrentAmount,
  }), [methodParameters,
    selectedCard?.is_card_token_active,
    amount,
    maxRecurrentAmount]);

  const {
    error,
    data,
    isFetching: isFetchingSubmitRequest,
  } = result;

  useFrameChangeHeight(isMethodLoadSuccess && !cardListResult.isLoading, {
    skip: !submitGeneralError && !!data,
  });

  const getFieldsForRequest = useCallback((fields: FieldValues, isNew: boolean) => prepareParametersForSubmit(fields, {
    isCvvPresent: localCurrentIsCVVPresent || resultRecurringCheck.data?.is_cvv_required,
    isCardTokenActive: isNew && !!selectedCard?.is_card_token_active,
    isCheckout: true,
  }, isNew), [localCurrentIsCVVPresent,
    resultRecurringCheck,
    selectedCard]);

  const submitCallback = useCallback((fields: FieldValues, isNew: boolean) => {
    setClickStreamEvents(isCarouselView, clickstream, cashierContext, payGroupContext);

    const fieldsForRequest = getFieldsForRequest(fields, isNew);
    const amountFieldValue = fieldsForRequest?.amount || amount;

    IntegrationCommunication.sendMessage({
      id: `${FlowTypes.deposit}Submit`,
      value: {
        amount: amountFieldValue,
        currency,
      },
    });
    abortControllerRef.current = new AbortController();

    submitHandler.handle({
      payGroupId,
      fields: fieldsForRequest,
      currency,
      amount: amountFieldValue,
      trigger,
      signal: abortControllerRef.current.signal,
    });
  }, [
    isCarouselView,
    clickstream,
    cashierContext,
    payGroupContext,
    amount,
    currency,
    submitHandler,
    payGroupId,
    trigger,
  ]);

  useEffect(() => {
    if (cardListResult.data?.cards?.length && cardListResult.isSuccess && !cardListResult.isFetching) {
      dispatch(setCards(cardListResult.data.cards));
      if (lastUsedCard?.id && !selectedCard?.id && !selectedCard.isNew) {
        dispatch(setSelectedCard(lastUsedCard));
      }
    }
  }, [
    cardListResult.isFetching,
    lastUsedCard,
  ]);

  useEffect(() => {
    if (error && (error as CustomServerError)?.errorCode === 17) {
      dispatch(setSessionExpired());
      return;
    }

    if (error) {
      const errorObj = getErrorMessage(error);
      const { message = '' } = errorObj;
      const messageText = getTranslationMessage({
        t: translate,
        value: message,
      });

      setSubmitGeneralError(messageText);
    }
  }, [error]);

  useEffect(() => {
    if (!submitGeneralError && data) {
      setIsCheckoutFormSubmitted(true);
    }
  }, [
    submitGeneralError,
    data,
  ]);

  useEffect(() => {
    getCardListTrigger();
  }, []);

  if (!submitGeneralError && data) {
    return (
      <SubmitFormWrapper
        paymentType={FlowTypes.deposit}
        redirectForm={data}
      />
    );
  }

  if (cardListResult.isFetching) {
    return <ModulorLoader />;
  }

  return (
    <div className="checkout-form-wrapper">
      <CheckoutForm
        amount={amount}
        isAutoSubmit={isAutoSubmit}
        isAutoSubmitSelectType={isAutoSubmitSelectType}
        methodParameters={methodParameters}
        submitCallback={submitCallback}
        cardsList={cardsList}
        amountBlocks={amountBlocks}
        currency={currency}
        withAmount={withAmount}
        methodName={methodName}
        amountRegExpFe={amountRegExpFe}
        limits={limits}
        isFetchingSubmitRequest={isFetchingSubmitRequest}
        presetCurrency={presetCurrency}
        additionalViewData={additionalViewData}
        merchantUserFee={merchantUserFee}
        fee={fee}
        presetAmount={presetAmount}
        allowPresetAmount={allowPresetAmount}
        recurringCheckTrigger={recurringCheckTrigger}
        shouldVerifyCVV={resultRecurringCheck.data?.is_cvv_required}
        processingCurrency={processingCurrency}
        processingCurrencyRate={processingCurrencyRate}
        payGroupId={payGroupId}
        formConfig={formConfig}
      />
      <SubmitError
        error={submitGeneralError}
        setError={setSubmitGeneralError}
      />
    </div>
  );
};
