/* eslint-disable @typescript-eslint/no-empty-function */
import { Dispatch, FC, FormEvent, SetStateAction, useMemo, useState } from 'react';
import { Content, Icon, Image, ListCell, ListView, Radio, RadioGroup, TextField, Typography } from '@modulor/react';
import { CountryCode, countryDialCode } from 'assets/countryDialCode';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { NAMESPACES } from 'services/constants';
import { useFrameChangeHeight } from '../../hooks';

import './CountrySelector.scss';

interface CountrySelectorProps {
  setCountryCode: (value: CountryCode) => void;
  countryCode: string;
  toggleModal: Dispatch<SetStateAction<boolean>>;
}

const MIN_OPTIONS_COUNT_FOR_SEARCH_FIELD = 15;

export const CountrySelector: FC<CountrySelectorProps> = ({ setCountryCode, countryCode, toggleModal }) => {
  const { translate, skeletonT } = useSkeletonTranslation();
  const [searchCountry, setSearchCountry] = useState('');

  const displaySearchField = countryDialCode.length >= MIN_OPTIONS_COUNT_FOR_SEARCH_FIELD;

  const handleSearch = (value: string | FormEvent<HTMLDivElement>) => {
    if (typeof value === 'string') {
      setSearchCountry(value);
    }
  };

  const filteredCountryDialCode = useMemo(() => countryDialCode
    .filter((country) => country.nicename.toLowerCase()
      .includes(searchCountry.toLowerCase()) || country.phonecode
      .includes(searchCountry) || `+${country.phonecode}`
      .includes(searchCountry)), [searchCountry]);

  useFrameChangeHeight(!!filteredCountryDialCode.length);

  return (
    <>
      {displaySearchField && (
        <div className="field-wrapper">
          <TextField
            placeholder={translate(`${NAMESPACES.PW_KEYS}:PH.LISTCELL.SEARCH.TITLE`)}
            type="text"
            value={searchCountry}
            onChange={handleSearch}
          />
        </div>
      )}
      {!filteredCountryDialCode.length && (
        <div className="country-selector-list empty-list-block">
          <Icon
            name="search"
            size={40}
            className="empty-list-block__icon"
          />
          <div className="empty-list-block__text">
            <Typography
              variant="title-2-semibold"
            >
              {skeletonT(`${NAMESPACES.PW_KEYS}:PH.LISTCELL.EMPTY.TITLE`)}
            </Typography>
          </div>
        </div>
      )}
      <ListView
        className="country-selector-list"
      >
        <RadioGroup onChange={() => {
        }}
        >
          {filteredCountryDialCode.map((country) => (
            <ListCell
              text={`+${country.phonecode} ${country.nicename}`}
              key={country.id}
              icon={(
                <Image
                  name={country.iso}
                  alt={country.nicename}
                />
                      )}
              onClick={() => {
                setCountryCode(country);
                toggleModal(false);
              }}
              content={(
                <Content>
                  <Radio
                    value={country.iso}
                    checked={country.iso === countryCode}
                  />
                </Content>
              )}
            />
          ))}
        </RadioGroup>
      </ListView>
    </>
  );
};
