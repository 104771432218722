import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OTPSlice {
  isOTPEnabled: boolean;
  code: string;
  fieldName: string;
}

const initialState: OTPSlice = {
  isOTPEnabled: false,
  code: '',
  fieldName: '',
};

export const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    toggleOTPMode: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOTPEnabled: action.payload,
    }),
    setOTPCode: (state, action: PayloadAction<string>) => ({
      ...state,
      code: action.payload,
    }),
    setOTPCodeFieldName: (state, action: PayloadAction<string>) => ({
      ...state,
      fieldName: action.payload,
    }),
  },
});

export const isOTPEnabledSelector = (state: { otp: OTPSlice }) => state
  .otp.isOTPEnabled;
export const OTPFilledCodeSelector = (state: { otp: OTPSlice }) => state
  .otp.code;
export const getOTPFCodeFieldNameSelector = (state: { otp: OTPSlice }) => state
  .otp.fieldName;

export const {
  toggleOTPMode,
  setOTPCode,
  setOTPCodeFieldName,
} = otpSlice.actions;
export default otpSlice.reducer;
