import React from 'react';
import { Icon, Toast } from '@modulor/react';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { ErrorProps } from '../otpTypes';

const AmountErrorToast = ({ error, onSetError }: ErrorProps) => {
  const { translate } = useSkeletonTranslation();

  return error
    ? (
      <Toast
        variant="error"
        visible={Boolean(error)}
        text={translate(typeof error === 'string' && error ? error : '')}
        multiline
        rightIcon={(
          <Icon
            name="close"
            onClick={() => onSetError && onSetError('')}
          />
            )}
        onHide={() => onSetError && onSetError('')}
        duration={2500}
      />
    ) : null;
};

export default AmountErrorToast;
