import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ListView, ListCell, Icon, Typography } from '@modulor/react';
import { PayHubWidgetContractsUserCardResponse } from 'store/models';
import { CardState } from 'store/slices/userCard';
import { Routes } from 'Modulor/router';
import { NAMESPACES } from 'services/constants';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { getCardsPlaceholder, getCardsIcon, getCardsTypeName } from './utils';
import { EmptyCardOption } from './EmptyCardOption';

import './Cards.scss';

interface SavedCardProps {
  cardsList: PayHubWidgetContractsUserCardResponse[];
  cardsState: CardState;
}

export const SavedCard: FC<SavedCardProps> = ({
  cardsList,
  cardsState,
}) => {
  const { skeletonT } = useSkeletonTranslation();

  if (!cardsList.length) {
    return null;
  }

  const { selectedCard } = cardsState;

  return (
    selectedCard?.id && !selectedCard.isNew ? (
      <div className="cards-container-wrapper">
        <div className="saved-card-label">
          <Typography variant="subhead-regular">
            {skeletonT(`${NAMESPACES.INFO}:PH.LABEL.SAVED_CARD`)}
          </Typography>
        </div>
        <Link
          className="link-box"
          to={Routes.cards}
        >
          <ListView className="card-box">
            <ListCell
              text={getCardsTypeName(selectedCard.bin)}
              subtitle={getCardsPlaceholder(selectedCard.last4, selectedCard.bin)}
              image={getCardsIcon(selectedCard.bin)}
              content={(
                <div className="checkbox__in-list-cell">
                  <Icon name="chevron_right" />
                </div>
              )}
            />
          </ListView>
        </Link>
      </div>
    ) : <EmptyCardOption selectedCard={selectedCard} />
  );
};
