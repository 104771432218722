import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectedPayGroupIdSelector } from 'store/slices/global';
import { useGenerateOTPMutation } from 'store/services/otpAPI';
import { currentDepositMethodCurrencySelector } from 'store/slices/deposit';
import GeneralErrorToast from './GeneralErrorToast';
import RefreshCodeTimer from './RefreshCodeTimer';
import FillAndResendCode from './FillAndResendCode';
import AmountErrorToast from './AmountErrorToast';
import { OtpFormProps, SendCodeProps } from '../otpTypes';
import { checkAmountValidation, mapOtpFieldsToFormData } from '../utils';
import './OtpForm.scss';

const OtpForm = ({ isDisabled, otpFields, getValues, errors, watch }:OtpFormProps) => {
  const currency = useAppSelector(currentDepositMethodCurrencySelector);
  const payGroupId = useAppSelector(selectedPayGroupIdSelector);
  const [generateOTP, { isSuccess, data, error, isLoading, reset }] = useGenerateOTPMutation();
  const [amountValueError, setAmountValueError] = useState<string>('');
  const [generalError, setGeneralError] = useState<boolean>(false);
  const [isCodeSent, setCodeSentStatus] = useState<boolean>(false);
  const [payload, setPayload] = useState<SendCodeProps>();
  const [showTimer, setTimerOn] = useState<boolean>(false);
  const isCanResendFalse = useMemo(() => isSuccess && !data?.canResend, [isSuccess,
    data]);
  const isDisabledResend = useMemo(
    () => isCanResendFalse || isDisabled || showTimer || Object.keys(errors).length !== 0,
    [isCanResendFalse,
      isDisabled,
      showTimer,
      errors],
  );
  const { amount } = getValues();
  const formData = watch();
  const mappedOtpFields = useMemo(() => mapOtpFieldsToFormData({
    otpFields, formData,
  }), [otpFields,
    formData]);
  const isCodeSendError = useMemo(() => data?.status?.toLowerCase() === 'fail'
      || data?.error || data?.error || data?.errorCode
      || data?.errorMessage || error, [data,
    error]);

  const handleSendCode = useCallback(async () => {
    setCodeSentStatus(false);
    checkAmountValidation({
      amount, setAmountValueError,
    });

    const preparedPayload = {
      paygroup_id: payGroupId,
      amount: parseFloat(amount),
      currency,
      parameters: mappedOtpFields,
    };
    setPayload(preparedPayload);

    await generateOTP(preparedPayload);
  }, [isCanResendFalse,
    payGroupId,
    amount,
    currency,
    mappedOtpFields]);

  useEffect(() => {
    if (isSuccess && !isCodeSendError) {
      setTimerOn(!!data?.canResend);
      setCodeSentStatus(true);
      setGeneralError(false);
    } else if (isCodeSendError || error) {
      setGeneralError(true);
    }
  }, [data,
    isCodeSendError]);

  useEffect(() => () => {
    setAmountValueError('');
    setGeneralError(false);
    setCodeSentStatus(false);
    setTimerOn(false);
    setPayload(undefined);
    reset();
  }, [payGroupId]);

  return (
    <>
      <FillAndResendCode
        formData={formData}
        previousPayload={payload as SendCodeProps}
        isShowResend={isCodeSent}
        isDisabledResend={isDisabledResend}
        isCanResendFalse={isCanResendFalse}
        isDisabled={isDisabled || Object.keys(errors).length !== 0}
        onSendCode={handleSendCode}
        onSetTimerOn={setTimerOn}
        isLoading={isLoading}
      />
      <RefreshCodeTimer
        isShow={showTimer}
        isTimerStop={isDisabled || !showTimer}
        onSetTimerOn={setTimerOn}
      />
      <GeneralErrorToast
        error={generalError}
        onShow={setGeneralError}
      />
      <AmountErrorToast
        error={amountValueError}
        onSetError={setAmountValueError}
      />
    </>
  );
};

export default OtpForm;
