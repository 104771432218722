import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { Control, FieldValues, useController, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { Icon, NavigationBar, PhoneField, Typography } from '@modulor/react';
import { FieldType, findCountryDetailsByCountryISO, getPattern } from 'utils';
import CustomModal from 'Modulor/CustomModal';
import { NAMESPACES } from 'services/constants';
import { CountryCode } from 'assets/countryDialCode';
import { useAppSelector } from 'store/hooks';
import { countryCodeSelector } from 'store/slices/global';
import { defaultErrorsMap, getErrorKey } from '../../fieldErrors';
import { CountrySelector } from './CountrySelector';
import {
  getBestMatchingCountryDetails,
  getLongestMask,
  getMask,
  getMaskedValue,
  getRawValue,
  isValidPhoneFormat,
  cleanInputValue,
} from './utils';
import { PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType } from '../../../store/models';

interface CustomPhoneFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  regexp?: string;
  extendedType?: PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType;
  isRequired?: boolean;
  defaultValue?: string | null;
  isDisabled?: boolean;
  validationMsgKey?: string;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
}

export const CustomPhoneField: FC<CustomPhoneFieldProps> = (props) => {
  const {
    name,
    label,
    control,
    regexp,
    extendedType,
    isRequired,
    isDisabled = false,
    defaultValue,
    validationMsgKey,
    setValue,
    setError,
  } = props;
  const { translate, skeletonT } = useSkeletonTranslation();

  const [countryCodeSelectorVisibility, setCountryCodeSelectorVisibility] = useState<boolean>(false);
  const currentCountryISO = useAppSelector(countryCodeSelector);
  const pattern = getPattern(regexp, extendedType);

  const DEFAULT_COUNTRY_CODE_UN = 'UN';
  const [phone, setPhone] = useState('+');
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE_UN);

  const {
    field,
    fieldState,
  } = useController({
    name,
    control,
    defaultValue: defaultValue || '',
    rules: {
      required: isRequired,
      pattern,
    },
  });

  const errorMessageKey = getErrorKey(FieldType.input, fieldState, validationMsgKey);
  const errorMessage = translate(
    Object.values(defaultErrorsMap).includes(errorMessageKey)
      ? errorMessageKey
      : `${NAMESPACES.PW_KEYS}:${errorMessageKey}`,
  );
  const maskedFieldValue = getMaskedValue(
    field.value,
    getMask(field.value, getBestMatchingCountryDetails(field.value)),
  );
  const isLocalStateDataEmpty = phone !== maskedFieldValue && !!maskedFieldValue?.length;

  useEffect(() => {
    const isValidDefaultPhoneNumber = defaultValue && isValidPhoneFormat(defaultValue);
    if (isValidDefaultPhoneNumber && !maskedFieldValue?.length) {
      const countryDetails = getBestMatchingCountryDetails(defaultValue);
      const defaultMask = getMask(defaultValue, countryDetails);
      const rawValue = getRawValue(defaultValue);
      const maskLength = defaultMask ? getRawValue(defaultMask).length : 0;
      const maskedPhone = getMaskedValue(defaultValue, defaultMask);
      const formattedPhone = defaultMask && rawValue.length > maskLength
        ? `${maskedPhone}${rawValue.slice(maskLength)}`
        : maskedPhone;
      setPhone(formattedPhone);
      setCountryCode(countryDetails?.iso || '');
    }
    if (!isValidDefaultPhoneNumber && currentCountryISO && !maskedFieldValue?.length) {
      const countryDetails = findCountryDetailsByCountryISO(currentCountryISO);
      setPhone(countryDetails?.phonecode ? `+${countryDetails.phonecode}` : '');
      setCountryCode(countryDetails?.iso || '');
    }
  }, [
    defaultValue,
    currentCountryISO,
    maskedFieldValue,
  ]);

  useEffect(() => {
    if (isLocalStateDataEmpty) {
      setPhone(maskedFieldValue);
      setCountryCode(getBestMatchingCountryDetails(field.value)?.iso || DEFAULT_COUNTRY_CODE_UN);
    }
  }, [isLocalStateDataEmpty]);

  const handleCountryCodeChange = (country: CountryCode) => {
    const newCodeValue = `+${country.phonecode}`;
    const isValid = pattern?.test(newCodeValue);

    if (!isValid) {
      setError(name, {
        type: 'manual',
      });
    }
    const defaultMask = getMask(newCodeValue, country);
    const maskedPhone = getMaskedValue(newCodeValue, defaultMask);

    setPhone(maskedPhone);
    setCountryCode(country.iso);
    setValue(name, newCodeValue);
  };

  const renderModalContent = () => (
    <CountrySelector
      setCountryCode={handleCountryCodeChange}
      countryCode={countryCode}
      toggleModal={setCountryCodeSelectorVisibility}
    />
  );

  const placeholderMask = useMemo(() => {
    const countryDetails = getBestMatchingCountryDetails(phone);
    const mask = getMask(phone, countryDetails);
    return getMaskedValue(phone, mask, true);
  }, [phone]);

  const fieldLabel = label || name ? translate(`${NAMESPACES.INFO}:${label || name}`) : '';

  const onChangeValue = useCallback(
    (inputValue: string, formField: FieldValues, forceChange = false) => {
      const phoneValue = cleanInputValue(inputValue);
      if (!phoneValue) return;
      // find country
      const country = getBestMatchingCountryDetails(phoneValue);
      // find longest mask for input limit
      const longestMask = getLongestMask(country);
      const prevPhone = `+${getRawValue(phone)}`;
      const isFirstCountryDigitsChanged = prevPhone.slice(1, 3) !== phoneValue.slice(1, 3);

      // do not change if value more than longest mask (limit by mask)
      if (
        longestMask
            && getRawValue(phoneValue).length > getRawValue(longestMask).length
            && !phoneValue.includes('+')
      ) {
        return;
      }

      // +380 in formField and user paste "+4111111" -> +4111111 (a change to b)
      // +380 in formField and user paste "1111" -> +c (a + b)
      if ([...phoneValue].filter((a) => a === '+').length >= 2) {
        const values = phoneValue.split('+');
        const lastValue = values[values.length - 1];
        onChangeValue(lastValue, formField, true);
        return;
      }

      // find current mask
      const mask = getMask(phoneValue, country);
      const valueWithoutPlus = phoneValue.replace('+', '');

      if (!mask && !/^(\d)*$/gim.test(valueWithoutPlus)) {
        return;
      }

      const maskedValue = getMaskedValue(phoneValue, mask);
      const rawValue = `+${getRawValue(phoneValue)}`;
      formField.onChange(rawValue);
      setPhone(maskedValue);

      // do not change country (fixed country)
      if (country && countryCode
          && country?.iso !== countryCode
          && countryCode !== DEFAULT_COUNTRY_CODE_UN
          && !forceChange
          && !isFirstCountryDigitsChanged) {
        return;
      }

      // update country
      if (country) {
        if (country.iso !== countryCode) {
          setCountryCode(country.iso);
        }
      } else if (countryCode !== DEFAULT_COUNTRY_CODE_UN) {
        setCountryCode(DEFAULT_COUNTRY_CODE_UN);
      }
    },
    [countryCode,
      getMask],
  );

  return (
    <>
      <PhoneField
        {...field}
        name={name}
        value={phone}
        error={errorMessage}
        label={fieldLabel}
        placeholderMask={placeholderMask}
        disabled={isDisabled}
        readOnly={isDisabled}
        onFlagClick={() => setCountryCodeSelectorVisibility(true)}
        countryCode={countryCode}
        onChange={(eventOrValue) => {
          const phoneValue = typeof eventOrValue === 'string'
            ? eventOrValue
            : (eventOrValue.target as HTMLInputElement).value;
          onChangeValue(phoneValue, field);
        }}
      />
      <CustomModal
        isPopupView
        onShadowClick={() => setCountryCodeSelectorVisibility(false)}
        header={(
          <NavigationBar
            transparent
            left={(
              <Typography variant="title-2-semibold">
                {skeletonT(`${NAMESPACES.PW_KEYS}:PH2.TITLE.SELECT_COUNTRY`)}
              </Typography>
                  )}
            right={(
              <Icon
                name="close"
                onClick={() => setCountryCodeSelectorVisibility(false)}
              />
                  )}
          />
                )}
        openModal={countryCodeSelectorVisibility}
      >
        {[renderModalContent()]}
      </CustomModal>
    </>
  );
};
