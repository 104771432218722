import { CountryCode, countryDialCode } from 'assets/countryDialCode';

export const isValidPhoneFormat = (value?: string | null) => value && value.startsWith('+');

export const getRawValue = (value: string) => (value ? value.replace(/\W/g, '') : '');

export const getMaskedValue = (value: string, mask?: string, placeholder = false) => {
  if (!mask) {
    return value;
  }

  const rawValue = getRawValue(value.toString());
  const maskTemplate = mask.replace(/\d/g, 'X');
  const splitMask = maskTemplate.split('');
  const splitValue = rawValue.split('');
  const resultLength = splitMask.length;

  let maskIndex = 0;
  let valueIndex = 0;

  while (maskIndex < resultLength && valueIndex < splitValue.length) {
    if (splitMask[maskIndex] === 'X') {
      if (splitValue[valueIndex].match(/\d/)) {
        splitMask[maskIndex] = splitValue[valueIndex];
        valueIndex += 1;
      } else if (placeholder) {
        splitMask[maskIndex] = '_';
      }
    }
    maskIndex += 1;
  }

  if (placeholder && valueIndex < splitValue.length) {
    splitMask.push(...splitValue.slice(valueIndex).map((digit) => (digit.match(/\d/) ? digit : '_')));
  }

  return placeholder
    ? splitMask.join('')
    : splitMask.join('').substring(0, maskIndex);
};

export const getBestMatchingCountryDetails = (value: string) => {
  const rawValue = getRawValue(value);

  const matchCountries = countryDialCode.filter(({ phonecode }) => rawValue.startsWith(getRawValue(phonecode)));

  const [country] = [...matchCountries].sort(
    ({ phonecode: a }, { phonecode: b }) => (getRawValue(a).length < getRawValue(b).length ? 1 : -1),
  );

  return country;
};

export const getMask = (value: string, country: CountryCode) => {
  if (!value) {
    return '';
  }

  if (!country) {
    return '';
  }

  if (country.telephoneMasks.length === 1) {
    return country.telephoneMasks[0];
  }

  const rawValue = getRawValue(value);

  return country.telephoneMasks.find(
    (mask) => rawValue.length <= getRawValue(mask).length,
  );
};

export const cleanInputValue = (phoneValue: string) => {
  const value = phoneValue.startsWith('+') ? phoneValue : `+${phoneValue}`;
  if (/[a-zA-Z]/gim.test(value) || /_/gim.test(value)) return null;
  return value;
};

export const getLongestMask = (country: CountryCode) => (
  country
    ? country.telephoneMasks.reduce((longest, current) => (current.length > longest.length ? current : longest), '')
    : null);
