import { FC, useEffect, useState } from 'react';
import { Chip, Chips } from '@modulor/react';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamEvent,
  useGetClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { paymentFlowSelector, setAmount } from 'store/slices/global';
import { getFormattedAmountWithCurrency, getPrettierFormatNumber } from 'utils/dataTransform';
import { currencyDisplaySelector as depositCurrencyDisplaySelector } from 'store/slices/deposit';
import { currencyDisplaySelector as withdrawalCurrencyDisplaySelector } from 'store/slices/withdrawal';
import { FlowTypes } from 'store/constants';
import { getInitialSelectedAmountIndex } from './utils';

interface AmountBlocksProps {
  currentAmount: number;
  amountBlocks: number[] | null;
  onClick: (value: number) => void;
  currency: string;
  isAutoSubmit?: boolean;
  methodName: string;
  isInitialLoadFinished?: boolean;
  setInitialLoadFinished?: (val: boolean) => void;
  min?: number;
}

export const AmountBlocks: FC<AmountBlocksProps> = (props) => {
  const {
    currentAmount,
    amountBlocks,
    onClick,
    currency,
    isAutoSubmit,
    methodName,
    isInitialLoadFinished = false,
    setInitialLoadFinished,
    min = 0,
  } = props;
  const dispatch = useAppDispatch();
  const clickstream = Clickstream.use();
  const flowType = useAppSelector(paymentFlowSelector);
  const cashierContext = useGetClickStreamCashierContext(flowType);
  const payGroupContext = useGetClickStreamPayGroupCashierContext(flowType);
  const currencyDisplaySetting = useAppSelector(flowType === FlowTypes.deposit ? (
    depositCurrencyDisplaySelector
  ) : withdrawalCurrencyDisplaySelector);

  const initialSelectedAmountIndex = getInitialSelectedAmountIndex(amountBlocks);
  const initialSelectedAmount = amountBlocks ? amountBlocks[initialSelectedAmountIndex] : 0;
  const [selectedAmount, setSelectedAmount] = useState<number | null>(initialSelectedAmount);
  const onClickHandler = (value: number, index: number) => {
    if (!isAutoSubmit) {
      const сlEvent = getClickStreamEvent.cashier_method_predefined_amount_button_click(index, currency, value);
      clickstream.push(сlEvent, [cashierContext,
        payGroupContext]);
      setSelectedAmount(value);
      onClick(value);
      dispatch(setAmount(`${value}`));
    }
  };

  useEffect(() => {
    const needToSelectChip = typeof selectedAmount === 'number' && (currentAmount <= min) && !isInitialLoadFinished;
    if (needToSelectChip) {
      onClickHandler(initialSelectedAmount, initialSelectedAmountIndex);
    }
    if (needToSelectChip && setInitialLoadFinished) {
      setInitialLoadFinished(true);
    }
  }, [
    methodName,
    min,
    isInitialLoadFinished,
  ]);

  useEffect(() => {
    setSelectedAmount(amountBlocks && amountBlocks.includes(currentAmount) ? currentAmount : null);
  }, [currentAmount]);

  if (!amountBlocks || !amountBlocks.length) {
    return null;
  }

  return (
    <div className="amount-blocks">
      <Chips
        dataTestId="amount-blocks"
        horizontal
      >
        {amountBlocks.map((value, index) => (
          <Chip
            key={value}
            className="amount-blocks__item"
            onClick={(e) => {
              e.preventDefault();
              onClickHandler(value, index);
            }}
            checked={value === selectedAmount}
            value={getPrettierFormatNumber(value)}
          >
            {getFormattedAmountWithCurrency(getPrettierFormatNumber(value), currency, currencyDisplaySetting)}
          </Chip>
        ))}
      </Chips>
    </div>
  );
};
