import React, { useEffect, useState } from 'react';
import { useSkeletonTranslation } from 'hooks/useSkeletonTranslation';
import { Button, TextField, Typography } from '@modulor/react';
import { NAMESPACES } from 'services/constants';
import { getOTPFCodeFieldNameSelector, OTPFilledCodeSelector, setOTPCode } from 'store/slices/otp';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { FillAndResendCodeProps } from '../otpTypes';
import { isPayloadsAndFormDataEqual } from '../utils';

const FillAndSendOrResendCode = ({ isDisabled, isShowResend, isDisabledResend, isCanResendFalse, onSendCode,
  formData, previousPayload, onSetTimerOn, isLoading }: FillAndResendCodeProps) => {
  const { translate, skeletonT } = useSkeletonTranslation();
  const dispatch = useAppDispatch();
  const OTPCode = useAppSelector(OTPFilledCodeSelector);
  const otpFiledName = useAppSelector(getOTPFCodeFieldNameSelector);
  const [code, setCode] = useState<string>('');
  const [isPayloadTheSame, setIsPayloadTheSame] = useState<boolean>(false);

  const handleCodeChange = (value: string) => {
    setCode(value);
    dispatch(setOTPCode(value));
  };

  useEffect(() => {
    if (previousPayload && formData) {
      setIsPayloadTheSame(isPayloadsAndFormDataEqual({
        previousPayload, formData, otpFiledName,
      }));
    }
  }, [previousPayload,
    formData,
    otpFiledName]);

  useEffect(() => {
    if (!isPayloadTheSame && OTPCode) {
      handleCodeChange('');
    } else if (!isPayloadTheSame) {
      onSetTimerOn(false);
    }
  }, [isPayloadTheSame,
    OTPCode]);

  return isShowResend && isPayloadTheSame ? (
    <>
      <TextField
        className="input-fill-code"
        value={code}
        type="text"
        inputMode="numeric"
        label={translate(`${NAMESPACES.PW_KEYS}:CASHIER.PH2.ENTER_OTP`)}
        onChange={(e) => {
          const value = (e as string).replace(/[^0-9]/g, '');
          handleCodeChange(value);
        }}
      />
      <div className="wrapper-button">
        <Button
          className="button-get-code resend_code"
          icon="refresh"
          disabled={isDisabledResend || isLoading}
          onClick={onSendCode}
        >
          {skeletonT(`${NAMESPACES.PW_KEYS}:PH.BUTTON.RECEIVE_NEW_CODE`)}
        </Button>
      </div>
      {isCanResendFalse
      && (
      <div className="resend-unavailable-text">
        <Typography
          variant="subhead-regular"
          data-translation-key={`${NAMESPACES.PW_KEYS}:PH.TEXT.RECEIVE_CODE_UNAVAILABLE`}
        >
          {skeletonT(`${NAMESPACES.PW_KEYS}:PH.TEXT.RECEIVE_CODE_UNAVAILABLE`)}
        </Typography>
      </div>
      )}
    </>
  )
    : (
      <div className="wrapper-button">
        <Button
          className="button-get-code"
          disabled={isDisabled || isLoading}
          onClick={onSendCode}
        >
          {skeletonT(`${NAMESPACES.PW_KEYS}:PH.BUTTON.RECEIVE_CODE`)}
        </Button>
      </div>
    );
};

export default FillAndSendOrResendCode;
