import React from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicTypeObject } from 'commonTypes';

// The custom hook returns a function that works like `t` but also handles skeleton loading
export const useSkeletonTranslation = () => {
  const { t, i18n, ready } = useTranslation();
  const isSkeleton = !ready || !i18n.isInitialized;

  const skeletonT = (key: string, options: DynamicTypeObject = {}) => {
    if (isSkeleton) {
      return (
        <span
          className="skeleton-text"
          style={{
            width: '100px',
          }}
        />
      );
    }
    return t(key, options);
  };

  const translate = (key: string, options?: DynamicTypeObject, showLoading = false) => {
    if (isSkeleton && showLoading) {
      return t('...', options);
    }
    return t(key, options);
  };

  return {
    translate,
    skeletonT,
    isSkeleton,
  };
};
