// https://gist.github.com/paulochf/9616f85f3f3904f1c36f

export interface CountryCode {
  id: string;
  iso: string;
  name: string;
  nicename: string;
  iso3: string;
  numcode: string | null;
  phonecode: string;
  telephoneMasks: string[];
}

const MASK_44 = '+44 - XXXX - XXX - XXX';
const MASK_61 = '+61 - X - XXXX - XXXX';
const MASK_599 = '+599 - XXX - XXXX';
const MASK_262 = '+262 - XXXXX - XXXX';

export const countryDialCode = [
  {
    id: '2',
    iso: 'AL',
    name: 'ALBANIA',
    nicename: 'Albania',
    iso3: 'ALB',
    numcode: '8',
    phonecode: '355',
    telephoneMasks: ['+355(XXX) XXX - XXX'],
  },
  {
    id: '3',
    iso: 'DZ',
    name: 'ALGERIA',
    nicename: 'Algeria',
    iso3: 'DZA',
    numcode: '12',
    phonecode: '213',
    telephoneMasks: ['+213 - XX - XXX - XXXX'],
  },
  {
    id: '4',
    iso: 'AS',
    name: 'AMERICAN SAMOA',
    nicename: 'American Samoa',
    iso3: 'ASM',
    numcode: '16',
    phonecode: '1684',
    telephoneMasks: ['+1(684) XXX - XXXX'],
  },
  {
    id: '5',
    iso: 'AD',
    name: 'ANDORRA',
    nicename: 'Andorra',
    iso3: 'AND',
    numcode: '20',
    phonecode: '376',
    telephoneMasks: ['+376 - XXX - XXX'],
  },
  {
    id: '6',
    iso: 'AO',
    name: 'ANGOLA',
    nicename: 'Angola',
    iso3: 'AGO',
    numcode: '24',
    phonecode: '244',
    telephoneMasks: ['+244(XXX) XXX - XXX'],
  },
  {
    id: '7',
    iso: 'AI',
    name: 'ANGUILLA',
    nicename: 'Anguilla',
    iso3: 'AIA',
    numcode: '660',
    phonecode: '1264',
    telephoneMasks: ['+1(264) XXX - XXXX'],
  },
  {
    id: '9',
    iso: 'AG',
    name: 'ANTIGUA AND BARBUDA',
    nicename: 'Antigua and Barbuda',
    iso3: 'ATG',
    numcode: '28',
    phonecode: '1268',
    telephoneMasks: ['+1(268) XXX - XXXX'],
  },
  {
    id: '10',
    iso: 'AR',
    name: 'ARGENTINA',
    nicename: 'Argentina',
    iso3: 'ARG',
    numcode: '32',
    phonecode: '54',
    telephoneMasks: ['+54(XXX) XXX - XXXX'],
  },
  {
    id: '11',
    iso: 'AM',
    name: 'ARMENIA',
    nicename: 'Armenia',
    iso3: 'ARM',
    numcode: '51',
    phonecode: '374',
    telephoneMasks: ['+374 - XX - XXX - XXX'],
  },
  {
    id: '12',
    iso: 'AW',
    name: 'ARUBA',
    nicename: 'Aruba',
    iso3: 'ABW',
    numcode: '533',
    phonecode: '297',
    telephoneMasks: ['+297 - XXX - XXXX'],
  },
  {
    id: '13',
    iso: 'AU',
    name: 'AUSTRALIA',
    nicename: 'Australia',
    iso3: 'AUS',
    numcode: '36',
    phonecode: '61',
    telephoneMasks: [
      MASK_61,
      '+61 - XX - XXXX - XXXX',
    ],
  },
  {
    id: '14',
    iso: 'AT',
    name: 'AUSTRIA',
    nicename: 'Austria',
    iso3: 'AUT',
    numcode: '40',
    phonecode: '43',
    telephoneMasks: ['+43(XXX) XXXX - XXXX'],
  },
  {
    id: '15',
    iso: 'AZ',
    name: 'AZERBAIJAN',
    nicename: 'Azerbaijan',
    iso3: 'AZE',
    numcode: '31',
    phonecode: '994',
    telephoneMasks: ['+994 - XX - XXX - XX - XX'],
  },
  {
    id: '16',
    iso: 'BS',
    name: 'BAHAMAS',
    nicename: 'Bahamas',
    iso3: 'BHS',
    numcode: '44',
    phonecode: '1242',
    telephoneMasks: ['+1(242) XXX - XXXX'],
  },
  {
    id: '17',
    iso: 'BH',
    name: 'BAHRAIN',
    nicename: 'Bahrain',
    iso3: 'BHR',
    numcode: '48',
    phonecode: '973',
    telephoneMasks: ['+973 - XXXX - XXXX'],
  },
  {
    id: '18',
    iso: 'BD',
    name: 'BANGLADESH',
    nicename: 'Bangladesh',
    iso3: 'BGD',
    numcode: '50',
    phonecode: '880',
    telephoneMasks: [
      '+880 - XX - XXX - XXX',
      '+880 - XX - XXXX - XXXX',
    ],
  },
  {
    id: '19',
    iso: 'BB',
    name: 'BARBADOS',
    nicename: 'Barbados',
    iso3: 'BRB',
    numcode: '52',
    phonecode: '1246',
    telephoneMasks: ['+1(246) XXX - XXXX'],
  },
  {
    id: '21',
    iso: 'BE',
    name: 'BELGIUM',
    nicename: 'Belgium',
    iso3: 'BEL',
    numcode: '56',
    phonecode: '32',
    telephoneMasks: ['+32(XXX) XXX - XXX'],
  },
  {
    id: '22',
    iso: 'BZ',
    name: 'BELIZE',
    nicename: 'Belize',
    iso3: 'BLZ',
    numcode: '84',
    phonecode: '501',
    telephoneMasks: ['+501 - XXX - XXXX'],
  },
  {
    id: '23',
    iso: 'BJ',
    name: 'BENIN',
    nicename: 'Benin',
    iso3: 'BEN',
    numcode: '204',
    phonecode: '229',
    telephoneMasks: ['+229 - XX - XX - XXXX'],
  },
  {
    id: '24',
    iso: 'BM',
    name: 'BERMUDA',
    nicename: 'Bermuda',
    iso3: 'BMU',
    numcode: '60',
    phonecode: '1441',
    telephoneMasks: ['+1(441) XXX - XXXX'],
  },
  {
    id: '25',
    iso: 'BT',
    name: 'BHUTAN',
    nicename: 'Bhutan',
    iso3: 'BTN',
    numcode: '64',
    phonecode: '975',
    telephoneMasks: [
      '+975 - 17 - XXX - XXX',
      '+975 - X - XXX - XXX',
    ],
  },
  {
    id: '26',
    iso: 'BO',
    name: 'BOLIVIA',
    nicename: 'Bolivia',
    iso3: 'BOL',
    numcode: '68',
    phonecode: '591',
    telephoneMasks: ['+591 - X - XXX - XXXX'],
  },
  {
    id: '27',
    iso: 'BA',
    name: 'BOSNIA AND HERZEGOVINA',
    nicename: 'Bosnia and Herzegovina',
    iso3: 'BIH',
    numcode: '70',
    phonecode: '387',
    telephoneMasks: [
      '+387 - XX - XXXXX',
      '+387 - XX - XXXX',
    ],
  },
  {
    id: '28',
    iso: 'BW',
    name: 'BOTSWANA',
    nicename: 'Botswana',
    iso3: 'BWA',
    numcode: '72',
    phonecode: '267',
    telephoneMasks: ['+267 - XX - XXX - XXX'],
  },
  {
    id: '30',
    iso: 'BR',
    name: 'BRAZIL',
    nicename: 'Brazil',
    iso3: 'BRA',
    numcode: '76',
    phonecode: '55',
    telephoneMasks: [
      '+55(XX) XXXX - XXXX',
      '+55(XX) XXXXX - XXXX',
    ],
  },
  {
    id: '32',
    iso: 'BN',
    name: 'BRUNEI DARUSSALAM',
    nicename: 'Brunei Darussalam',
    iso3: 'BRN',
    numcode: '96',
    phonecode: '673',
    telephoneMasks: ['+673 - XXX - XXXX'],
  },
  {
    id: '33',
    iso: 'BG',
    name: 'BULGARIA',
    nicename: 'Bulgaria',
    iso3: 'BGR',
    numcode: '100',
    phonecode: '359',
    telephoneMasks: ['+359(XXX) XXX - XXX'],
  },
  {
    id: '34',
    iso: 'BF',
    name: 'BURKINA FASO',
    nicename: 'Burkina Faso',
    iso3: 'BFA',
    numcode: '854',
    phonecode: '226',
    telephoneMasks: ['+226 - XX - XX - XXXX'],
  },
  {
    id: '35',
    iso: 'BI',
    name: 'BURUNDI',
    nicename: 'Burundi',
    iso3: 'BDI',
    numcode: '108',
    phonecode: '257',
    telephoneMasks: ['+257 - XX - XX - XXXX'],
  },
  {
    id: '36',
    iso: 'KH',
    name: 'CAMBODIA',
    nicename: 'Cambodia',
    iso3: 'KHM',
    numcode: '116',
    phonecode: '855',
    telephoneMasks: ['+855 - XX - XXX - XXX'],
  },
  {
    id: '37',
    iso: 'CM',
    name: 'CAMEROON',
    nicename: 'Cameroon',
    iso3: 'CMR',
    numcode: '120',
    phonecode: '237',
    telephoneMasks: ['+237 - XXXX - XXXX'],
  },
  {
    id: '38',
    iso: 'CA',
    name: 'CANADA',
    nicename: 'Canada',
    iso3: 'CAN',
    numcode: '124',
    phonecode: '1',
    telephoneMasks: ['+1 - XXX - XXX - XXXX'],
  },
  {
    id: '39',
    iso: 'CV',
    name: 'CAPE VERDE',
    nicename: 'Cape Verde Islands',
    iso3: 'CPV',
    numcode: '132',
    phonecode: '238',
    telephoneMasks: ['+238(XXX) XX - XX'],
  },
  {
    id: '40',
    iso: 'KY',
    name: 'CAYMAN ISLANDS',
    nicename: 'Cayman Islands',
    iso3: 'CYM',
    numcode: '136',
    phonecode: '1345',
    telephoneMasks: ['+1(345) XXX - XXXX'],
  },
  {
    id: '41',
    iso: 'CF',
    name: 'CENTRAL AFRICAN REPUBLIC',
    nicename: 'Central African Republic',
    iso3: 'CAF',
    numcode: '140',
    phonecode: '236',
    telephoneMasks: ['+236 - XX - XX - XXXX'],
  },
  {
    id: '42',
    iso: 'TD',
    name: 'CHAD',
    nicename: 'Chad',
    iso3: 'TCD',
    numcode: '148',
    phonecode: '235',
    telephoneMasks: ['+235 - XX - XX - XX - XX'],
  },
  {
    id: '43',
    iso: 'CL',
    name: 'CHILE',
    nicename: 'Chile',
    iso3: 'CHL',
    numcode: '152',
    phonecode: '56',
    telephoneMasks: ['+56 - X - XXXX - XXXX'],
  },
  {
    id: '44',
    iso: 'CN',
    name: 'CHINA',
    nicename: 'China',
    iso3: 'CHN',
    numcode: '156',
    phonecode: '86',
    telephoneMasks: [
      '+86(XXX) XXXX - XXXX',
      '+86(XXX) XXXX - XXX',
      '+86 - XX - XXXXX - XXXXX',
    ],
  },
  {
    id: '47',
    iso: 'CO',
    name: 'COLOMBIA',
    nicename: 'Colombia',
    iso3: 'COL',
    numcode: '170',
    phonecode: '57',
    telephoneMasks: ['+57(XXX) XXX - XXXX'],
  },
  {
    id: '48',
    iso: 'KM',
    name: 'COMOROS',
    nicename: 'Comoros',
    iso3: 'COM',
    numcode: '174',
    phonecode: '269',
    telephoneMasks: ['+269 - XX - XXXXX'],
  },
  {
    id: '49',
    iso: 'CG',
    name: 'CONGO',
    nicename: 'Congo',
    iso3: 'COG',
    numcode: '178',
    phonecode: '242',
    telephoneMasks: ['+242 - XX - XXX - XXXX'],
  },
  {
    id: '50',
    iso: 'CD',
    name: 'Democratic Republic of the Congo',
    nicename: 'Democratic Republic of the Congo',
    iso3: 'COD',
    numcode: '180',
    phonecode: '243',
    telephoneMasks: ['+243(XXX) XXX - XXX'],
  },
  {
    id: '51',
    iso: 'CK',
    name: 'COOK ISLANDS',
    nicename: 'Cook Islands',
    iso3: 'COK',
    numcode: '184',
    phonecode: '682',
    telephoneMasks: ['+682 - XX - XXX'],
  },
  {
    id: '52',
    iso: 'CR',
    name: 'COSTA RICA',
    nicename: 'Costa Rica',
    iso3: 'CRI',
    numcode: '188',
    phonecode: '506',
    telephoneMasks: ['+506 - XXXX - XXXX'],
  },
  {
    id: '53',
    iso: 'CI',
    name: 'Cote dIvoire',
    nicename: 'Cote dIvoire',
    iso3: 'CIV',
    numcode: '384',
    phonecode: '225',
    telephoneMasks: ['+225 - XX - XXX - XXX'],
  },
  {
    id: '54',
    iso: 'HR',
    name: 'CROATIA',
    nicename: 'Croatia',
    iso3: 'HRV',
    numcode: '191',
    phonecode: '385',
    telephoneMasks: ['+385 - XX - XXX - XXX'],
  },
  {
    id: '55',
    iso: 'CU',
    name: 'CUBA',
    nicename: 'Cuba',
    iso3: 'CUB',
    numcode: '192',
    phonecode: '53',
    telephoneMasks: ['+53 - X - XXX - XXXX'],
  },
  {
    id: '56',
    iso: 'CY',
    name: 'CYPRUS',
    nicename: 'Cyprus',
    iso3: 'CYP',
    numcode: '196',
    phonecode: '357',
    telephoneMasks: ['+357 - XX - XXX - XXX'],
  },
  {
    id: '57',
    iso: 'CZ',
    name: 'CZECH REPUBLIC',
    nicename: 'Czech Republic',
    iso3: 'CZE',
    numcode: '203',
    phonecode: '420',
    telephoneMasks: ['+420(XXX) XXX - XXX'],
  },
  {
    id: '58',
    iso: 'DK',
    name: 'DENMARK',
    nicename: 'Denmark',
    iso3: 'DNK',
    numcode: '208',
    phonecode: '45',
    telephoneMasks: ['+45 - XX - XX - XX - XX'],
  },
  {
    id: '59',
    iso: 'DJ',
    name: 'DJIBOUTI',
    nicename: 'Djibouti',
    iso3: 'DJI',
    numcode: '262',
    phonecode: '253',
    telephoneMasks: ['+253 - XX - XX - XX - XX'],
  },
  {
    id: '60',
    iso: 'DM',
    name: 'DOMINICA',
    nicename: 'Dominica',
    iso3: 'DMA',
    numcode: '212',
    phonecode: '1767',
    telephoneMasks: ['+1(767) XXX - XXXX'],
  },
  {
    id: '61',
    iso: 'DO',
    name: 'DOMINICAN REPUBLIC',
    nicename: 'Dominican Republic',
    iso3: 'DOM',
    numcode: '214',
    phonecode: '1809',
    telephoneMasks: [
      '+1(809) XXX - XXXX',
      '+1(829) XXX - XXXX',
      '+1(849) XXX - XXXX',
    ],
  },
  {
    id: '62',
    iso: 'EC',
    name: 'ECUADOR',
    nicename: 'Ecuador',
    iso3: 'ECU',
    numcode: '218',
    phonecode: '593',
    telephoneMasks: [
      '+593 - XX - XXX - XXXX',
      '+593 - X - XXX - XXXX',
    ],
  },
  {
    id: '63',
    iso: 'EG',
    name: 'EGYPT',
    nicename: 'Egypt',
    iso3: 'EGY',
    numcode: '818',
    phonecode: '20',
    telephoneMasks: ['+20(XXX) XXX - XXXX'],
  },
  {
    id: '64',
    iso: 'SV',
    name: 'EL SALVADOR',
    nicename: 'El Salvador',
    iso3: 'SLV',
    numcode: '222',
    phonecode: '503',
    telephoneMasks: ['+503 - XX - XX - XXXX'],
  },
  {
    id: '65',
    iso: 'GQ',
    name: 'EQUATORIAL GUINEA',
    nicename: 'Equatorial Guinea',
    iso3: 'GNQ',
    numcode: '226',
    phonecode: '240',
    telephoneMasks: ['+240 - XX - XXX - XXXX'],
  },
  {
    id: '66',
    iso: 'ER',
    name: 'ERITREA',
    nicename: 'Eritrea',
    iso3: 'ERI',
    numcode: '232',
    phonecode: '291',
    telephoneMasks: ['+291 - X - XXX - XXX'],
  },
  {
    id: '67',
    iso: 'EE',
    name: 'ESTONIA',
    nicename: 'Estonia',
    iso3: 'EST',
    numcode: '233',
    phonecode: '372',
    telephoneMasks: [
      '+372 - XXXX - XXXX',
      '+372 - XXX - XXXX',
    ],
  },
  {
    id: '68',
    iso: 'ET',
    name: 'ETHIOPIA',
    nicename: 'Ethiopia',
    iso3: 'ETH',
    numcode: '231',
    phonecode: '251',
    telephoneMasks: ['+251 - XX - XXX - XXXX'],
  },
  {
    id: '69',
    iso: 'FK',
    name: 'FALKLAND ISLANDS (MALVINAS)',
    nicename: 'Falkland Islands (Malvinas)',
    iso3: 'FLK',
    numcode: '238',
    phonecode: '500',
    telephoneMasks: ['+500 - XXXXX'],
  },
  {
    id: '70',
    iso: 'FO',
    name: 'FAROE ISLANDS',
    nicename: 'Faroe Islands',
    iso3: 'FRO',
    numcode: '234',
    phonecode: '298',
    telephoneMasks: ['+298 - XXX - XXX'],
  },
  {
    id: '71',
    iso: 'FJ',
    name: 'FIJI',
    nicename: 'Fiji',
    iso3: 'FJI',
    numcode: '242',
    phonecode: '679',
    telephoneMasks: ['+679 - XX - XXXXX'],
  },
  {
    id: '72',
    iso: 'FI',
    name: 'FINLAND',
    nicename: 'Finland',
    iso3: 'FIN',
    numcode: '246',
    phonecode: '358',
    telephoneMasks: ['+358(XXX) XXX - XX - XX'],
  },
  {
    id: '73',
    iso: 'FR',
    name: 'FRANCE',
    nicename: 'France',
    iso3: 'FRA',
    numcode: '250',
    phonecode: '33',
    telephoneMasks: [
      MASK_262,
      '+33(XXX) XXX - XXX',
      '+508 - XX - XXXX',
      '+590(XXX) XXX - XXX',
    ],
  },
  {
    id: '74',
    iso: 'GF',
    name: 'FRENCH GUIANA',
    nicename: 'French Guiana',
    iso3: 'GUF',
    numcode: '254',
    phonecode: '594',
    telephoneMasks: ['+689 - XX - XX - XX'],
  },
  {
    id: '75',
    iso: 'PF',
    name: 'FRENCH POLYNESIA',
    nicename: 'French Polynesia',
    iso3: 'PYF',
    numcode: '258',
    phonecode: '689',
    telephoneMasks: ['+594 - XXXXX - XXXX'],
  },
  {
    id: '76',
    iso: 'TF',
    name: 'FRENCH SOUTHERN TERRITORIES',
    nicename: 'French Southern Territories',
    iso3: 'ATF',
    numcode: null,
    phonecode: '262',
    telephoneMasks: ['+262 - XX - XX - XX'],
  },
  {
    id: '77',
    iso: 'GA',
    name: 'GABON',
    nicename: 'Gabon',
    iso3: 'GAB',
    numcode: '266',
    phonecode: '241',
    telephoneMasks: ['+241 - X - XX - XX - XX'],
  },
  {
    id: '78',
    iso: 'GM',
    name: 'GAMBIA',
    nicename: 'Gambia',
    iso3: 'GMB',
    numcode: '270',
    phonecode: '220',
    telephoneMasks: ['+220(XXX) XX - XX'],
  },
  {
    id: '79',
    iso: 'GE',
    name: 'GEORGIA',
    nicename: 'Georgia',
    iso3: 'GEO',
    numcode: '268',
    phonecode: '995',
    telephoneMasks: ['+995(XXX) XXX - XXX'],
  },
  {
    id: '80',
    iso: 'DE',
    name: 'GERMANY',
    nicename: 'Germany',
    iso3: 'DEU',
    numcode: '276',
    phonecode: '49',
    telephoneMasks: [
      '+49(XXXX) XXX - XXXX',
      '+49(XXX) XXX - XXXX',
      '+49(XXX) XX - XXXX',
      '+49(XXX) XX - XXX',
      '+49(XXX) XX - XX',
      '+49 - XXX - XXX',
    ],
  },
  {
    id: '81',
    iso: 'GH',
    name: 'GHANA',
    nicename: 'Ghana',
    iso3: 'GHA',
    numcode: '288',
    phonecode: '233',
    telephoneMasks: ['+233(XXX) XXX - XXX'],
  },
  {
    id: '82',
    iso: 'GI',
    name: 'GIBRALTAR',
    nicename: 'Gibraltar',
    iso3: 'GIB',
    numcode: '292',
    phonecode: '350',
    telephoneMasks: ['+350 - XXX - XXXXX'],
  },
  {
    id: '83',
    iso: 'GR',
    name: 'GREECE',
    nicename: 'Greece',
    iso3: 'GRC',
    numcode: '300',
    phonecode: '30',
    telephoneMasks: ['+30(XXX) XXX - XXXX'],
  },
  {
    id: '84',
    iso: 'GL',
    name: 'GREENLAND',
    nicename: 'Greenland',
    iso3: 'GRL',
    numcode: '304',
    phonecode: '299',
    telephoneMasks: ['+299 - XX - XX - XX'],
  },
  {
    id: '85',
    iso: 'GD',
    name: 'GRENADA',
    nicename: 'Grenada',
    iso3: 'GRD',
    numcode: '308',
    phonecode: '1473',
    telephoneMasks: ['+1(473) XXX - XXXX'],
  },
  {
    id: '86',
    iso: 'GP',
    name: 'GUADELOUPE',
    nicename: 'Guadeloupe',
    iso3: 'GLP',
    numcode: '312',
    phonecode: '590',
    telephoneMasks: ['+590(XXX) XXX - XXX'],
  },
  {
    id: '87',
    iso: 'GU',
    name: 'GUAM',
    nicename: 'Guam',
    iso3: 'GUM',
    numcode: '316',
    phonecode: '1671',
    telephoneMasks: ['+1(671) XXX - XXXX'],
  },
  {
    id: '88',
    iso: 'GT',
    name: 'GUATEMALA',
    nicename: 'Guatemala',
    iso3: 'GTM',
    numcode: '320',
    phonecode: '502',
    telephoneMasks: ['+502 - X - XXX - XXXX'],
  },
  {
    id: '89',
    iso: 'GN',
    name: 'GUINEA',
    nicename: 'Guinea',
    iso3: 'GIN',
    numcode: '324',
    phonecode: '224',
    telephoneMasks: ['+224 - XX - XXX - XXX'],
  },
  {
    id: '90',
    iso: 'GW',
    name: 'GUINEA-BISSAU',
    nicename: 'Guinea-Bissau',
    iso3: 'GNB',
    numcode: '624',
    phonecode: '245',
    telephoneMasks: ['+245 - X - XXXXXX'],
  },
  {
    id: '91',
    iso: 'GY',
    name: 'GUYANA',
    nicename: 'Guyana',
    iso3: 'GUY',
    numcode: '328',
    phonecode: '592',
    telephoneMasks: ['+592 - XXX - XXXX'],
  },
  {
    id: '92',
    iso: 'HT',
    name: 'HAITI',
    nicename: 'Haiti',
    iso3: 'HTI',
    numcode: '332',
    phonecode: '509',
    telephoneMasks: ['+509 - XX - XX - XXXX'],
  },
  {
    id: '95',
    iso: 'HN',
    name: 'HONDURAS',
    nicename: 'Honduras',
    iso3: 'HND',
    numcode: '340',
    phonecode: '504',
    telephoneMasks: ['+504 - XXXX - XXXX'],
  },
  {
    id: '96',
    iso: 'HK',
    name: 'HONG KONG',
    nicename: 'Hong Kong',
    iso3: 'HKG',
    numcode: '344',
    phonecode: '852',
    telephoneMasks: ['+852 - XXXX - XXXX'],
  },
  {
    id: '97',
    iso: 'HU',
    name: 'HUNGARY',
    nicename: 'Hungary',
    iso3: 'HUN',
    numcode: '348',
    phonecode: '36',
    telephoneMasks: ['+36(XXX) XXX - XXX'],
  },
  {
    id: '98',
    iso: 'IS',
    name: 'ICELAND',
    nicename: 'Iceland',
    iso3: 'ISL',
    numcode: '352',
    phonecode: '354',
    telephoneMasks: ['+354 - XXX - XXXX'],
  },
  {
    id: '99',
    iso: 'IN',
    name: 'INDIA',
    nicename: 'India',
    iso3: 'IND',
    numcode: '356',
    phonecode: '91',
    telephoneMasks: ['+91(XXXX) XXX - XXX'],
  },
  {
    id: '100',
    iso: 'ID',
    name: 'INDONESIA',
    nicename: 'Indonesia',
    iso3: 'IDN',
    numcode: '360',
    phonecode: '62',
    telephoneMasks: [
      '+62 - XX - XXX - XX',
      '+62 - XX - XXX - XXX',
      '+62(XXX) XXX - XXXX',
      '+62 - XX - XXX - XXXX',
      '+62(XXX) XXX - XX - XXX',
      '+62(XXX) XXX - XXX - XXX',
      '+62(XXX) XX - XXXX - XXXX',
    ],
  },
  {
    id: '103',
    iso: 'IE',
    name: 'IRELAND',
    nicename: 'Ireland',
    iso3: 'IRL',
    numcode: '372',
    phonecode: '353',
    telephoneMasks: ['+353(XXX) XXX - XXX'],
  },
  {
    id: '104',
    iso: 'IL',
    name: 'ISRAEL',
    nicename: 'Israel',
    iso3: 'ISR',
    numcode: '376',
    phonecode: '972',
    telephoneMasks: [
      '+972 - 5X - XXX - XXXX',
      '+972 - X - XXX - XXXX',
    ],
  },
  {
    id: '105',
    iso: 'IT',
    name: 'ITALY',
    nicename: 'Italy',
    iso3: 'ITA',
    numcode: '380',
    phonecode: '39',
    telephoneMasks: [
      '+39(XXX) XXXX - XXX',
    ],
  },
  {
    id: '106',
    iso: 'JM',
    name: 'JAMAICA',
    nicename: 'Jamaica',
    iso3: 'JAM',
    numcode: '388',
    phonecode: '1876',
    telephoneMasks: ['+1(876) XXX - XXXX'],
  },
  {
    id: '107',
    iso: 'JP',
    name: 'JAPAN',
    nicename: 'Japan',
    iso3: 'JPN',
    numcode: '392',
    phonecode: '81',
    telephoneMasks: [
      '+81 - XX - XXXX - XXXX',
      '+81(XXX) XXX - XXX',
    ],
  },
  {
    id: '108',
    iso: 'JO',
    name: 'JORDAN',
    nicename: 'Jordan',
    iso3: 'JOR',
    numcode: '400',
    phonecode: '962',
    telephoneMasks: ['+962 - X - XXXX - XXXX'],
  },
  {
    id: '109',
    iso: 'KZ',
    name: 'KAZAKHSTAN',
    nicename: 'Kazakhstan',
    iso3: 'KAZ',
    numcode: '398',
    phonecode: '7',
    telephoneMasks: ['+7(XXX) XXX - XX - XX'],
  },
  {
    id: '110',
    iso: 'KE',
    name: 'KENYA',
    nicename: 'Kenya',
    iso3: 'KEN',
    numcode: '404',
    phonecode: '254',
    telephoneMasks: ['+254 - XXX - XXXXXX'],
  },
  {
    id: '111',
    iso: 'KI',
    name: 'KIRIBATI',
    nicename: 'Kiribati',
    iso3: 'KIR',
    numcode: '296',
    phonecode: '686',
    telephoneMasks: ['+686 - XX - XXX'],
  },
  {
    id: '113',
    iso: 'KR',
    name: 'South Korea',
    nicename: 'South Korea',
    iso3: 'KOR',
    numcode: '410',
    phonecode: '82',
    telephoneMasks: [
      '+82 - XX - XXX - XXXX',
    ],
  },
  {
    id: '114',
    iso: 'KW',
    name: 'KUWAIT',
    nicename: 'Kuwait',
    iso3: 'KWT',
    numcode: '414',
    phonecode: '965',
    telephoneMasks: ['+965 - XXXX - XXXX'],
  },
  {
    id: '115',
    iso: 'KG',
    name: 'KYRGYZSTAN',
    nicename: 'Kyrgyzstan',
    iso3: 'KGZ',
    numcode: '417',
    phonecode: '996',
    telephoneMasks: ['+996(XXX) XXX - XXX'],
  },
  {
    id: '116',
    iso: 'LA',
    name: 'Laos',
    nicename: 'Laos',
    iso3: 'LAO',
    numcode: '418',
    phonecode: '856',
    telephoneMasks: [
      '+856(20XX) XXX - XXX',
      '+856 - XX - XXX - XXX',
    ],
  },
  {
    id: '117',
    iso: 'LV',
    name: 'LATVIA',
    nicename: 'Latvia',
    iso3: 'LVA',
    numcode: '428',
    phonecode: '371',
    telephoneMasks: ['+371 - XX - XXX - XXX'],
  },
  {
    id: '118',
    iso: 'LB',
    name: 'LEBANON',
    nicename: 'Lebanon',
    iso3: 'LBN',
    numcode: '422',
    phonecode: '961',
    telephoneMasks: [
      '+961 - XX - XXX - XXX',
      '+961 - X - XXX - XXX',
    ],
  },
  {
    id: '119',
    iso: 'LS',
    name: 'LESOTHO',
    nicename: 'Lesotho',
    iso3: 'LSO',
    numcode: '426',
    phonecode: '266',
    telephoneMasks: ['+266 - X - XXX - XXXX'],
  },
  {
    id: '120',
    iso: 'LR',
    name: 'LIBERIA',
    nicename: 'Liberia',
    iso3: 'LBR',
    numcode: '430',
    phonecode: '231',
    telephoneMasks: ['+231 - XX - XXX - XXX'],
  },
  {
    id: '122',
    iso: 'LI',
    name: 'LIECHTENSTEIN',
    nicename: 'Liechtenstein',
    iso3: 'LIE',
    numcode: '438',
    phonecode: '423',
    telephoneMasks: ['+423(XXX) XXX - XXXX'],
  },
  {
    id: '123',
    iso: 'LT',
    name: 'LITHUANIA',
    nicename: 'Lithuania',
    iso3: 'LTU',
    numcode: '440',
    phonecode: '370',
    telephoneMasks: ['+370(XXX) XX - XXX'],
  },
  {
    id: '124',
    iso: 'LU',
    name: 'LUXEMBOURG',
    nicename: 'Luxembourg',
    iso3: 'LUX',
    numcode: '442',
    phonecode: '352',
    telephoneMasks: ['+352(XXX) XXX - XXX'],
  },
  {
    id: '125',
    iso: 'MO',
    name: 'MACAO',
    nicename: 'Macao',
    iso3: 'MAC',
    numcode: '446',
    phonecode: '853',
    telephoneMasks: ['+853 - XXXX - XXXX'],
  },
  {
    id: '126',
    iso: 'MK',
    name: 'North Macedonia',
    nicename: 'North Macedonia',
    iso3: 'MKD',
    numcode: '807',
    phonecode: '389',
    telephoneMasks: ['+389 - XX - XXX - XXX'],
  },
  {
    id: '127',
    iso: 'MG',
    name: 'MADAGASCAR',
    nicename: 'Madagascar',
    iso3: 'MDG',
    numcode: '450',
    phonecode: '261',
    telephoneMasks: ['+261 - XX - XX - XXXXX'],
  },
  {
    id: '128',
    iso: 'MW',
    name: 'MALAWI',
    nicename: 'Malawi',
    iso3: 'MWI',
    numcode: '454',
    phonecode: '265',
    telephoneMasks: [
      '+265 - 1 - XXX - XXX',
      '+265 - X - XXXX - XXXX',
    ],
  },
  {
    id: '129',
    iso: 'MY',
    name: 'MALAYSIA',
    nicename: 'Malaysia',
    iso3: 'MYS',
    numcode: '458',
    phonecode: '60',
    telephoneMasks: [
      '+60 - XX - XXX - XXXX',
      '+60(XXX) XXX - XXX',
      '+60 - XX - XXX - XXX',
      '+60 - X - XXX - XXX',
      '+60 - XX - XXXX - XXXX',
    ],
  },
  {
    id: '130',
    iso: 'MV',
    name: 'MALDIVES',
    nicename: 'Maldives',
    iso3: 'MDV',
    numcode: '462',
    phonecode: '960',
    telephoneMasks: ['+960 - XXX - XXXX'],
  },
  {
    id: '131',
    iso: 'ML',
    name: 'MALI',
    nicename: 'Mali',
    iso3: 'MLI',
    numcode: '466',
    phonecode: '223',
    telephoneMasks: ['+223 - XX - XX - XXXX'],
  },
  {
    id: '132',
    iso: 'MT',
    name: 'MALTA',
    nicename: 'Malta',
    iso3: 'MLT',
    numcode: '470',
    phonecode: '356',
    telephoneMasks: ['+356 - XXXX - XXXX'],
  },
  {
    id: '133',
    iso: 'MH',
    name: 'MARSHALL ISLANDS',
    nicename: 'Marshall Islands',
    iso3: 'MHL',
    numcode: '584',
    phonecode: '692',
    telephoneMasks: ['+692 - XXX - XXXX'],
  },
  {
    id: '134',
    iso: 'MQ',
    name: 'MARTINIQUE',
    nicename: 'Martinique',
    iso3: 'MTQ',
    numcode: '474',
    phonecode: '596',
    telephoneMasks: ['+596(XXX) XX - XX - XX'],
  },
  {
    id: '135',
    iso: 'MR',
    name: 'MAURITANIA',
    nicename: 'Mauritania',
    iso3: 'MRT',
    numcode: '478',
    phonecode: '222',
    telephoneMasks: ['+222 - XX - XX - XXXX'],
  },
  {
    id: '136',
    iso: 'MU',
    name: 'MAURITIUS',
    nicename: 'Mauritius',
    iso3: 'MUS',
    numcode: '480',
    phonecode: '230',
    telephoneMasks: ['+230 - XXX - XXXX'],
  },
  {
    id: '137',
    iso: 'YT',
    name: 'MAYOTTE',
    nicename: 'Mayotte',
    iso3: 'MYT',
    numcode: null,
    phonecode: '269',
    telephoneMasks: [MASK_262],
  },
  {
    id: '138',
    iso: 'MX',
    name: 'MEXICO',
    nicename: 'Mexico',
    iso3: 'MEX',
    numcode: '484',
    phonecode: '52',
    telephoneMasks: [
      '+52 - XX - XXXX - XXXX',
      '+52 - XXX - XXX - XXXX',
      '+52 - XXX - XXXX - XXXX',
    ],
  },
  {
    id: '139',
    iso: 'FM',
    name: 'Federated States of Micronesia',
    nicename: 'Federated States of Micronesia',
    iso3: 'FSM',
    numcode: '583',
    phonecode: '691',
    telephoneMasks: ['+691 - XXX - XXXX'],
  },
  {
    id: '140',
    iso: 'MD',
    name: 'MOLDOVA',
    nicename: 'Moldova',
    iso3: 'MDA',
    numcode: '498',
    phonecode: '373',
    telephoneMasks: ['+373 - XXXX - XXXX'],
  },
  {
    id: '141',
    iso: 'MC',
    name: 'MONACO',
    nicename: 'Monaco',
    iso3: 'MCO',
    numcode: '492',
    phonecode: '377',
    telephoneMasks: [
      '+377(XXX) XXX - XXX',
      '+377 - XX - XXX - XXX',
    ],
  },
  {
    id: '142',
    iso: 'MN',
    name: 'MONGOLIA',
    nicename: 'Mongolia',
    iso3: 'MNG',
    numcode: '496',
    phonecode: '976',
    telephoneMasks: ['+976 - XX - XX - XXXX'],
  },
  {
    id: '144',
    iso: 'MA',
    name: 'MOROCCO',
    nicename: 'Morocco',
    iso3: 'MAR',
    numcode: '504',
    phonecode: '212',
    telephoneMasks: ['+212 - XX - XXXX - XXX'],
  },
  {
    id: '145',
    iso: 'MZ',
    name: 'MOZAMBIQUE',
    nicename: 'Mozambique',
    iso3: 'MOZ',
    numcode: '508',
    phonecode: '258',
    telephoneMasks: ['+258 - XX - XXX - XXX'],
  },
  {
    id: '146',
    iso: 'MM',
    name: 'MYANMAR',
    nicename: 'Myanmar',
    iso3: 'MMR',
    numcode: '104',
    phonecode: '95',
    telephoneMasks: [
      '+95 - XX - XXX - XXX',
      '+95 - X - XXX - XXX',
      '+95 - XXX - XXX',
    ],
  },
  {
    id: '147',
    iso: 'NA',
    name: 'NAMIBIA',
    nicename: 'Namibia',
    iso3: 'NAM',
    numcode: '516',
    phonecode: '264',
    telephoneMasks: ['+264 - XX - XXX - XXXX'],
  },
  {
    id: '148',
    iso: 'NR',
    name: 'NAURU',
    nicename: 'Nauru',
    iso3: 'NRU',
    numcode: '520',
    phonecode: '674',
    telephoneMasks: ['+674 - XXX - XXXX'],
  },
  {
    id: '149',
    iso: 'NP',
    name: 'NEPAL',
    nicename: 'Nepal',
    iso3: 'NPL',
    numcode: '524',
    phonecode: '977',
    telephoneMasks: [
      '+977 - XX - XXX - XXX',
      '+977- XX - XXXX - XXXX',
    ],
  },
  {
    id: '150',
    iso: 'NL',
    name: 'NETHERLANDS',
    nicename: 'Netherlands',
    iso3: 'NLD',
    numcode: '528',
    phonecode: '31',
    telephoneMasks: ['+31 - XX - XXX - XXXX'],
  },
  {
    id: '151',
    iso: 'AN',
    name: 'NETHERLANDS ANTILLES',
    nicename: 'Netherlands Antilles',
    iso3: 'ANT',
    numcode: '530',
    phonecode: '599',
    telephoneMasks: [
      MASK_599,
      '+599 - 9XXX - XXXX',
    ],
  },
  {
    id: '152',
    iso: 'NC',
    name: 'NEW CALEDONIA',
    nicename: 'New Caledonia',
    iso3: 'NCL',
    numcode: '540',
    phonecode: '687',
    telephoneMasks: ['+687 - XX - XXXX'],
  },
  {
    id: '153',
    iso: 'NZ',
    name: 'NEW ZEALAND',
    nicename: 'New Zealand',
    iso3: 'NZL',
    numcode: '554',
    phonecode: '64',
    telephoneMasks: [
      '+64 - XX - XXX - XXXX',
      '+64 - XXX - XXX - XXXX',
      '+64 - XXXX - XXX - XXX',
    ],
  },
  {
    id: '154',
    iso: 'NI',
    name: 'NICARAGUA',
    nicename: 'Nicaragua',
    iso3: 'NIC',
    numcode: '558',
    phonecode: '505',
    telephoneMasks: ['+505 - XXXX - XXXX'],
  },
  {
    id: '155',
    iso: 'NE',
    name: 'NIGER',
    nicename: 'Niger',
    iso3: 'NER',
    numcode: '562',
    phonecode: '227',
    telephoneMasks: ['+227 - XX - XX - XXXX'],
  },
  {
    id: '156',
    iso: 'NG',
    name: 'NIGERIA',
    nicename: 'Nigeria',
    iso3: 'NGA',
    numcode: '566',
    phonecode: '234',
    telephoneMasks: [
      '+234 - XX - XXX - XXX',
      '+234 - XX - XXX - XX',
      '+234(XXX) XXX - XXXX',
    ],
  },
  {
    id: '158',
    iso: 'NF',
    name: 'NORFOLK ISLAND',
    nicename: 'Norfolk Island',
    iso3: 'NFK',
    numcode: '574',
    phonecode: '672',
    telephoneMasks: ['+672 - 3XX - XXX'],
  },
  {
    id: '159',
    iso: 'MP',
    name: 'NORTHERN MARIANA ISLANDS',
    nicename: 'Northern Mariana Islands',
    iso3: 'MNP',
    numcode: '580',
    phonecode: '1670',
    telephoneMasks: ['+1(670) XXX - XXXX'],
  },
  {
    id: '160',
    iso: 'NO',
    name: 'NORWAY',
    nicename: 'Norway',
    iso3: 'NOR',
    numcode: '578',
    phonecode: '47',
    telephoneMasks: ['+47(XXX) XX - XXX'],
  },
  {
    id: '161',
    iso: 'OM',
    name: 'OMAN',
    nicename: 'Oman',
    iso3: 'OMN',
    numcode: '512',
    phonecode: '968',
    telephoneMasks: ['+968 - XX - XXX - XXX'],
  },
  {
    id: '162',
    iso: 'PK',
    name: 'PAKISTAN',
    nicename: 'Pakistan',
    iso3: 'PAK',
    numcode: '586',
    phonecode: '92',
    telephoneMasks: ['+92(XXX) XXX - XXXX'],
  },
  {
    id: '163',
    iso: 'PW',
    name: 'PALAU',
    nicename: 'Palau',
    iso3: 'PLW',
    numcode: '585',
    phonecode: '680',
    telephoneMasks: ['+680 - XXX - XXXX'],
  },
  {
    id: '164',
    iso: 'PS',
    name: 'Palestine',
    nicename: 'Palestine',
    iso3: 'PSE',
    numcode: null,
    phonecode: '970',
    telephoneMasks: ['+970 - XX - XXX - XXXX'],
  },
  {
    id: '165',
    iso: 'PA',
    name: 'PANAMA',
    nicename: 'Panama',
    iso3: 'PAN',
    numcode: '591',
    phonecode: '507',
    telephoneMasks: ['+507 - XXX - XXXX'],
  },
  {
    id: '166',
    iso: 'PG',
    name: 'PAPUA NEW GUINEA',
    nicename: 'Papua New Guinea',
    iso3: 'PNG',
    numcode: '598',
    phonecode: '675',
    telephoneMasks: ['+675(XXX) XX - XXX'],
  },
  {
    id: '167',
    iso: 'PY',
    name: 'PARAGUAY',
    nicename: 'Paraguay',
    iso3: 'PRY',
    numcode: '600',
    phonecode: '595',
    telephoneMasks: ['+595(XXX) XXX - XXX'],
  },
  {
    id: '168',
    iso: 'PE',
    name: 'PERU',
    nicename: 'Peru',
    iso3: 'PER',
    numcode: '604',
    phonecode: '51',
    telephoneMasks: ['+51(XXX) XXX - XXX'],
  },
  {
    id: '169',
    iso: 'PH',
    name: 'PHILIPPINES',
    nicename: 'Philippines',
    iso3: 'PHL',
    numcode: '608',
    phonecode: '63',
    telephoneMasks: ['+63(XXX) XXX - XXXX'],
  },
  {
    id: '171',
    iso: 'PL',
    name: 'POLAND',
    nicename: 'Poland',
    iso3: 'POL',
    numcode: '616',
    phonecode: '48',
    telephoneMasks: ['+48(XXX) XXX - XXX'],
  },
  {
    id: '172',
    iso: 'PT',
    name: 'PORTUGAL',
    nicename: 'Portugal',
    iso3: 'PRT',
    numcode: '620',
    phonecode: '351',
    telephoneMasks: ['+351 - XX - XXX - XXXX'],
  },
  {
    id: '173',
    iso: 'PR',
    name: 'PUERTO RICO',
    nicename: 'Puerto Rico',
    iso3: 'PRI',
    numcode: '630',
    phonecode: '1787',
    telephoneMasks: ['+1(787) XXX - XXXX'],
  },
  {
    id: '174',
    iso: 'QA',
    name: 'QATAR',
    nicename: 'Qatar',
    iso3: 'QAT',
    numcode: '634',
    phonecode: '974',
    telephoneMasks: ['+974 - XXXX - XXXX'],
  },
  {
    id: '175',
    iso: 'RE',
    name: 'REUNION',
    nicename: 'Reunion',
    iso3: 'REU',
    numcode: '638',
    phonecode: '262',
    telephoneMasks: [MASK_262],
  },
  {
    id: '176',
    iso: 'RO',
    name: 'ROMANIA',
    nicename: 'Romania',
    iso3: 'ROU',
    numcode: '642',
    phonecode: '40',
    telephoneMasks: ['+40 - XX - XXX - XXXX'],
  },
  {
    id: '178',
    iso: 'RW',
    name: 'RWANDA',
    nicename: 'Rwanda',
    iso3: 'RWA',
    numcode: '646',
    phonecode: '250',
    telephoneMasks: ['+250(XXX) XXX - XXX'],
  },
  {
    id: '179',
    iso: 'SH',
    name: 'SAINT HELENA',
    nicename: 'Saint Helena',
    iso3: 'SHN',
    numcode: '654',
    phonecode: '290',
    telephoneMasks: ['+290 - XXXX'],
  },
  {
    id: '180',
    iso: 'KN',
    name: 'SAINT KITTS AND NEVIS',
    nicename: 'Saint Kitts and Nevis',
    iso3: 'KNA',
    numcode: '659',
    phonecode: '1869',
    telephoneMasks: ['+1(869) XXX - XXXX'],
  },
  {
    id: '181',
    iso: 'LC',
    name: 'SAINT LUCIA',
    nicename: 'Saint Lucia',
    iso3: 'LCA',
    numcode: '662',
    phonecode: '1758',
    telephoneMasks: ['+1(758) XXX - XXXX'],
  },
  {
    id: '183',
    iso: 'VC',
    name: 'SAINT VINCENT AND THE GRENADINES',
    nicename: 'Saint Vincent and the Grenadines',
    iso3: 'VCT',
    numcode: '670',
    phonecode: '1784',
    telephoneMasks: ['+1(784) XXX - XXXX'],
  },
  {
    id: '184',
    iso: 'WS',
    name: 'SAMOA',
    nicename: 'Samoa',
    iso3: 'WSM',
    numcode: '882',
    phonecode: '684',
    telephoneMasks: ['+685 - XX - XXXX'],
  },
  {
    id: '187',
    iso: 'SA',
    name: 'SAUDI ARABIA',
    nicename: 'Saudi Arabia',
    iso3: 'SAU',
    numcode: '682',
    phonecode: '966',
    telephoneMasks: [
      '+966 - 5 - XXXX - XXXX',
      '+966 - X - XXX - XXXX',
    ],
  },
  {
    id: '188',
    iso: 'SN',
    name: 'SENEGAL',
    nicename: 'Senegal',
    iso3: 'SEN',
    numcode: '686',
    phonecode: '221',
    telephoneMasks: ['+221 - XX - XXX - XXXX'],
  },
  {
    id: '190',
    iso: 'SC',
    name: 'SEYCHELLES',
    nicename: 'Seychelles',
    iso3: 'SYC',
    numcode: '690',
    phonecode: '248',
    telephoneMasks: ['+248 - X - XXX - XXX'],
  },
  {
    id: '191',
    iso: 'SL',
    name: 'SIERRA LEONE',
    nicename: 'Sierra Leone',
    iso3: 'SLE',
    numcode: '694',
    phonecode: '232',
    telephoneMasks: ['+232 - XX - XXXXXX'],
  },
  {
    id: '192',
    iso: 'SG',
    name: 'SINGAPORE',
    nicename: 'Singapore',
    iso3: 'SGP',
    numcode: '702',
    phonecode: '65',
    telephoneMasks: ['+65 - XXXX - XXXX'],
  },
  {
    id: '193',
    iso: 'SK',
    name: 'SLOVAKIA',
    nicename: 'Slovak Republic',
    iso3: 'SVK',
    numcode: '703',
    phonecode: '421',
    telephoneMasks: ['+421(XXX) XXX - XXX'],
  },
  {
    id: '194',
    iso: 'SI',
    name: 'SLOVENIA',
    nicename: 'Slovenia',
    iso3: 'SVN',
    numcode: '705',
    phonecode: '386',
    telephoneMasks: ['+386 - XX - XXX - XXX'],
  },
  {
    id: '195',
    iso: 'SB',
    name: 'SOLOMON ISLANDS',
    nicename: 'Solomon Islands',
    iso3: 'SLB',
    numcode: '90',
    phonecode: '677',
    telephoneMasks: [
      '+677 - XXX - XXXX',
      '+677 - XXXXX',
    ],
  },
  {
    id: '197',
    iso: 'ZA',
    name: 'SOUTH AFRICA',
    nicename: 'South Africa',
    iso3: 'ZAF',
    numcode: '710',
    phonecode: '27',
    telephoneMasks: ['+27 - XX - XXX - XXXX'],
  },
  {
    id: '199',
    iso: 'ES',
    name: 'SPAIN',
    nicename: 'Spain',
    iso3: 'ESP',
    numcode: '724',
    phonecode: '34',
    telephoneMasks: ['+34(XXX) XXX - XXX'],
  },
  {
    id: '200',
    iso: 'LK',
    name: 'SRI LANKA',
    nicename: 'Sri Lanka',
    iso3: 'LKA',
    numcode: '144',
    phonecode: '94',
    telephoneMasks: ['+94 - XX - XXX - XXXX'],
  },
  {
    id: '202',
    iso: 'SR',
    name: 'SURINAME',
    nicename: 'Suriname',
    iso3: 'SUR',
    numcode: '740',
    phonecode: '597',
    telephoneMasks: [
      '+597 - XXX - XXXX',
      '+597 - XXX - XXX',
    ],
  },
  {
    id: '204',
    iso: 'SZ',
    name: 'SWAZILAND',
    nicename: 'Swaziland',
    iso3: 'SWZ',
    numcode: '748',
    phonecode: '268',
    telephoneMasks: ['+268 - XX - XX - XXXX'],
  },
  {
    id: '205',
    iso: 'SE',
    name: 'SWEDEN',
    nicename: 'Sweden',
    iso3: 'SWE',
    numcode: '752',
    phonecode: '46',
    telephoneMasks: ['+46 - XX - XXX - XXXX'],
  },
  {
    id: '206',
    iso: 'CH',
    name: 'SWITZERLAND',
    nicename: 'Switzerland',
    iso3: 'CHE',
    numcode: '756',
    phonecode: '41',
    telephoneMasks: ['+41 - XX - XXX - XXXX'],
  },
  {
    id: '208',
    iso: 'TW',
    name: 'TAIWAN',
    nicename: 'Taiwan',
    iso3: 'TWN',
    numcode: '158',
    phonecode: '886',
    telephoneMasks: [
      '+886 - X - XXXX - XXXX',
      '+886 - XXXX - XXXX',
    ],
  },
  {
    id: '209',
    iso: 'TJ',
    name: 'TAJIKISTAN',
    nicename: 'Tajikistan',
    iso3: 'TJK',
    numcode: '762',
    phonecode: '992',
    telephoneMasks: ['+992 - XX - XXX - XXXX'],
  },
  {
    id: '210',
    iso: 'TZ',
    name: 'TANZANIA',
    nicename: 'Tanzania',
    iso3: 'TZA',
    numcode: '834',
    phonecode: '255',
    telephoneMasks: ['+255(XX) XXXX - XXX'],
  },
  {
    id: '211',
    iso: 'TH',
    name: 'THAILAND',
    nicename: 'Thailand',
    iso3: 'THA',
    numcode: '764',
    phonecode: '66',
    telephoneMasks: [
      '+66 - XX - XXX - XXXX',
      '+66 - XX - XXX - XXX',
      '+66 - XX - XXXX - XXXX',
    ],
  },
  {
    id: '212',
    iso: 'TL',
    name: 'TIMOR-LESTE',
    nicename: 'Timor-Leste',
    iso3: 'TLS',
    numcode: null,
    phonecode: '670',
    telephoneMasks: [
      '+670 - XXX - XXXX',
      '+670 - 77X - XXXXX',
      '+670 - 78X - XXXXX',
    ],
  },
  {
    id: '213',
    iso: 'TG',
    name: 'TOGO',
    nicename: 'Togo',
    iso3: 'TGO',
    numcode: '768',
    phonecode: '228',
    telephoneMasks: ['+228 - XX - XXX - XXX'],
  },
  {
    id: '215',
    iso: 'TO',
    name: 'TONGA',
    nicename: 'Tonga',
    iso3: 'TON',
    numcode: '776',
    phonecode: '676',
    telephoneMasks: ['+676 - XXXXX'],
  },
  {
    id: '216',
    iso: 'TT',
    name: 'TRINIDAD AND TOBAGO',
    nicename: 'Trinidad and Tobago',
    iso3: 'TTO',
    numcode: '780',
    phonecode: '1868',
    telephoneMasks: ['+1(868) XXX - XXXX'],
  },
  {
    id: '217',
    iso: 'TN',
    name: 'TUNISIA',
    nicename: 'Tunisia',
    iso3: 'TUN',
    numcode: '788',
    phonecode: '216',
    telephoneMasks: ['+216 - XX - XXX - XXX'],
  },
  {
    id: '218',
    iso: 'TR',
    name: 'TURKEY',
    nicename: 'Turkey',
    iso3: 'TUR',
    numcode: '792',
    phonecode: '90',
    telephoneMasks: ['+90(XXX) XXX - XXXX'],
  },
  {
    id: '219',
    iso: 'TM',
    name: 'TURKMENISTAN',
    nicename: 'Turkmenistan',
    iso3: 'TKM',
    numcode: '795',
    phonecode: '7370',
    telephoneMasks: ['+993 - X - XXX - XXXX'],
  },
  {
    id: '220',
    iso: 'TC',
    name: 'TURKS AND CAICOS ISLANDS',
    nicename: 'Turks and Caicos Islands',
    iso3: 'TCA',
    numcode: '796',
    phonecode: '1649',
    telephoneMasks: ['+1(649) XXX - XXXX'],
  },
  {
    id: '222',
    iso: 'UG',
    name: 'UGANDA',
    nicename: 'Uganda',
    iso3: 'UGA',
    numcode: '800',
    phonecode: '256',
    telephoneMasks: ['+256(XXX) XXX - XXX'],
  },
  {
    id: '223',
    iso: 'UA',
    name: 'UKRAINE',
    nicename: 'Ukraine',
    iso3: 'UKR',
    numcode: '804',
    phonecode: '380',
    telephoneMasks: ['+380(XX) XXX - XX - XX'],
  },
  {
    id: '224',
    iso: 'AE',
    name: 'UNITED ARAB EMIRATES',
    nicename: 'United Arab Emirates',
    iso3: 'ARE',
    numcode: '784',
    phonecode: '971',
    telephoneMasks: [
      '+971 - 5X - XXX - XXXX',
      '+971 - X - XXX - XXXX',
    ],
  },
  {
    id: '225',
    iso: 'GB',
    name: 'UNITED KINGDOM',
    nicename: 'United Kingdom',
    iso3: 'GBR',
    numcode: '826',
    phonecode: '44',
    telephoneMasks: ['+44 - XX - XXXX - XXXX'],
  },
  {
    id: '226',
    iso: 'US',
    name: 'UNITED STATES',
    nicename: 'United States',
    iso3: 'USA',
    numcode: '840',
    phonecode: '1',
    telephoneMasks: ['+1(XXX) XXX - XXXX'],
  },
  {
    id: '227',
    iso: 'UM',
    name: 'UNITED STATES MINOR OUTLYING ISLANDS',
    nicename: 'United States Minor Outlying Islands',
    iso3: 'UMI',
    numcode: null,
    phonecode: '1',
    telephoneMasks: ['+1(XXX) - XXX - XXXX'],
  },
  {
    id: '228',
    iso: 'UY',
    name: 'URUGUAY',
    nicename: 'Uruguay',
    iso3: 'URY',
    numcode: '858',
    phonecode: '598',
    telephoneMasks: ['+598 - X - XXX - XX - XX'],
  },
  {
    id: '229',
    iso: 'UZ',
    name: 'UZBEKISTAN',
    nicename: 'Uzbekistan',
    iso3: 'UZB',
    numcode: '860',
    phonecode: '998',
    telephoneMasks: ['+998 - XX - XXX - XXXX'],
  },
  {
    id: '230',
    iso: 'VU',
    name: 'VANUATU',
    nicename: 'Vanuatu',
    iso3: 'VUT',
    numcode: '548',
    phonecode: '678',
    telephoneMasks: [
      '+678 - XX - XXXXX',
      '+678 - XXXXX',
    ],
  },
  {
    id: '231',
    iso: 'VE',
    name: 'VENEZUELA',
    nicename: 'Venezuela',
    iso3: 'VEN',
    numcode: '862',
    phonecode: '58',
    telephoneMasks: ['+58(XXX) XXX - XXXX'],
  },
  {
    id: '232',
    iso: 'VN',
    name: 'VIET NAM',
    nicename: 'Viet Nam',
    iso3: 'VNM',
    numcode: '704',
    phonecode: '84',
    telephoneMasks: [
      '+84 - XX - XXXX - XXX',
      '+84(XXX) XXXX - XXX',
    ],
  },
  {
    id: '233',
    iso: 'VG',
    name: 'VIRGIN ISLANDS, BRITISH',
    nicename: 'Virgin Islands, British',
    iso3: 'VGB',
    numcode: '92',
    phonecode: '1284',
    telephoneMasks: ['+1(284) XXX - XXXX'],
  },
  {
    id: '234',
    iso: 'VI',
    name: 'VIRGIN ISLANDS, U.S.',
    nicename: 'Virgin Islands, U.S.',
    iso3: 'VIR',
    numcode: '850',
    phonecode: '1340',
    telephoneMasks: ['+1(340) XXX - XXXX'],
  },
  {
    id: '236',
    iso: 'EH',
    name: 'WESTERN SAHARA',
    nicename: 'Western Sahara',
    iso3: 'ESH',
    numcode: '732',
    phonecode: '212',
    telephoneMasks: ['+212 - XX - XXX - XXXX'],
  },
  {
    id: '238',
    iso: 'ZM',
    name: 'ZAMBIA',
    nicename: 'Zambia',
    iso3: 'ZMB',
    numcode: '894',
    phonecode: '260',
    telephoneMasks: ['+260 - XX - XXX - XXXX'],
  },
  {
    id: '239',
    iso: 'ZW',
    name: 'ZIMBABWE',
    nicename: 'Zimbabwe',
    iso3: 'ZWE',
    numcode: '716',
    phonecode: '263',
    telephoneMasks: ['+263 - X - XXXXXX'],
  },
  {
    id: '240',
    iso: 'RS',
    name: 'SERBIA',
    nicename: 'Serbia',
    iso3: 'SRB',
    numcode: '688',
    phonecode: '381',
    telephoneMasks: ['+381 - XX - XXX - XXXX'],
  },
  {
    id: '242',
    iso: 'ME',
    name: 'MONTENEGRO',
    nicename: 'Montenegro',
    iso3: 'MNE',
    numcode: '499',
    phonecode: '382',
    telephoneMasks: ['+382 - XX - XXX - XXX'],
  },
  {
    id: '243',
    iso: 'AX',
    name: 'ALAND ISLANDS',
    nicename: 'Aland Islands',
    iso3: 'ALA',
    numcode: '248',
    phonecode: '358',
    telephoneMasks: ['+358 - X - XXX - XXXX'],
  },
  {
    id: '244',
    iso: 'BQ',
    name: 'BONAIRE, SINT EUSTATIUS AND SABA',
    nicename: 'Bonaire, Sint Eustatius and Saba',
    iso3: 'BES',
    numcode: '535',
    phonecode: '599',
    telephoneMasks: [MASK_599],
  },
  {
    id: '245',
    iso: 'CW',
    name: 'CURACAO',
    nicename: 'Curacao',
    iso3: 'CUW',
    numcode: '531',
    phonecode: '599',
    telephoneMasks: ['+599 - X -XXX - XXXX'],
  },
  {
    id: '246',
    iso: 'GG',
    name: 'GUERNSEY',
    nicename: 'Guernsey',
    iso3: 'GGY',
    numcode: '831',
    phonecode: '44',
    telephoneMasks: [MASK_44],
  },
  {
    id: '247',
    iso: 'IM',
    name: 'ISLE OF MAN',
    nicename: 'Isle of Man',
    iso3: 'IMN',
    numcode: '833',
    phonecode: '44',
    telephoneMasks: [MASK_44],
  },
  {
    id: '248',
    iso: 'JE',
    name: 'JERSEY',
    nicename: 'Jersey',
    iso3: 'JEY',
    numcode: '832',
    phonecode: '44',
    telephoneMasks: [MASK_44],
  },
  {
    id: '249',
    iso: 'XK',
    name: 'KOSOVO',
    nicename: 'Kosovo',
    iso3: 'XKX',
    numcode: '0',
    phonecode: '383',
    telephoneMasks: ['+383 - XXX - XXX - XXX'],
  },
  {
    id: '250',
    iso: 'BL',
    name: 'SAINT BARTHELEMY',
    nicename: 'Saint Barthelemy',
    iso3: 'BLM',
    numcode: '652',
    phonecode: '590',
    telephoneMasks: ['+590 - XXX - XXX - XXX'],
  },
  {
    id: '251',
    iso: 'MF',
    name: 'SAINT MARTIN',
    nicename: 'Saint Martin',
    iso3: 'MAF',
    numcode: '663',
    phonecode: '590',
    telephoneMasks: ['+590 - XXX - XXX - XXX'],
  },
  {
    id: '252',
    iso: 'SX',
    name: 'SINT MAARTEN',
    nicename: 'Sint Maarten',
    iso3: 'SXM',
    numcode: '534',
    phonecode: '1',
    telephoneMasks: ['+1(XXX) - XXX - XXXX'],
  },
];
