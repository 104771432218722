import { createSelector, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getCategoryByPathname } from 'utils';
import { Notification, Notifications, NotificationsState, RenderCategory } from '../models';

export const initialState: NotificationsState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications(state, action: PayloadAction<Notifications>) {
      let removedKeys: string[] = [];
      const allKeys: string[] = [];

      if (state.notifications.length) {
        removedKeys = state.notifications.reduce((acc, { removed, key = '' }: Notification) => {
          allKeys.push(key);

          if (removed) {
            acc.push(key);
          }

          return acc;
        }, [] as string[]);
      }
      const notifications = [...state.notifications];

      action.payload.forEach((notification: Notification) => {
        if (!removedKeys.includes(notification?.key as string) && !allKeys.includes(notification?.key as string)) {
          notifications.push(notification);
        }
      });

      return {
        ...state,
        notifications,
      };
    },
    resetAllNotifications(state) {
      return {
        ...state,
        notifications: state.notifications.map((notification: Notification) => ({
          ...notification,
          removed: true,
        })),
      };
    },
    removeNotification(state, action: PayloadAction<string>) {
      const keyToDelete = action.payload;
      const filteredNotifications = state.notifications.map((notification: Notification) => ({
        ...notification,
        removed: notification.key === keyToDelete,
      }));

      return {
        ...state,
        notifications: filteredNotifications,
      };
    },
  },
});

export const notificationsSelector = (selectedPayGroupId: number) => createSelector(
  [
    (state: { notifications: NotificationsState }) => state.notifications.notifications,
    () => getCategoryByPathname(window.location.pathname),
  ],
  (notifications, renderCategory) => {
    if (Object.is(renderCategory, null)) {
      return [];
    }

    if ([RenderCategory.depositForm,
      RenderCategory.withdrawalForm].includes(renderCategory as RenderCategory)) {
      return notifications.filter(
        ({ removed, categories, payGroupId }: Notification) => !removed
                    && categories.includes(renderCategory as RenderCategory)
                    && selectedPayGroupId === payGroupId,
      );
    }

    return notifications.filter(
      ({ removed, categories }: Notification) => !removed && categories.includes(renderCategory as RenderCategory),
    );
  },
);

export const {
  setNotifications,
  resetAllNotifications,
  removeNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
